<template>
  <Row>
    <Row class="ManagementBox">
      <Col span="24" class="text">
        <Row align="middle">
          <Avatar :size="64" style="margin-right: 15px"></Avatar>
          <span style="font-weight: bolder">{{ company }}</span>
        </Row>
      </Col>

      <Col span="24">
        <Row align="middle" class="footer">
          <Col span="6">
            <div class="footerText">
              {{ t('profileManagement.name.user') }} :
              <span>{{ user }}</span>
            </div>
          </Col>
          <Col span="6">
            <div class="footerText">
              {{ t('profileManagement.name.superAdmin') }} :
              <span>{{ admin }}</span>
            </div>
          </Col>
          <Col span="6">
            <div class="footerText">
              {{ t('profileManagement.name.sites') }} :
              <span>{{ sites }}</span>
            </div>
          </Col>
          <Col span="6">
            <div class="footerText">
              {{ t('profileManagement.name.devices') }} :
              <span>{{ devives }}</span>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  </Row>
</template>

<script setup>
import { computed } from "vue";
import { Col, Row, Avatar } from "ant-design-vue";
import {useI18n} from "vue-i18n";

const {t} = useI18n()
const props = defineProps({
  company: {
    type: String,
    default: "company",
  },
  user: {
    type: Number,
    default: 1,
  },
  admin: {
    type: Number,
    default: 1,
  },
  sites: {
    type: Number,
    default: 1,
  },
  devives: {
    type: Number,
    default: 1,
  },
});
</script>

<style lang="less">
@import url("style");
</style>
