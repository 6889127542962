<!-- eslint-disable vue/multi-word-component-names -->

<template>
  <Row id="structure">
    <Modal v-model:open="modalOpen" width="1600px" :title="t('Structure.StructureLevel')">
      <Col :span="24" style="font-size: 18px; margin-bottom: 20px">{{ t("Structure.AddStructureLevel") }}</Col>
      <Col :span="24">
        <Row :gutter="[40, 20]">
          <Col :span="12" align="center">
            <Row align="middle" :gutter="[40, 0]">
              <Col span="6" align="right">{{ t("Structure.table.NickName") }}:</Col>
              <Col span="18"><Input></Input></Col>
            </Row>
          </Col>
          <Col :span="12" align="center">
            <Row align="middle" :gutter="[40, 0]">
              <Col span="6" align="right">{{ t("Structure.StructureLevel") }}:</Col>
              <Col span="18">
                <Select style="width: 100%">
                  <Select.Option v-for="option in options" :key="option.key" :value="option.value">
                    <span>
                      <span v-for="(item, index) in (option.level - 1) * 2" :key="index">&nbsp;</span>
                      {{ option.label }}
                    </span>
                  </Select.Option>
                </Select>
              </Col>
            </Row>
          </Col>
          <Col :span="12" align="center">
            <Row align="middle" :gutter="[40, 0]">
              <Col span="6" align="right">{{ t("Structure.StructureAccessRight") }}:</Col>
              <Col span="18" align="left">
                <Checkbox></Checkbox>
                {{ t("Structure.ViewRoot") }}
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Modal>
    <!-- 标签-->
    <Col :span="24" class="title">
      <TypographyTitle>{{ t("Structure.StructureLevel") }}</TypographyTitle>
    </Col>

    <Col :span="24" class="content">
      <Col span="24" style="margin-bottom: 10px">
        <Row>
          <Col span="24" align="center">
            <div class="addlevelBox" @click="modalOpen = true">{{ t("Structure.AddStructureLevel") }}</div>
          </Col>
          <Col span="24">
            <Input.Search
              v-model:value="value"
              placeholder="input search text"
              style="width: 400px"
              @search="onSearch"
            />
          </Col>
        </Row>
      </Col>
      <!-- 列表-->
      <Row>
        <Col :span="24">
          <!--   :scroll="{ x: 1500, y: 'calc(100vh - 300px)' }" -->
          <Table :columns="columns" :dataSource="list" rowKey="key" :loading="loading" @change="chang_page">
            <template #headerCell="{ column }">
              <template v-if="column.dataIndex === 'action'">
                <span>
                  {{ $t("public.table.action") }}
                </span>
              </template>
            </template>
            <template #bodyCell="{ column }">
              <template v-if="column.dataIndex === 'status'">
                <div class="pc-display">
                  <Button type="link" size="small" style="background-color: #13a155; color: white">
                    {{ t("Structure.Normal") }}
                  </Button>
                </div>
              </template>
              <template v-if="column.dataIndex === 'action'">
                <div class="pc-display">
                  <Button type="link" size="small" style="background-color: #e79c12; color: white">
                    {{ t("Structure.Edit") }}
                  </Button>
                </div>
              </template>
            </template>
          </Table>
          <div v-if="showLoadingBut" style="width: 100%; text-align: center; margin-top: 5px">
            <Button @click="onClickLoading">loading({{ tablePagination.pageSize }})...</Button>
          </div>
        </Col>
      </Row>
    </Col>
  </Row>
</template>
<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  NiceName: "Structure",
};
</script>
<script setup>
import { createVNode, ref } from "vue";

import { Button, Col, TypographyTitle, Row, Select, Table, Tag, Divider, Input, Checkbox, Modal } from "ant-design-vue";
import { useI18n } from "vue-i18n";

import { EditOutlined, BarChartOutlined, EnvironmentOutlined, PlusCircleOutlined } from "@ant-design/icons-vue";

import { useRouter } from "vue-router";

const i18n = useI18n();
const { t } = useI18n();

const router = useRouter();

let modalOpen = ref(false);

let showLoadingBut = ref(false);

let categorieList = ref([]);

const options = [
  {
    value: "CLPe",
    label: "ㅏCLPe",
    level: 1,
    key: "1",
  },
  {
    value: "SiteA",
    label: "ㄴSiteA",
    level: 2,
    key: "11",
  },
  {
    value: "10/F",
    label: "ㅏ10/F",
    level: 3,
    key: "111",
  },
  {
    value: "Zone A",
    label: "ㄴZone A",
    level: 4,
    key: "1111",
  },
  {
    value: "11/F",
    label: "ㅏ11/F",
    level: 3,
    key: "112",
  },
  {
    value: "Zone A",
    label: "ㄴZone A",
    level: 4,
    key: "1121",
  },
  {
    value: "Property Management Company",
    label: "ㄴProperty Management Company",
    level: 1,
    key: "2",
  },
  {
    value: "Site 11",
    label: "ㄴSite 11",
    level: 2,
    key: "21",
  },
  {
    value: "1/F",
    label: "ㄴ1/F",
    level: 3,
    key: "211",
  },
  {
    value: "Zone Z",
    label: "ㄴZone Z",
    level: 4,
    key: "2111",
  },
];

let tablePagination = ref({
  pageSizeOptions: ["10", "20", "50", "100"],
  showSizeChanger: true,
  current: 1,
  pageSize: 20,
  total: 0,
  lastRowKey: undefined,
});
let loading = ref(false);
let list = ref([]);

let infoList = ref([]);

const columns = [
  { title: t("Structure.table.NickName"), width: 180, ellipsis: true, dataIndex: "NiceName", key: "NiceName" },

  { title: t("Structure.table.CreatedTime"), width: 180, ellipsis: true, dataIndex: "CreatedTime", key: "CreatedTime" },
  {
    title: t("Structure.table.Status"),
    width: 180,
    ellipsis: true,
    dataIndex: "status",
    key: "status",
  },
  {
    title: t("Structure.table.Action"),
    key: "action",
    dataIndex: "action",
    fixed: "right",
    width: 145,
    ellipsis: true,
  },
];

function chang_page(pagination) {
  tablePagination.value = pagination;
  get_list();
}

// ㄴ ㅏ
function get_list(lastRowKey) {
  loading.value = true;

  list.value = [
    {
      NiceName: "ㅏCLPe",
      CreatedTime: "2020-05-11 16:42:55",
      key: 1,
      children: [
        {
          NiceName: "ㄴSiteA",
          CreatedTime: "2020-02-04 12:11:14",
          key: 11,
          children: [
            {
              NiceName: "ㅏ10/F",
              CreatedTime: "2020-02-04 12:11:14",
              key: 111,
              children: [
                {
                  NiceName: "ㄴZone A",
                  CreatedTime: "2020-02-04 12:11:14",
                  key: 1111,
                },
              ],
            },
            {
              NiceName: "ㄴ11/F",
              CreatedTime: "2020-04-12 11:22:55",
              key: 212,
              children: [
                {
                  NiceName: "ㄴZone A",
                  CreatedTime: "2020-02-04 12:11:14",
                  key: 2121,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      NiceName: "ㄴProperty Management Company",
      CreatedTime: "2020-05-11 16:39:55",
      key: 2,
      children: [
        {
          NiceName: "ㄴSite 11",
          CreatedTime: "2020-05-12 16:42:33",
          key: 21,
          children: [
            {
              NiceName: "ㄴ1/F",
              CreatedTime: "2020-05-13 16:12:24",
              key: 211,
              children: [{ NiceName: "Zone Z", CreatedTime: "2020-05-12 16:42:33", key: 3111 }],
            },
          ],
        },
      ],
    },
  ];
  console.log(list.value);

  loading.value = false;
}

function clickId(id, category) {
  router.push({
    path: "/DeviceLogs",
    query: {
      id: id,
      category: category,
    },
  });
}

// 加载更多数据
function onClickLoading() {
  get_list(tablePagination.value.lastRowKey);
}

function filterOption(input, option) {
  return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
}

get_list(undefined);
</script>

<style lang="less">
@import url("style");
</style>
