<template>
  <div :class="fullScreen ? 'OnlineOfflineTotal OnlineOfflineTotalFullScreen' : 'OnlineOfflineTotal'">
    <Card :title="props.title" >
      <template #extra><Button :type="'text'" :icon="fullScreen ? h(ShrinkOutlined) : h(ArrowsAltOutlined)" @click="visibleModal"></Button></template>
      <div id="temperature" ></div>
    </Card>
  </div>
</template>

<script setup>
import { Card, message,Button } from "ant-design-vue";
import { ref, onMounted, nextTick,h } from "vue";
import { Chart } from '@antv/g2';
import {DeviceOperaLog, DeviceSpecificationAttributes} from "@/api/tuya";
import { EllipsisOutlined, ArrowsAltOutlined, ShrinkOutlined } from "@ant-design/icons-vue";
import dayjs from "dayjs";

// let data = [
//   { year: '1991', value: 3 },
//   { year: '1992', value: 4 },
//   { year: '1993', value: 3.5 },
//   { year: '1994', value: 5 },
//   { year: '1995', value: 4.9 },
//   { year: '1996', value: 6 },
//   { year: '1997', value: 7 },
//   { year: '1998', value: 9 },
//   { year: '1999', value: 13 },
// ];

const props = defineProps({
  deviceId: {
    type: String,
    default: ""
  },
  title: {
    type: String,
    default: ""
  }
})
let chart = null;
const fullScreen = ref(false);

const visibleModal = () => {
  fullScreen.value = !fullScreen.value;
  chart.destroy();
  setTimeout(() => {
    initChart();
  }, 100);
};



    

const initChart = async () => {
  chart = new Chart({
  container: 'temperature',
    autoFit: true,
  });

  // 获取设备状态列表

  let response = await new DeviceSpecificationAttributes().get({deviceId: props.deviceId});

  let codeList = []
  // 拼接所有状态，并查询
  if (response.data) {
    response.data.status?.forEach(item => {
      let values = JSON.parse(item.values)
      codeList.push({
        value: item.code,
        label: item.code,
        unit: values.unit,
        scale: values.scale
      })
    })
  }


  // 构建查询参数
  const getData = {
    deviceId: props.deviceId,
    codes: 'va_temperature',
  }
  let response1 = await new DeviceOperaLog().get(getData);
  if (response1.data.msg !== undefined) {
      message.error(response1.data.msg)
      return
    }
  console.log(response1.data.logs)
  let data = [
  ];
  const obj = codeList.find(item => item.value === 'va_temperature')
  for(let i = 0; i < response1.data.logs.length; i++) {
    if(response1.data.logs[i].code === 'va_temperature'){
      data.push({
        "time": dayjs(response1.data.logs[i].timeStr).format('HH:mm:ss'),
        "value": response1.data.logs[i].value / Math.pow(10, obj.scale)
      })
    }
    
  }
 





  chart
  .data(data)
  .encode('x', 'time')
  .encode('y', 'value')
  .axis('y', { labelFormatter: (d) => d + '°C' })
  .scale('x', {
    range: [0, 1],
  })
  .scale('y', {
    domainMin: 0,
    nice: true,
  });

chart.line().label({
  text: 'value',
  style: {
    dx: -10,
    dy: -12,
  },
});

chart.point().style('fill', 'white').tooltip(false);

chart.render();
}


onMounted(() => {
  initChart();
})




</script>

<style lang="less">
.OnlineOfflineTotal {
  background-color: white;
  border-radius: 10px;
  width: 100%;
  padding: 20px;
}
.OnlineOfflineTotalFullScreen {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  height: 100vh;
  .ant-row {
    height: 100vh;
  }
}
</style>