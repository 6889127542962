<template>
  <Row id="PileManage">
    <!-- 加载状态-->
    <Spin size="large" :spinning="false"/>
    <!-- 标签-->
    <Col :span="24" class="title">
      <TypographyTitle>{{ $t('nav.name.RentalManage') }}</TypographyTitle>
    </Col>
    <Col :span="24" class="content">
      <!-- 查询表单-->
      <Row :gutter="[16,32]">
        <Col :span="24">
          <Form :model="table">
            <Row gutter="16">
              <Col :span="6">
                <FormItem :label="$t('rentalManage.table.reId')" name="reId">
                  <Input v-model:value="table.reId" allowClear></Input>
                </FormItem>
              </Col>
              <Col :span="6">
                <FormItem :label="$t('rentalManage.table.reName')" name="reName">
                  <Input v-model:value="table.reName" allowClear></Input>
                </FormItem>
              </Col>
              <Col :span="6">
                <FormItem :label="$t('rentalManage.table.reAisle')" name="reAisle">
                  <Input v-model:value="table.reAisle" allowClear></Input>
                </FormItem>
              </Col>
              <Col :span="6"></Col>
            </Row>

          </Form>
          <Row style="margin-bottom: 5px">
            <Col :span="8">
              <Button v-if="checkPermission('RentalManage.Add')" type="primary" size="default" @click="add()">{{ $t('public.name.add') }}</Button>
              <span>&nbsp;</span>
              <Button v-if="checkPermission('RentalManage.Delete')" type="primary" size="default" danger @click="delByIds()">{{ $t('public.name.batchDelete') }}</Button>
            </Col>
            <Col :span="8" :offset="8" style="text-align: right;">
              <Button type="primary" @click="search_submit">{{ $t('public.name.search') }}</Button>
              <span>&nbsp;</span>
              <Button @click="search_reset">{{ $t('public.name.reset') }}</Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <!-- 列表-->
      <Row>
        <Col :span="24">
          <Table
              :row-selection="{selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
              :columns="columns"
              :dataSource="list"
              rowKey="id"
              :loading="loading"
              :pagination="tablePagination"
              :scroll="{ x: 1500, y: 'calc(100vh - 455px)' }"
              @change="chang_page">
            <!-- 插槽 -->
            <template #isEnableSwitchTitle>
              <span>
                {{ $t("rentalManage.table.isEnable") }}
              </span>
            </template>
            <template #isEnableSwitch="{text}">
              <Switch :checked="text.isEnable === '1'" @click="e => switchClick(e, text)" checked-children="开"
                      un-checked-children="关"/>
            </template>

            <!-- 插槽 -->
            <template #actionTitle>
              <span>
                {{ $t("public.table.action") }}
                <Button v-if="checkPermission('RentalManage.Add')" type="primary" size="small"
                        shape="circle" @click="add()">
                  <PlusOutlined/>
                </Button>
              </span>
            </template>
            <template #action="{text}">
              <div>
                <div class="pc-display">
                  <Button v-if="checkPermission('RentalManage.Edit')" type="primary" size="small"
                          @click="edit(text)">{{ $t("public.name.edit") }}
                  </Button>
                  <span>&nbsp;</span>
                  <Button v-if="checkPermission('RentalManage.Delete')" type="primary" danger size="small"
                          @click="del(text)">{{ $t("public.name.del") }}
                  </Button>
                </div>
              </div>
            </template>
          </Table>
        </Col>
      </Row>
    </Col>
    <FormModel ref="refFormModel" @updateList="get_list"></FormModel>
  </Row>
</template>

<script>
import {createVNode} from "vue";
import {
  Button,
  Col,
  Dropdown,
  Form,
  Input,
  Menu,
  message,
  Modal,
  Row,
  Select,
  Switch,
  Table,
  Tag,
  Typography,
  Spin
} from "ant-design-vue";
import {DownOutlined, ExclamationCircleOutlined, PlusOutlined} from "@ant-design/icons-vue";

import {RentalGetListByPage, RentalDelInfo} from "@/api/rental";

import FormModel from "./FormModel";

export default {
  name: "PileManage",
  components: {

    Row,
    Col,
    Table,
    Form,
    FormItem: Form.Item,
    TypographyTitle: Typography.Title,
    Tag,
    Button,
    Input,
    Select,
    SelectOption: Select.Option,
    Dropdown,
    DownOutlined,
    PlusOutlined,
    ExclamationCircleOutlined,
    Menu,
    MenuItem: Menu.Item,
    Switch,
    FormModel,
    Spin
  },
  data() {
    return {
      table: {},
      tablePagination: {
        pageSizeOptions: ["10", "20", "50", "100"],
        showSizeChanger: true,
        current: 1,
        pageSize: 10,
        total: 0,
      },
      loading: false,
      list: [],
      selectedRowKeys: [],
      columns: [
        // {
        //   title: this.$t("public.name.index"),
        //   fixed: "left",
        //   width: 60,
        //   ellipsis: true,
        //   customRender: ({text, record, index, column}) => `${(this.tablePagination.current-1) * this.tablePagination.pageSize +  index + 1}`
        // },
        {title: this.$t("public.name.ID"), fixed: "center", width: 60, ellipsis: true, dataIndex: "id", key: "id"},
        {title: this.$t("rentalManage.table.reId"), fixed: "center", width: 150, ellipsis: true, dataIndex: "reId", key: "reId"},
        {title: this.$t("rentalManage.table.reName"), fixed: "center", width: 100, ellipsis: true, dataIndex: "reName", key: "reName"},
        {
          title: this.$t("rentalManage.table.reSecKey"),
          fixed: "center",
          width: 100,
          ellipsis: true,
          dataIndex: "reSecKey",
          key: "reSecKey"
        },
        {
          title: this.$t("rentalManage.table.reAisle"),
          fixed: "center",
          width: 100,
          ellipsis: true,
          dataIndex: "reAisle",
          key: "reAisle"
        },

        // { title: this.$t("rentalManage.table.reStatus"), fixed: "center", width: 100, dataIndex: "reStatus", key: "reStatus" },
        // { title: this.$t("rentalManage.table.reArgs"), fixed: "center", width: 100, dataIndex: "reArgs", key: "reArgs" },
        {
          title: this.$t("rentalManage.table.reIsAuthorized"),
          fixed: "center",
          width: 100,
          ellipsis: true,
          dataIndex: "reIsAuthorized",
          key: "reIsAuthorized",
          customRender: ({text, record, index, column}) => {
            if (text === "1") {
              return this.$t('public.isTrue.1')
            }
            return this.$t('public.isTrue.0')
          }
        },
        {
          title: this.$t("rentalManage.table.createTime"),
          fixed: "center",
          width: 150,
          ellipsis: true,
          dataIndex: "createTime",
          key: "createTime"
        },
        {key: "action", fixed: "right", width: 80, ellipsis: true, slots: {title: "actionTitle", customRender: "action"}},
      ],
      isClickLoading: false
    };
  },
  methods: {
    chang_page(pagination) {
      this.tablePagination = pagination;
      this.get_list();
    },
    get_list() {
      this.loading = true;
      const api = new RentalGetListByPage();
      // 构建查询参数
      const getData = {
        ...this.table,
        page: this.tablePagination.current,
        limit: this.tablePagination.pageSize,
      }
      api.get(getData)
          .then((response) => {
            this.loading = false;
            if (0 === parseInt(response.data.code)) {
              const dataTmp = response.data.data;
              this.tablePagination.current = parseInt(dataTmp.current);
              this.tablePagination.pageSize = parseInt(dataTmp.pageSize);
              this.tablePagination.total = parseInt(dataTmp.total);
              this.list = dataTmp.data
            } else {
              this.list = [];
            }
          });
    },
    add() {
      this.$refs.refFormModel.addShow();
    },
    edit(obj) {
      this.$refs.refFormModel.editShow(obj);
    },
    del(obj) {
      Modal.confirm({
        title: this.$t("public.name.are_you_sure_delete") + "?",
        content: this.$t("public.name.action_cannot_resumed"),
        icon: createVNode(ExclamationCircleOutlined),
        okText: this.$t("public.name.yes"),
        okType: "danger",
        cancelText: this.$t("public.name.no"),
        onOk: () => {
          const api = new RentalDelInfo();
          let l = []
          l.push(obj.id)
          api.post({ids: l}).then((response) => {
            parseInt(response.data.code) === 0 ? message.success(response.data.msg) : message.error(response.data.msg);
            // 刷新列表
            this.get_list();
          });
        },
      });
    },
    onSelectChange(ids) {
      this.selectedRowKeys = ids
    },
    delByIds() {
      Modal.confirm({
        title: this.$t("public.name.are_you_sure_delete") + "?",
        content: this.$t("public.name.action_cannot_resumed"),
        icon: createVNode(ExclamationCircleOutlined),
        okText: this.$t("public.name.yes"),
        okType: "danger",
        cancelText: this.$t("public.name.no"),
        onOk: () => {
          const api = new RentalDelInfo();
          api.post({ids: this.selectedRowKeys}).then((response) => {
            parseInt(response.data.code) === 0 ? message.success(response.data.msg) : message.error(response.data.msg);
            // 刷新列表
            this.get_list();
          });
        },
      });
    },
    search_submit() {
      this.tablePagination.current = 1
      this.tablePagination.pageSize = 10
      this.get_list()
    },
    // 重置查询表单
    search_reset() {
      this.table = {}
      this.get_list()
    },
  },
  mounted() {
    this.get_list();
  },
}
</script>

<style lang="less">
@import url("style");
</style>
