<template>
  <Row id="body">
    <!-- 标签-->
    <Col :span="24" class="title">
      <TypographyTitle>Device</TypographyTitle>
    </Col>
    <Col :span="24" class="content">
      <Row :gutter="[16, 32]">
        <Col :span="24">
          <Row style="margin-bottom: 15px">
            <Col :span="6" style="font-size: 26px">Device</Col>
            <Col :span="12"></Col>
            <Col :span="6" align="right">
              <Button type="primary">Device Filtter</Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <!-- 列表-->
      <Row>
        <Col :span="24">
          <Table
            :columns="columns"
            :dataSource="list"
            rowKey="id"
            :loading="loading"
            :scroll="{ x: 1500, y: 'calc(100vh - 300px)' }"
            :pagination="tablePagination"
            @change="chang_page"
          >
            <template #headerCell="{ column }">
              <template v-if="column.dataIndex === 'action'">
                <span>
                  {{ $t("public.table.action") }}
                </span>
              </template>
            </template>

            <template #bodyCell="{ column, record }">
              <template v-if="column.dataIndex === 'action'">
                <div class="pc-display">
                  <Button type="link" size="small" @click="showDetail(record)">Detail</Button>
                </div>
              </template>
            </template>
          </Table>
          <div v-if="showLoadingBut" style="width: 100%; text-align: center; margin-top: 5px">
            <Button @click="onClickLoading">loading({{ tablePagination.pageSize }})...</Button>
          </div>
        </Col>
      </Row>
      <div ref="ModalRef" class="TabsModal">
        <Modal
          v-model:open="ModalOpen"
          :mask="false"
          width="100%"
          wrap-class-name="full-modal"
          @ok="handleOk"
          :getContainer="() => $refs.ModalRef"
          :footer="false"
          class="TabsModal"
        >
          <Row
            style="
              background-color: #0d5480;
              padding: 20px 24px;
              color: white;
              border-top-left-radius: 8px;
              border-top-right-radius: 8px;
            "
          >
            <Col span="24" style="font-size: 22px">My Device</Col>
            <Col span="24">Device Detail</Col>
          </Row>
          <Row style="padding: 0 24px">
            <Col span="24">
              <Tabs v-model:activeKey="activeKey">
                <Tabs.TabPane key="1" tab="Details">
                  <Col span="24">
                    <Button class="colorBtn">Open details page</Button>
                    <Button class="colorBtn">Make device public</Button>
                    <Button class="colorBtn">Assign to customer</Button>
                    <Button class="colorBtn" @click="CredentialsOpen = true">Manage credentials</Button>
                    <Button class="colorBtn">check connectivity</Button>
                    <Button class="colorBtn">Delete device</Button>
                  </Col>
                  <Col span="24">
                    <Button class="colorBtn" style="background-color: #fdfefe; color: black">Copy device ID</Button>
                    <Button class="colorBtn" style="background-color: #fdfefe; color: black">Copy device token</Button>
                  </Col>
                  <Col span="24" style="background-color: #f7fbfc">
                    <div class="colorInput">
                      <div style="padding: 1px 11px; color: #d2d6d6; font-size: 11px">Name</div>
                      <div><Input style="background-color: #f7fbfc" placeholder="My New Device"></Input></div>
                    </div>
                  </Col>
                  <Col span="24" style="background-color: #f7fbfc">
                    <div class="colorInput">
                      <div style="padding: 1px 11px; color: #d2d6d6; font-size: 11px">Device Profile</div>
                      <div><Input style="background-color: #f7fbfc" placeholder="default"></Input></div>
                    </div>
                  </Col>
                  <Col span="24" style="background-color: #f7fbfc">
                    <div class="colorInput">
                      <div><Input style="background-color: #f7fbfc; height: 50px" placeholder="Label"></Input></div>
                    </div>
                  </Col>
                  <Col span="24" style="background-color: #f7fbfc">
                    <div class="colorInput">
                      <div>
                        <Input style="background-color: #f7fbfc; height: 50px" placeholder="Assigned firmware"></Input>
                      </div>
                    </div>
                  </Col>
                  <Col span="24" style="background-color: #f7fbfc">
                    <div class="colorInput">
                      <div>
                        <Input style="background-color: #f7fbfc; height: 50px" placeholder="Assigned software"></Input>
                      </div>
                    </div>
                  </Col>
                  <Col span="24" style="margin-top: 20px">
                    <Switch v-model:checked="checked" />
                    Is gateway
                  </Col>
                  <Col span="24" style="background-color: #f7fbfc">
                    <div class="colorInput">
                      <div>
                        <Input style="background-color: #f7fbfc; height: 50px" placeholder="Description"></Input>
                      </div>
                    </div>
                  </Col>
                </Tabs.TabPane>
                <Tabs.TabPane key="2" tab="Attrlbutes">
                  <Row align="middle" style="margin-bottom: 10px">
                    <Col span="12">Server attributes</Col>
                    <Col span="12" align="right">
                      <PlusOutlined style="margin-right: 10px; font-size: 20px" />
                      <RedoOutlined style="margin-right: 10px; font-size: 20px" />
                      <SearchOutlined style="font-size: 20px" />
                    </Col>
                  </Row>
                  <Col span="24">
                    <Table :row-selection="rowSelection" :dataSource="dataSource" :columns="servercolumns">
                      <template #headerCell="{ column }">
                        <template v-if="column.dataIndex === 'action'">
                          <span>
                            {{ $t("public.table.action") }}
                          </span>
                        </template>
                      </template>
                      <template #bodyCell="{ column, record }">
                        <template v-if="column.dataIndex === 'action'">
                          <div class="pc-display">
                            <Button type="link" size="small" @click="showDetail(record)"><EditOutlined /></Button>
                          </div>
                        </template>
                      </template>
                    </Table>
                  </Col>
                </Tabs.TabPane>
                <Tabs.TabPane key="3" tab="Latest telemetry">
                  <Col span="24">
                    <Table :dataSource="dataSource" :columns="servercolumns"></Table>
                  </Col>
                </Tabs.TabPane>
                <Tabs.TabPane key="4" tab="Alarms">
                  <Col span="24">
                    <Button class="borderBtn">
                      <AlignCenterOutlined />
                      Filter:Active
                    </Button>
                    <Button class="borderBtn">
                      <ClockCircleOutlined style="margin-right: 5px" />
                      For all time
                    </Button>
                  </Col>
                  <Col span="24">
                    <Table :row-selection="rowSelection" :dataSource="alarmdataSource" :columns="alarmcolumns">
                      <template #headerCell="{ column }">
                        <template v-if="column.dataIndex === 'action'">
                          <span>
                            {{ $t("public.table.action") }}
                          </span>
                        </template>
                      </template>
                      <template #bodyCell="{ column, record }">
                        <template v-if="column.dataIndex === 'action'">
                          <div class="pc-display">
                            <Button type="link" size="small"><SmallDashOutlined style="font-size: 22px" /></Button>
                          </div>
                        </template>
                        <template v-if="column.dataIndex === 'severity'">
                          <span style="color: red">
                            {{ record.severity }}
                          </span>
                        </template>
                        <template v-if="column.dataIndex === 'assignee'">
                          <Avatar></Avatar>
                          <span style="margin: 0 10px">
                            {{ record.assignee }}
                          </span>
                          <DownOutlined />
                        </template>
                      </template>
                    </Table>
                  </Col>
                </Tabs.TabPane>
                <Tabs.TabPane key="5" tab="Events">
                  <Col span="24" style="font-size: 11px; color: #0c5580">Event type</Col>
                  <Col span="24">
                    <Select
                      style="width: 200px; border-bottom: 1px solid #09537d"
                      :bordered="false"
                      v-model:value="selectValue"
                    >
                      <Select.Option value="jack">Error</Select.Option>
                      <Select.Option value="lucy">Lifecycle event</Select.Option>
                      <Select.Option value="Yiminghe">Statistic</Select.Option>
                    </Select>
                    <Button style="border-color: #0c5580; color: #0c5580; margin-left: 10px">
                      <ClockCircleOutlined />
                      last 15 minutes
                    </Button>
                  </Col>
                  <Col span="24">
                    <Table :dataSource="eventdatasource" :columns="eventcolumns"></Table>
                  </Col>
                </Tabs.TabPane>
                <Tabs.TabPane key="6" tab="Relations">
                  <Col span="24">
                    <Table :dataSource="dataSource" :columns="servercolumns"></Table>
                  </Col>
                </Tabs.TabPane>
                <Tabs.TabPane key="7" tab="Relations">
                  <Col span="24">
                    <Table :dataSource="dataSource" :columns="servercolumns"></Table>
                  </Col>
                </Tabs.TabPane>
              </Tabs>
            </Col>
          </Row>
        </Modal>
      </div>
      <div ref="Credentials" class="CredentialsModal">
        <Modal
          v-model:open="CredentialsOpen"
          :mask="false"
          wrap-class-name="full-modal"
          @ok="handleOk"
          :getContainer="() => $refs.Credentials"
          :footer="false"
          class="TabsModal"
        >
          <Row
            style="
              background-color: #0d5480;
              padding: 20px 24px;
              color: white;
              border-top-left-radius: 8px;
              border-top-right-radius: 8px;
            "
          >
            <Col span="24" style="font-size: 22px">Device Credentials</Col>
          </Row>
          <Row style="padding: 20px 24px">
            <Col span="24">Credentials type</Col>

            <Col span="24">
              <Row class="typeBox">
                <Col span="8" align="center" @click="changeSelect('1')" :class="selectType == 1 ? 'selectBox' : ''">
                  Access token
                </Col>
                <Col span="8" align="center" @click="changeSelect('2')" :class="selectType == 2 ? 'selectBox' : ''">
                  X509
                </Col>
                <Col span="8" align="center" @click="changeSelect('3')" :class="selectType == 3 ? 'selectBox' : ''">
                  MQTT Basic
                </Col>
              </Row>
            </Col>
            <Col span="24" style="background-color: #f7fbfc; margin-top: 15px">
              <div>
                <div style="padding: 1px 11px; color: #d2d6d6; font-size: 11px">Device Profile</div>
                <div style="position: relative">
                  <Input style="background-color: #f7fbfc" placeholder="default"></Input>
                  <div class="reloadBox">
                    <RedoOutlined />
                  </div>
                </div>
              </div>
            </Col>
            <Col span="24" style="background-color: #f7fbfc; margin-top: 15px">
              <div>
                <div style="padding: 1px 11px; color: #d2d6d6; font-size: 11px">Device Profile</div>
                <div style="position: relative">
                  <Input style="background-color: #f7fbfc" placeholder="default"></Input>
                  <div class="reloadBox">
                    <RedoOutlined />
                  </div>
                </div>
              </div>
            </Col>
            <Col span="24" style="background-color: #f7fbfc; margin-top: 15px">
              <div>
                <div style="padding: 1px 11px; color: #d2d6d6; font-size: 11px">Device Profile</div>
                <div style="position: relative">
                  <Input.Password
                    style="background-color: #f7fbfc"
                    placeholder="default"
                    :bordered="false"
                    :controls="false"
                  ></Input.Password>
                  <div class="reloadBox">
                    <RedoOutlined />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Modal>
      </div>
    </Col>
  </Row>
</template>
<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "deviceManage",
};
</script>
<script setup>
import { createVNode, ref } from "vue";

import {
  Button,
  Col,
  TypographyTitle,
  Modal,
  Row,
  Table,
  Tag,
  Tabs,
  Avatar,
  Input,
  Switch,
  InputNumber,
  Select,
} from "ant-design-vue";
import { useI18n } from "vue-i18n";
import {
  EditOutlined,
  PlusOutlined,
  SearchOutlined,
  RedoOutlined,
  ClockCircleOutlined,
  AlignCenterOutlined,
  SmallDashOutlined,
  DownOutlined,
} from "@ant-design/icons-vue";

import { useRouter } from "vue-router";
import { Footer } from "ant-design-vue/es/layout/layout";

const i18n = useI18n();

const router = useRouter();
// const ModalRef = ref(null);

let checked = ref(false);

let selectValue = ref("Error");

let showLoadingBut = ref(false);

let ModalOpen = ref(false);
let CredentialsOpen = ref(false);

let selectType = ref("3");

let eventdatasource = [];

const eventcolumns = [
  { title: "Event", ellipsis: true, dataIndex: "event", key: "event" },

  { title: "Method", ellipsis: true, dataIndex: "method", key: "method" },
  {
    title: "Error",
    ellipsis: true,
    dataIndex: "error",
    key: "error",
  },
];

let alarmdataSource = [
  {
    createdtime: "2023-10-31 13:11:55",
    originator: "My Davice",
    severity: "Critical",
    type: "High Temperatune",
    assignee: "Unassignee",
  },
];

const alarmcolumns = [
  { title: "Created time", ellipsis: true, dataIndex: "createdtime", key: "createdtime" },

  { title: "Originator", ellipsis: true, dataIndex: "originator", key: "originator" },
  {
    title: "Type",
    ellipsis: true,
    dataIndex: "type",
    key: "type",
  },
  {
    title: "Severity",
    ellipsis: true,
    dataIndex: "severity",
    key: "severity",
  },
  {
    title: "Assignee",
    ellipsis: true,
    dataIndex: "assignee",
    key: "assignee",
    width: 200,
  },

  { dataIndex: "action", key: "action", fixed: "right", width: 145, ellipsis: true },
];

let dataSource = [
  {
    lasttime: "2024-08-31 10:31",
    key: "inactiityAlarmTime",
    value: "false",
  },
  {
    lasttime: "2024-08-31 10:31",
    key: "active",
    value: "4597454894651894",
  },
  {
    lasttime: "2024-08-31 10:31",
    key: "lastActivityTime",
    value: "45954145541894",
  },
  {
    lasttime: "2024-09-20 10:31",
    key: "lastConnectTime",
    value: "4597414954651894",
  },
  {
    lasttime: "2024-08-31 10:31",
    key: "lastDisconnectTime",
    value: "564849519894",
  },
];

const servercolumns = [
  { title: "Last update time", ellipsis: true, dataIndex: "lasttime", key: "lasttime" },

  { title: "Key", ellipsis: true, dataIndex: "key", key: "key" },
  {
    title: "Value",
    ellipsis: true,
    dataIndex: "value",
    key: "value",
  },

  { dataIndex: "action", key: "action", fixed: "right", width: 145, ellipsis: true },
];

const columns = [
  { title: "Name", ellipsis: true, dataIndex: "Name", key: "Name" },

  { title: "Device Profile", ellipsis: true, dataIndex: "DeviceProfile", key: "DeviceProfile" },
  {
    title: "Label",
    ellipsis: true,
    dataIndex: "Label",
    key: "Label",
  },

  { dataIndex: "action", key: "action", fixed: "right", width: 145, ellipsis: true },
];

let tablePagination = ref({
  pageSizeOptions: ["10", "20", "50", "100"],
  showSizeChanger: true,
  current: 1,
  pageSize: 20,
  total: 0,
  lastRowKey: undefined,
});
let loading = ref(false);
let list = ref([]);

let infoList = ref([]);

function changeSelect(data) {
  selectType.value = data;
}

function chang_page(pagination) {
  tablePagination.value = pagination;
  get_list();
}

function showDetail(info) {
  console.log(info);
  ModalOpen.value = true;
}

function get_list(lastRowKey) {
  loading.value = true;

  list.value = [
    {
      Name: "My Device",
      DeviceProfile: "Default",
      Label: "1",
    },
  ];

  loading.value = false;
}

function clickId(id, category) {
  router.push({
    path: "/DeviceLogs",
    query: {
      id: id,
      category: category,
    },
  });
}

// 加载更多数据
function onClickLoading() {
  get_list(tablePagination.value.lastRowKey);
}

const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(`selectedRowKeys: ${selectedRowKeys}`, "selectedRows: ", selectedRows);
  },
  getCheckboxProps: (record) => ({
    disabled: record.name === "Disabled User", // Column configuration not to be checked
    name: record.name,
  }),
};

get_list(undefined);
</script>

<style lang="less" scoped>
@import url("style");
</style>
