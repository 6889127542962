<template>
  <Row id="controlPanel" :gutter="[8, 8]">
    <Col :span="24">
      <swiper-container :slides-per-view="slidesPerViewNum">
        <swiper-slide v-for="(item, index) in attributes.status" :key="index">
          <componentStatistic
            :title="getText(item)"
            :num="!item.values.scale || !item.values.unit ? statusObj[item.code] : statusObj[item.code] / Math.pow(10, item.values?.scale) + item.values.unit"
          />
        </swiper-slide>
      </swiper-container>
    </Col>
    <Col :span="24">
      <Row :gutter="[16, 16]">
        <Col v-if="attributes.functions" :xs="24" :sm="24" :md="24" :lg="12" :xl="12" :xxl="8">
          <Card title="Functions">
            <Form ref="refForm" :model="form" layout="vertical" :rules="validateRules">
              <Row justify="space-around">
                <template v-for="(item, index) in attributes.functions" :key="index">
                  <Col span="10" v-if="item['type'] !== 'Raw'">
                    <FormItem :label="getText(item)" :name="item.code">
                      <Input v-if="item['type'] === 'String'" :placeholder="item.desc" v-model:value="form[item.code]"></Input>
                      <Select v-if="item['type'] === 'Enum'" :placeholder="item.desc" v-model:value="form[item.code]" :dropdownStyle="{ zIndex: 9999999950 }">
                        <SelectOption :key="sk" v-for="(it, sk) in item.values.range" :value="it">{{ it }}</SelectOption>
                      </Select>
                      <InputNumber
                        v-if="item['type'] === 'Integer'"
                        :placeholder="item.desc"
                        v-model:value="form[item.code]"
                        :step="item.values.step"
                        :min="item.values.min"
                        :max="item.values.max"
                        style="width: 100%"
                      ></InputNumber>
                      <Switch v-if="item['type'] === 'Boolean'" :placeholder="item.desc" v-model:value="form[item.code]" :checked="form[item.code]" @click="(e) => switchClick(e, item.code)"></Switch>
                    </FormItem>
                  </Col>
                </template>
                <Col span="24">
                  <Button type="primary" @click="submit">{{ $t("public.name.submit") }}</Button>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
        <Col :xs="24" :sm="24" :md="24" :lg="12" :xl="12" :xxl="8" v-for="(item, index) in attributes.status" :key="index">
          <TemperatureChart v-if="item.code === 'va_temperature'" :device-id="deviceId" :title="getText(item)"></TemperatureChart>
          <HumidityChart v-else-if="item.code === 'va_humidity'" :device-id="deviceId" :title="getText(item)"></HumidityChart>
          <BatteryChart v-else-if="item.code === 'battery_percentage'" :device-id="deviceId" :title="getText(item)"></BatteryChart>
          <PirChart v-else-if="item.code === 'pir'" :device-id="deviceId" :title="getText(item)"></PirChart>
        </Col>
      </Row>
    </Col>
  </Row>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import { Row, Col, message, Card, Form, FormItem, Input, InputNumber, Switch, Descriptions, DescriptionsItem, Select, Button, SelectOption, Divide } from "ant-design-vue";
import { register } from "swiper/element/bundle";
register();
import componentStatistic from "@/components/Dashboard/Statistic.vue";
import TemperatureChart from "@/components/charts/TemperatureChart.vue";
import HumidityChart from "@/components/charts/HumidityChart.vue";
import BatteryChart from "@/components/charts/BatteryChart.vue";
import PirChart from "@/components/charts/PirChart.vue";
import { useRoute } from "vue-router";
import { DeviceStatus, DeviceFunctions, DeviceSendFunction, DeviceSpecificationAttributes, DeviceSendAction, DeviceSendProperties } from "@/api/tuya";

import chineseConv from "chinese-conv";

const slidesPerViewNum = ref(1);

import { useI18n } from "vue-i18n";

const i18n = useI18n();
const route = useRoute();

const emits = defineEmits(["updateList"]);

const refForm = ref();

let title = ref("");
let open = ref(false);
let confirmLoading = ref(false);

let isZh = ref(i18n.locale.value === "zhTW" || i18n.locale.value === "zhCN");

// 根据语言，选择显示不同的名称
const getText = (item) => {
  if (i18n.locale.value === "zhTW") {
    return chineseConv.tify(item.name);
  } else if (i18n.locale.value === "enUS") {
    return item.code;
  } else {
    return item.name;
  }
};

const deviceId = ref("");

const attributes = ref({});
const statusList = ref([]);
const statusObj = ref({});
const functionList = ref([]);

let form = ref({});
const validateRules = {
  ids: [
    {
      required: false,
      message: i18n.t("chargerManage.validate.id"),
      trigger: "change",
    },
  ],
};

function submit() {
  refForm.value
    .validate()
    .then(() => {
      confirmLoading.value = true;
      sendProperties(form.value);
      getDeviceSpecificationAttributes(deviceId.value);
      getStatus(deviceId.value);
    })
    .catch(() => {});
}

function _resetData() {
  form.value = {};
}

function switchClick(e, code) {
  form.value[code] = e;
}

// 获取设备规格
function getDeviceSpecificationAttributes(deviceId) {
  attributes.value = {};
  const api = new DeviceSpecificationAttributes();
  api.get({ deviceId: deviceId }).then((response) => {
    console.log("getDeviceSpecificationAttributes => ", response.data);
    if (response.data.msg !== undefined) {
      message.error(response.data.msg);
      return;
    }
    attributes.value = response.data;
    attributes.value.status?.forEach((item) => {
      item.values = JSON.parse(item.values);
    });
    attributes.value.functions?.forEach((item) => {
      item.values = JSON.parse(item.values);
    });
  });
}

// 获取设备当前状态
function getStatus(deviceId) {
  statusList.value = [];
  const api = new DeviceStatus();
  api.get({ deviceId: deviceId }).then((response) => {
    confirmLoading.value = false;
    if (response.data.msg !== undefined) {
      message.error(response.data.msg);
      return;
    }
    response.data?.forEach((item) => {
      statusObj.value[item.code] = item.value;
      form.value[item.code] = item.value;
    });
    statusList.value = response.data;
  });
}

// 下发属性
function sendProperties(commands) {
  const api = new DeviceSendProperties();
  const param = {
    deviceId: deviceId.value,
    commands: JSON.stringify(commands),
  };
  api.post(param).then((response) => {
    if (response.data.msg !== undefined) {
      message.error(response.data.msg);
      return;
    }
    message.success("success");
    // 获取最新状态
    setTimeout(() => {
      getStatus(deviceId.value);
    }, 2000); // 2秒后执行
  });
}

const computed_slidesPerViewNum = () => {
  let clientWidth = document.body.clientWidth - 200;
  if (clientWidth < 260) {
    slidesPerViewNum.value = 1;
  } else {
    slidesPerViewNum.value = Math.floor(clientWidth / 260);
  }
};
window.addEventListener("resize", () => computed_slidesPerViewNum());

onMounted(() => {
  deviceId.value = route.query.id;
  console.log(route.query.id);
  _resetData();
  getDeviceSpecificationAttributes(deviceId.value);
  getStatus(deviceId.value);
  computed_slidesPerViewNum();
});
</script>

<style lang="less">
#controlPanel {
  // background-color: #ffffff;
  padding: 30px;
}
</style>
