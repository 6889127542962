<template>
  <Modal
      id="FormModel"
      width="50%"
      :visible="visible"
      @cancel="visible = !visible"
      @ok="submit"
      :title="title"
      :confirmLoading="buttonLoading"
  >
    <Row align="middle" justify="center">
      <Col span="18">
        <Row>
          <Col span="24">
            <Form
                ref="refForm"
                :model="form"
                layout="vertical"
                :rules="validateRules"
            >
              <Row justify="space-around">
                <Col span="10">
                  <FormItem :label="$t('rentalManage.table.id')" name="id">
                    <Input
                        :disabled="true"
                        :placeholder="$t('rentalManage.validate.id')"
                        v-model:value="form.id">
                    </Input>
                  </FormItem>
                </Col>

                <Col span="10">
                  <FormItem :label="$t('rentalManage.table.reId')" name="reId">
                    <Input
                        :disabled="dsKey !== 'admin'"
                        :placeholder="$t('rentalManage.validate.reId')"
                        v-model:value="form.reId">
                    </Input>
                  </FormItem>
                </Col>

                <Col span="10">
                  <FormItem :label="$t('rentalManage.table.reName')" name="reName">
                    <Input
                        :disabled="dsKey !== 'admin'"
                        :placeholder="$t('rentalManage.validate.reName')"
                        v-model:value="form.reName"
                    >
                    </Input>
                  </FormItem>
                </Col>

                <Col span="10">
                  <FormItem :label="$t('rentalManage.table.reSecKey')" name="reSecKey">
                    <Input

                        :placeholder="$t('rentalManage.validate.reSecKey')"
                        v-model:value="form.reSecKey">
                    </Input>
                  </FormItem>
                </Col>

                <Col span="10">
                  <FormItem :label="$t('rentalManage.table.reIsAuthorized')" name="reIsAuthorized">
                    <Select
                        :disabled="dsKey !== 'admin'"
                        v-model:value="form.reIsAuthorized"
                        :placeholder="$t('rentalManage.validate.reIsAuthorized')"
                        allowClear
                    >
                      <SelectOption :value="'0'">{{ $t('public.isTrue.0') }}</SelectOption>
                      <SelectOption :value="'1'">{{ $t('public.isTrue.1') }}</SelectOption>
                    </Select>
                  </FormItem>
                </Col>

                <Col span="10">
                  <FormItem :label="$t('rentalManage.table.tenantKey')" name="tenantKey">
                    <Select
                        :disabled="dsKey !== 'admin'"
                        v-model:value="form.tenantKey"
                        show-search
                        :placeholder="$t('rentalManage.validate.tenantKey')"
                        style="width: 100%"
                        :options="options"
                        :filter-option="filterOption"
                        @change="handleChange"
                    ></Select>
                  </FormItem>
                </Col>

                <Col span="22">
                  <FormItem :label="$t('rentalManage.table.remark')" name="remark">
                    <Input
                        :disabled="dsKey !== 'admin'"
                        :placeholder="$t('rentalManage.validate.remark')"
                        v-model:value="form.remark">
                    </Input>
                  </FormItem>
                </Col>

                <Col span="22">
                  <FormItem :label="$t('rentalManage.table.reAisle')" name="reAisle">
                    <Textarea
                        :disabled="dsKey !== 'admin'"
                        :placeholder="$t('rentalManage.validate.reAisle')"
                        v-model:value="form.reAisle">
                    </Textarea>
                  </FormItem>
                </Col>

              </Row>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  </Modal>
</template>

<script>
import {
  Modal,
  Row,
  Col,
  Form,
  Input,
  InputNumber,
  Image,
  Radio,
  Steps,
  Step,
  Button,
  message,
  Select,
  DatePicker,
  InputGroup,
  Upload,
  Descriptions,
  DescriptionsItem,
  Space,
  Card,
  Table,
  Popconfirm,
} from "ant-design-vue";
import {
  UploadOutlined,
  UserOutlined,
  SolutionOutlined,
  FileImageOutlined,
  CheckOutlined,
  PlusOutlined,
  MinusCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons-vue";

import {RentalAddInfo, RentalEditInfo, RentalUserGetTenantKeyList} from "@/api/rental";
import store from "@/store";

export default {
  name: "FormModel",
  components: {
    Modal,
    Row,
    Col,
    UserOutlined,
    SolutionOutlined,
    FileImageOutlined,
    CheckOutlined,
    UploadOutlined,
    PlusOutlined,
    MinusCircleOutlined,
    ExclamationCircleOutlined,
    Form: Form,
    FormItem: Form.Item,
    Input: Input,
    InputPassword: Input.Password,
    Textarea: Input.TextArea,
    InputGroup,
    InputNumber,
    Image,
    RadioGroup: Radio.Group,
    RadioButton: Radio.Button,
    Steps,
    Step,
    Button,
    message,
    Select,
    SelectOption: Select.Option,
    DatePicker,
    Upload,
    Descriptions,
    DescriptionsItem,
    Space,
    Card,
    Table,
    Popconfirm,
  },
  data() {
    return {
      dsKey: undefined,
      options: [],
      buttonLoading: false,
      visible: false,
      actionType: "",
      form: {
        id: undefined,
        reId: undefined,
        reName: undefined,
        reAisle: undefined,
        tenantKey: undefined,
        reStatus: undefined,
        reArgs: undefined,
        reIsAuthorized: undefined,
        reLeaseTime: undefined,
        remark: undefined,
      },
      fileList: {},
      validateRules: {
        id: [
          {
            required: false,
            message: this.$t("rentalManage.validate.id"),
            trigger: "change",
          },
        ],
        reId: [
          {
            required: true,
            message: this.$t("rentalManage.validate.reId"),
            trigger: "change",
          },
        ],
        reName: [
          {
            required: true,
            message: this.$t("rentalManage.validate.reName"),
            trigger: "change",
          },
        ],
        reAisle: [
          {
            required: true,
            message: this.$t("rentalManage.validate.reAisle"),
            trigger: "change",
          },
        ],
        reStatus: [
          {
            required: true,
            message: this.$t("rentalManage.validate.reStatus"),
            trigger: "change",
          },
        ],
        reIsAuthorized: [
          {
            required: true,
            message: this.$t("rentalManage.validate.reIsAuthorized"),
            trigger: "change",
          },
        ],
        tenantKey: [
          {
            required: true,
            message: this.$t("rentalManage.validate.tenantKey"),
            trigger: "change",
          },
        ],
        reLeaseTime: [
          {
            required: true,
            message: this.$t("rentalManage.validate.reLeaseTime"),
            trigger: "change",
          },
        ],
        remark: [
          {
            required: false,
            message: this.$t("rentalManage.validate.remark"),
            trigger: "change",
          },
        ],
      },
    };
  },
  computed: {
    title: function () {
      let name = "";
      switch (this.actionType) {
        case "add":
          name = this.$t("public.name.add");
          break;
        case "edit":
          name = this.$t("public.name.edit");
          break;
      }
      return name;
    },
  },
  watch: {
    "visible": {
      deep: true,
      handler: function (newValue, oldValue) {
        if (!newValue) {
          this.$emit("updateList");
        }
      }
    }
  },
  methods: {
    addShow() {
      this.dsKey = this.$store.getters.getDsKey
      this.actionType = "add";
      this._resetData();
      this.getUserTenantKeyList()
      this.visible = true;
    },
    async editShow(obj) {
      this.dsKey = this.$store.getters.getDsKey
      // 格式化日期
      // obj.reLeaseTime = obj.reLeaseTime === undefined ? new Date() : moment(obj.reLeaseTime).format('YYYY-MM-DD HH:mm:ss')
      this._resetData();
      this.actionType = "edit";
      this.getUserTenantKeyList()
      this.form = obj;
      this.visible = true;
    },
    submit() {
      this.$refs["refForm"].validate().then(() => {
        this.buttonLoading = true
        let api;
        switch (this.actionType) {
          case "add":
            console.log("add");
            api = new RentalAddInfo();
            break;
          case "edit":
            console.log("edit");
            api = new RentalEditInfo();
            break;
          default:
            return false;
        }
        // 取的是后台服务器时间，前端需要过滤掉时间(或者格式化时间)，否则后端校验异常
        // this.form.reLeaseTime = moment(this.form.reLeaseTime).format('YYYY-MM-DD HH:mm:ss');
        delete this.form.createTime
        delete this.form.updateTime
        api.post(this.form).then((response, any) => {
          this.buttonLoading = false
          if (0 === parseInt(response.data.code)) {
            message.success(response.data.msg);
            // 触发主页面组件定义事件
            this.$emit("updateList", response.data.data);
            this.visible = false;
          } else {
            message.error(response.data.msg);
          }
        });
      }).catch(() => {});
    },

    // 获取用户表中的租户列表
    getUserTenantKeyList() {
      const api = new RentalUserGetTenantKeyList();
      api.get().then(res => {
        if (0 === parseInt(res.data.code)) {
          let l = []
          res.data.data.forEach(item => {
            l.push({value: item, label: item})
          })
          this.options = l
        } else {
          message.error(res.data.msg);
        }
      })
    },
    filterOption(input, option) {
      return option.label.indexOf(input) >= 0;
    },
    // 搜索框选择事件
    handleChange(value) {
      console.log("搜索框选择事件", value)
    },

    // reset data
    _resetData() {
      this.form = {
        id: undefined,
        reId: undefined,
        reName: undefined,
        reAisle: undefined,
        tenantKey: undefined,
        reStatus: undefined,
        reArgs: undefined,
        reIsAuthorized: undefined,
        remark: undefined,
      };
    },
  },
};
</script>

<style lang="less">
@import url("style");
</style>
