
import {createI18n} from "vue-i18n"
import zhTW from "@/i18n/zhTW"
import enUS from "@/i18n/enUS"
import zhCN from "@/i18n/zhCN"
const i18n = createI18n({
    legacy: false,
    globalInjection: true,
    locale: "enUS",
    fallbackLocale: "enUS",
    messages: {
        zhTW,
        enUS,
        zhCN,
    }
})
export default i18n