<template>
  <Row id="PileManage">
    <!-- 标签-->
    <Col :span="24" class="title">
      <TypographyTitle>{{ $t('nav.name.PileManage') }}</TypographyTitle>
    </Col>
    <Col :span="24" class="content">
      <!-- 查询表单-->
      <Row :gutter="[16,32]">
        <Col :span="24">
          <Form :model="searchForm">
            <Row :gutter="16">
              <Col :span="6">
                <FormItem :label="$t('pileManage.field.identifier')" name="identifier">
                  <Input :placeholder="$t('pileManage.validate.identifier')" v-model:value="searchForm.identifier"
                         allowClear></Input>
                </FormItem>
              </Col>
              <Col :span="6">
                <FormItem :label="$t('pileManage.field.reId')" name="reId">
                  <Select
                      v-model:value="searchForm.reId"
                      show-search
                      :placeholder="$t('pileManage.validate.reId')"
                      style="width: 100%"
                      :options="options"
                      :filter-option="filterOption"
                      @change="handleChange"
                  ></Select>
                </FormItem>
              </Col>
              <Col :span="6">
                <FormItem :label="$t('pileManage.field.evName')" name="evName">
                  <Input :placeholder="$t('pileManage.validate.evName')" v-model:value="searchForm.evName"
                         allowClear></Input>
                </FormItem>
              </Col>
              <Col :span="6">
                <FormItem :label="$t('pileManage.field.evStatus')" name="evStatus">
                  <Select :placeholder="$t('pileManage.validate.evStatus')" v-model:value="searchForm.evStatus"
                          allowClear>
                    <SelectOption :value="1">{{ $t("pileManage.field.evStatusList.Available") }}</SelectOption>
                    <SelectOption :value="3">{{ $t("pileManage.field.evStatusList.UnAvailable") }}</SelectOption>
                  </Select>
                </FormItem>
              </Col>
            </Row>
            <Row :gutter="16">
              <Col span="6">
                <FormItem :label="$t('pileManage.field.evType')" name="evType">
                  <Input :placeholder="$t('pileManage.validate.evType')" v-model:value="searchForm.evType"
                         allowClear></Input>
                </FormItem>
              </Col>
              <Col span="6">
                <FormItem :label="$t('pileManage.field.readMeter')" name="readMeter">
                  <Select v-model:value="searchForm.readMeter" :placeholder="$t('public.placeholder.please_select')">
                    <SelectOption :value="'0'"> {{ $t('pileManage.field.readMeterList.0') }}</SelectOption>
                    <SelectOption :value="'1'"> {{ $t('pileManage.field.readMeterList.1') }}</SelectOption>
                  </Select>
                </FormItem>
              </Col>
              <Col span="6"></Col>
              <Col span="6"></Col>
            </Row>
          </Form>
          <Row style="margin-bottom: 5px">
            <Col :span="8">
              <Button v-if="checkPermission('PileManage.Add')" type="primary" size="default" @click="add()">
                {{ $t("public.name.add") }}
              </Button>
              <span>&nbsp;</span>
              <Button v-if="checkPermission('PileManage.Delete')" type="primary" size="default" danger
                      @click="delByIds()">{{ $t("public.name.dels") }}
              </Button>
            </Col>
            <Col :span="8" :offset="8" style="text-align: right;">
              <Button type="primary" @click="search_submit">{{ $t('public.name.search') }}</Button>
              <span>&nbsp;</span>
              <Button @click="search_reset">{{ $t('public.name.reset') }}</Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <!-- 列表-->
      <Row>
        <Col :span="24">
          <Table
              :row-selection="{selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
              :columns="columns"
              :dataSource="list"
              rowKey="id"
              :scroll="{ x: 1500, y: 'calc(100vh - 455px)' }"
              :loading="loading"
              :pagination="tablePagination"
              @change="chang_page">
            <!-- 插槽 -->
            <template #status="{text}">
              <div>
                <!-- 0:未注册；1:正常；2:异常 -->
                <Tag v-if="text === '0'" color="#f39c12">{{ $t("pileManage.field.evStatusList.Detected") }}</Tag>
                <Tag v-else-if="text === '1'" color="#2ed573">{{ $t("pileManage.field.evStatusList.Available") }}</Tag>
                <Tag v-else-if="text === '3'" color="#ff4757">{{ $t("pileManage.field.evStatusList.UnAvailable") }}
                </Tag>
                <Tag v-else color="#747d8c">{{ $t("pileManage.field.evStatusList.UnEnroll") }}</Tag>
              </div>
            </template>
            <!-- 插槽 -->
            <template #actionTitle>
              <span>
                {{ $t("public.table.action") }}
                <Button v-if="checkPermission('PileManage.Add')" type="primary" size="small" shape="circle"
                        @click="add()">
                  <PlusOutlined/>
                </Button>
              </span>
            </template>
            <!-- 插槽 -->
            <template #isEnableSwitchTitle>
              <span>
<!--                {{$t("tenantManage.table.isEnable")}}-->
                URL
              </span>
            </template>
            <template #isEnableSwitch="{text}">
              <Switch :checked="text.byNotify === 1" @click="e => switchClick(e, text)" checked-children="ON"
                      un-checked-children="OFF"/>
            </template>
            <!-- 插槽 -->
            <template #action="{text}">
              <div class="pc-display">
                <Dropdown :trigger="['click']" @click.prevent>
                  <a>
                    {{ $t("public.name.setting") }}
                    <DownOutlined/>
                  </a>
                  <template #overlay>
                    <Menu>
                      <MenuItem v-if="checkPermission('PileManage.Edit')">
                        <Button type="primary" size="small" @click="edit(text)">{{ $t("public.name.edit") }}</Button>
                      </MenuItem>
                      <MenuItem v-if="checkPermission('PileManage.Delete')">
                        <Button type="primary" danger size="small" @click="del(text)">{{
                            $t("public.name.del")
                          }}
                        </Button>
                      </MenuItem>
                      <MenuItem v-if="checkPermission('PileManage.Setting')">
                        <Button type="primary" size="small" @click.stop="showSetting(text)">{{
                            $t("public.name.setting")
                          }}
                        </Button>
                      </MenuItem>
                      <MenuItem v-if="checkPermission('PileManage.Setting')">
                        <Button type="primary" size="small" @click.stop="updateFirmwareShow(text)">
                          {{ $t("pileManage.field.action.updateFirmware") }}
                        </Button>
                      </MenuItem>
                      <MenuItem v-if="checkPermission('PileManage.Setting')">
                        <Button type="primary" size="small" @click.stop="resetPile(text, 'Hard')">
                          {{ $t("pileManage.field.action.hardReset") }}
                        </Button>
                      </MenuItem>
                      <MenuItem v-if="checkPermission('PileManage.Setting')">
                        <Button type="primary" size="small" @click.stop="resetPile(text, 'Soft')">
                          {{ $t("pileManage.field.action.softReset") }}
                        </Button>
                      </MenuItem>
                      <MenuItem v-if="checkPermission('PileManage.Setting')">
                        <Button type="primary" size="small" @click.stop="dataTransferPile(text.identifier)">
                          数据传输
                        </Button>
                      </MenuItem>
                      <MenuItem v-if="checkPermission('PileManage.Setting')">
                        <Button type="primary" size="small" @click.stop="getDiagnostics(text)">
                          获取诊断日志
                        </Button>
                      </MenuItem>
                    </Menu>
                  </template>
                </Dropdown>

                <Dropdown :trigger="['click']" @click.prevent>
                  <a>
                    触发
                    <DownOutlined/>
                  </a>
                  <template #overlay>
                    <Menu>
                      <MenuItem>
                        <Button type="primary" size="small" @click="triggerMessage(text.identifier, 'BootNotification', 0)">BootNotification</Button>
                      </MenuItem>
                      <MenuItem>
                        <Button type="primary" size="small" @click="triggerMessage(text.identifier, 'DiagnosticsStatusNotification', 0)">DiagnosticsStatusNotification</Button>
                      </MenuItem>
                      <MenuItem>
                        <Button type="primary" size="small" @click="triggerMessage(text.identifier, 'FirmwareStatusNotification', 0)">FirmwareStatusNotification</Button>
                      </MenuItem>
                      <MenuItem>
                        <Button type="primary" size="small" @click="triggerMessage(text.identifier, 'Heartbeat', 0)">Heartbeat</Button>
                      </MenuItem>
<!--                      <MenuItem>-->
<!--                        <Button type="primary" size="small" @click="triggerMessage(text.identifier, 'MeterValues', 0)">MeterValues</Button>-->
<!--                      </MenuItem>-->
<!--                      <MenuItem>-->
<!--                        <Button type="primary" size="small" @click="triggerMessage(text.identifier, 'StatusNotification', 0)">StatusNotification</Button>-->
<!--                      </MenuItem>-->
                    </Menu>
                  </template>
                </Dropdown>
              </div>


            </template>
          </Table>
        </Col>
      </Row>
    </Col>
    <FormModel ref="refFormModel" :options="options" @updateList="get_list"/>
    <SettingList ref="refSettingList" @updateList="get_list"/>
    <UpdateFirmwareModel ref="refUpdateFirmwareModel" @updateList="get_list"/>
    <DataTransfer ref="refDataTransfer"/>
    <DiagnosticsModel ref="refDiagnostics"/>
  </Row>
</template>
<script>
export default {
  name: "PileManage",
}
</script>
<script setup>
import {createVNode, reactive, ref, toRefs} from "vue";
import {
  Button,
  Col,
  Dropdown,
  Form,
  FormItem,
  Input,
  Menu,
  MenuItem,
  message,
  Modal,
  Row,
  Select,
  SelectOption,
  Switch,
  Table,
  Tag,
} from "ant-design-vue";
import {DownOutlined, ExclamationCircleOutlined, PlusOutlined} from "@ant-design/icons-vue";

import {
  PileGetListByPage,
  PileDelInfo,
  PileEditInfo,
  PileReset,
  PileTriggerMessage,
} from "@/api/pile";

import FormModel from "./FormModel";
import SettingList from "./SettingList";
import UpdateFirmwareModel from "./UpdateFirmwareModel";
import DataTransfer from "./DataTransfer";
import DiagnosticsModel from "./DiagnosticsModel";
import {RentalGetTenantList} from "@/api/rental";
import {useI18n} from "vue-i18n";

const i18n = useI18n()

const refUpdateFirmwareModel = ref()
const refFormModel = ref()
const refSettingList = ref()
const refDataTransfer = ref()
const refDiagnostics = ref()

let loading = ref(false)

const chargingPortTypeList = [
  {value: '0', label: 'Type 1'},
  {value: '1', label: 'Type 2'},
  {value: '2', label: 'CHAdeMO'},
  {value: '3', label: 'CCS Combo Type 1'},
  {value: '4', label: 'CCS Combo Type 2'},
]

const columns = [
  // {
  //   title: i18n.t("public.name.index"),
  //   fixed: "left",
  //   width: 50,
  //   ellipsis: true,
  //   customRender: ({
  //                    text,
  //                    record,
  //                    index,
  //                    column
  //                  }) => `${(tablePagination.value.current - 1) * tablePagination.value.pageSize + index + 1}`
  // },
  {
    title: i18n.t("public.name.ID"),
    fixed: "center",
    width: 50,
    dataIndex: "id",
    key: "id"
  },
  {title: "session", fixed: "center", width: 165, ellipsis: true, dataIndex: "sessionId", key: "sessionId"},
  {
    title: i18n.t("pileManage.field.identifier"),
    fixed: "center",
    width: 120,
    dataIndex: "identifier",
    key: "identifier"
  },
  {title: i18n.t("pileManage.field.reId"), fixed: "center", width: 80, ellipsis: true, dataIndex: "reId", key: "reId"},
  {
    title: "Vendor",
    fixed: "center",
    width: 80,
    ellipsis: true,
    dataIndex: "chargePointVendor",
    key: "chargePointVendor"
  },
  {
    title: i18n.t("pileManage.field.firmwareVersion"),
    fixed: "center",
    width: 90,
    ellipsis: true,
    dataIndex: "firmwareVersion",
    key: "firmwareVersion"
  },
  // {
  //   title: i18n.t("pileManage.field.evStatus"),
  //   fixed: "center",
  //   width: 80,
  //   ellipsis: true,
  //   dataIndex: "evStatus",
  //   key: "evStatus",
  //   slots: {title: "statusTitle", customRender: "status"}
  // },
  {
    title: i18n.t("pileManage.field.readMeter"),
    fixed: "center",
    width: 80,
    ellipsis: true,
    dataIndex: "readMeter",
    key: "readMeter",
    customRender: ({text}) => {
      return text === '1' ? i18n.t('pileManage.field.readMeterList.1') : i18n.t('pileManage.field.readMeterList.0')
    }
  },
  {
    title: i18n.t("pileManage.field.lastHeartBeatTime"),
    fixed: "center",
    width: 100,
    ellipsis: true,
    dataIndex: "lastHeartbeatTime",
    key: "lastHeartbeatTime"
  },
  // {key: "byNotify", fixed: "center", width: 50, slots: {title: "isEnableSwitchTitle", customRender: "isEnableSwitch"}},
  {key: "action", fixed: "right", width: 80, ellipsis: true, slots: {title: "actionTitle", customRender: "action"}},
]

const data = reactive({
  options: [],
  searchForm: {},
  tablePagination: {
    pageSizeOptions: ["10", "20", "50", "100"],
    showSizeChanger: true,
    current: 1,
    pageSize: 10,
    total: 0,
  },
  list: [],
  selectedRowKeys: [],
})
const {options, searchForm, tablePagination, list, selectedRowKeys} = toRefs(data)

function chang_page(pagination) {
  tablePagination.value = pagination;
  get_list();
}

function get_list() {
  loading.value = true;
  const api = new PileGetListByPage();
  // 构建查询参数
  const getData = {
    ...searchForm.value,
    page: tablePagination.value.current,
    limit: tablePagination.value.pageSize,
  }
  api.get(getData).then((response) => {
        loading.value = false;
        if (0 === parseInt(response.data.code)) {
          const dataTmp = response.data.data;
          tablePagination.value.current = parseInt(dataTmp.current);
          tablePagination.value.pageSize = parseInt(dataTmp.pageSize);
          tablePagination.value.total = parseInt(dataTmp.total);
          list.value = dataTmp.data
        } else {
          list.value = [];
        }
      });
}

function updateFirmwareShow(obj) {
  if (obj.evStatus !== '1') {
    message.error(obj.identifier + " " + i18n.t("pileManage.field.evStatusList.UnAvailable"))
    return
  }
  refUpdateFirmwareModel.value?.onShow(obj);
}

function resetPile(obj, tp) {
  Modal.confirm({
    title: i18n.t("public.name.are_you_sure_operate") + "?",
    content: i18n.t("public.name.operateRemind"),
    icon: createVNode(ExclamationCircleOutlined),
    okText: i18n.t("public.name.yes"),
    okType: "danger",
    cancelText: i18n.t("public.name.no"),
    onOk: () => {
      const api = new PileReset()
      api.post({identifier: obj.identifier, type: tp}).then((response) => {
        parseInt(response.data.code) === 0 ? message.success(response.data.msg) : message.error(response.data.msg);
        // 刷新列表
        get_list();
      });
    },
  });
}

function dataTransferPile(nc){
  refDataTransfer.value?.editShow(nc)
}

function getDiagnostics(nc){
  refDiagnostics.value?.onShow(nc);
}

function add() {
  refFormModel.value?.addShow();
}

function edit(obj) {
  refFormModel.value?.editShow(obj);
}

function del(obj) {
  Modal.confirm({
    title: i18n.t("public.name.are_you_sure_delete") + "?",
    content: i18n.t("public.name.action_cannot_resumed"),
    icon: createVNode(ExclamationCircleOutlined),
    okText: i18n.t("public.name.yes"),
    okType: "danger",
    cancelText: i18n.t("public.name.no"),
    onOk: () => {
      const api = new PileDelInfo();
      let l = []
      l.push(obj.id)
      api.post({ids: l}).then((response) => {
        parseInt(response.data.code) === 0 ? message.success(response.data.msg) : message.error(response.data.msg);
        // 刷新列表
        get_list();
      });
    },
  });
}

// 触发电桩上报
function triggerMessage(identifier, trigger, connectorId) {
  const api = new PileTriggerMessage()
  api.post({identifier: identifier, trigger: trigger, connectorId: connectorId}).then((response) => {
    if (parseInt(response.data.code) === 0) {
      response.data.data.status === 'Accepted' ? message.success(response.data.data.status) : message.error(response.data.data.status);
    } else {
      message.error(response.data.msg)
    }
  });
}

function onSelectChange(ids) {
  selectedRowKeys.value = ids
}

function delByIds() {
  Modal.confirm({
    title: i18n.t("public.name.are_you_sure_delete") + "?",
    content: i18n.t("public.name.action_cannot_resumed"),
    icon: createVNode(ExclamationCircleOutlined),
    okText: i18n.t("public.name.yes"),
    okType: "danger",
    cancelText: i18n.t("public.name.no"),
    onOk: () => {
      const api = new PileDelInfo();
      api.post({ids: selectedRowKeys.value}).then((response) => {
        parseInt(response.data.code) === 0 ? message.success(response.data.msg) : message.error(response.data.msg);
        // 刷新列表
        get_list();
      });
    },
  });
}

function search_submit() {
  tablePagination.value.current = 1
  get_list()
}

function search_reset() {
  searchForm.value = {}
  get_list()
}

function showSetting(obj) {
  if (obj.evStatus !== '1') {
    message.error(obj.identifier + " " + i18n.t("pileManage.field.evStatusList.UnAvailable"))
    return
  }
  if (obj.identifier !== undefined) {
    refSettingList.value?.showList(obj.identifier);
  } else {
    message.error("The identifier is undefined")
  }
}

function getSelectOptions() {
  const api = new RentalGetTenantList();
  api.get().then((res) => {
    if (0 === parseInt(res.data.code)) {
      let l = []
      res.data.data.forEach(item => {
        l.push({value: item.reId, label: item.reId})
      })
      options.value = l
    } else {
      message.error(res.data.msg);
    }
  });
}

function filterOption(input, option) {
  return option.label.indexOf(input) >= 0;
}

function handleChange(value) {
  console.log("搜索框选择事件", value)
}

function switchClick(e, obj) {
  Modal.confirm({
    title: i18n.t("public.name.areYouSure") + "?",
    content: i18n.t("public.name.operateRemind") + "！",
    icon: createVNode(ExclamationCircleOutlined),
    okText: i18n.t("public.name.yes"),
    okType: "danger",
    cancelText: i18n.t("public.name.no"),
    onOk: () => {
      // 更新数据库及刷新列表
      obj.byNotify = e ? 1 : 0
      console.log("switchClick点击事件", obj.byNotify)

      delete obj.createTime
      delete obj.updateTime

      const api = new PileEditInfo();
      api.post(obj).then((response) => {
        if (0 === parseInt(response.data.code)) {
          // 刷新列表
          get_list();
          message.success(response.data.msg);
        } else {
          message.error(response.data.msg);
        }
      });
    },
  });
}

getSelectOptions()
get_list();

</script>

<style lang="less">
@import url("style");
</style>
