<template>
  <Modal
      id="DataTransfer"
      width="50%"
      :visible="visible"
      :confirmLoading="confirmLoading"
      @cancel="visible = !visible"
      @ok="submit"
      :title="$t('public.name.edit')"
  >
    <Row align="middle" justify="center">
      <Col span="18">
        <Row>
          <Col span="24">
            <Form
                ref="refForm"
                :model="form"
                layout="vertical"
                :rules="validateRules"
            >
              <Row justify="space-around">
                <Col span="10">
                  <FormItem :label="$t('pileManage.field.identifier')" name="identifier">
                    <Input :placeholder="$t('pileManage.validate.identifier')" v-model:value="form.identifier" disabled></Input>
                  </FormItem>
                </Col>

                <Col span="10"></Col>

                <Col span="10">
                  <FormItem :label="$t('pileManage.settingField.key')" name="messageId">
                    <Input :placeholder="$t('pileManage.settingField.required.key')" v-model:value="form.messageId"></Input>
                  </FormItem>
                </Col>

                <Col span="10">
                  <FormItem :label="$t('pileManage.settingField.value')" name="dataStr">
                    <Input :placeholder="$t('pileManage.settingField.required.value')" v-model:value="form.dataStr"></Input>
                  </FormItem>
                </Col>

                <Col span="10"></Col>

                <Col span="22">
                  <FormItem label="ResData">
                    <Textarea v-model:value="resData"></Textarea>
                  </FormItem>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  </Modal>
</template>
<script>
export default {
  name: "DataTransfer",
}
</script>
<script setup>
import {
  Modal,
  Row,
  Col,
  Form,
  FormItem,
  Input,
  Textarea,
  message,
} from "ant-design-vue";

import {PileDataTransfer} from "@/api/pile";
import {reactive, ref, toRefs} from "vue";
import {useI18n} from "vue-i18n";

const i18n = useI18n()

const refForm = ref()

let confirmLoading = ref(false)
let visible = ref(false)

let resData = ref("")

const validateRules = {
  identifier: [
    {
      required: true,
      message: i18n.t("pileManage.required.identifier"),
      trigger: "change",
    },
  ],
  messageId: [
    {
      required: true,
      message: i18n.t("pileManage.settingField.required.key"),
      trigger: "change",
    },
  ],
  dataStr: [
    {
      required: true,
      message: i18n.t("pileManage.settingField.required.value"),
      trigger: "change",
    },
  ],
}

const data = reactive({
  form: {
    identifier: undefined,
    messageId: undefined,
    dataStr: undefined,
  },
})
const {form} = toRefs(data)

async function editShow(nc) {
  _resetData();
  form.value.identifier = nc
  visible.value = true;
}

function submit() {
  refForm.value.validate().then(() => {
    confirmLoading.value = true
    let api = new PileDataTransfer();

    api.post(form.value).then(response => {
      confirmLoading.value = false
      if (0 === parseInt(response.data.code)) {
        resData.value = response.data.data
        message.success(response.data.msg);
      } else {
        message.error(response.data.msg);
      }
    });
  }).catch(() => {});
}

function _resetData() {
  resData.value = undefined
  form.value = {
    identifier: undefined,
    messageId: undefined,
    dataStr: undefined,
  };
}

// eslint-disable-next-line no-undef
defineExpose({
  editShow
})
</script>
