<template>
  <Modal
      id="FormModel"
      width="50%"
      :visible="visible"
      @cancel="cancel"
      @ok="submit"
      title="配置列表"
      :footer="null"
      :destroyOnClose="true"
  >
    <div v-if="isShowList">
      <Select
          v-model:value="searchValue"
          show-search
          placeholder="Select a person"
          style="width: 300px; margin-bottom: 10px"
          :options="options"
          :filter-option="filterOption"
          @change="handleChange"
          allowClear
      ></Select>
      <Table
          :columns="columns"
          :dataSource="listView"
          rowKey="id"
          :scroll="{ x: 800, y: 500 }"
          :loading="loading"
          :pagination=false
      >

        <!-- 插槽 -->
        <template #status="{text}">
          <div>
            <!-- 0:未注册；1:正常；2:异常 -->
            <Tag v-if="text === true" color="#747d8c">&nbsp;</Tag>
            <Tag v-else-if="text === false" color="#2ed573">&nbsp;</Tag>
            <Tag v-else color="#ff4757">&nbsp;</Tag>
          </div>
        </template>

        <!-- 插槽 -->
        <template #actionTitle>
        <span>
          {{ $t("public.table.action") }}
        </span>
        </template>
        <template #action="{text}">
          <div>
            <div class="pc-display">
              <Button type="primary" size="small" @click="isEdit(text)">Edit</Button>
            </div>
          </div>
        </template>
      </Table>
    </div>

    <div v-if="!isShowList">
      <Row>
        <Col span="24">
          <Form
              ref="refForm"
              :model="form"
              layout="vertical"
              :rules="validateRules"
          >
            <Row justify="space-around">
              <Col span="10">
                <FormItem :label="$t('pileManage.settingField.key')" name="key">
                  <Input :placeholder="$t('pileManage.settingField.required.key')"
                         v-model:value="form.key" disabled>
                  </Input>
                </FormItem>
              </Col>
              <Col span="10">
                <FormItem :label="$t('pileManage.settingField.readonly')" name="readonly">
                  <Input :placeholder="$t('pileManage.settingField.required.readonly')"
                         v-model:value="form.readonly" disabled>
                  </Input>
                </FormItem>
              </Col>
            </Row>

            <Row justify="space-around">
              <Col span="22">
                <FormItem :label="$t('pileManage.settingField.value')" name="value">
            <Textarea :placeholder="$t('pileManage.settingField.required.value')"
                      v-model:value="form.value" :disabled="form.readonly">
            </Textarea>
                </FormItem>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <Row>
        <Col span="24" style="text-align: center">
          <Button v-if="!form.readonly" @click="submit">提交</Button>
          <span v-if="!form.readonly">&nbsp;</span>
          <Button @click="this.isShowList = true">返回</Button>
        </Col>
      </Row>
    </div>
  </Modal>
</template>

<script>
import {
  Modal,
  Row,
  Col,
  Form,
  Input,
  InputNumber,
  Image,
  Radio,
  Steps,
  Step,
  Button,
  message,
  Select,
  DatePicker,
  InputGroup,
  Upload,
  Descriptions,
  DescriptionsItem,
  Space,
  Card,
  Table,
  Popconfirm,
  Tag,
  Tooltip
} from "ant-design-vue";
import {
  UploadOutlined,
  UserOutlined,
  SolutionOutlined,
  FileImageOutlined,
  CheckOutlined,
  PlusOutlined,
  MinusCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons-vue";

import { PileGetConfig, PileSetConfig } from "@/api/pile";

export default {
  name: "FormModel",
  components: {
    Modal,
    Row,
    Col,
    UserOutlined,
    SolutionOutlined,
    FileImageOutlined,
    CheckOutlined,
    UploadOutlined,
    PlusOutlined,
    MinusCircleOutlined,
    ExclamationCircleOutlined,
    Form: Form,
    FormItem: Form.Item,
    Input: Input,
    InputPassword: Input.Password,
    Textarea: Input.TextArea,
    InputGroup,
    InputNumber,
    Image,
    RadioGroup: Radio.Group,
    RadioButton: Radio.Button,
    Steps,
    Step,
    Button,
    message,
    Select,
    SelectOption: Select.Option,
    DatePicker,
    Upload,
    Descriptions,
    DescriptionsItem,
    Space,
    Card,
    Table,
    Popconfirm,
    Tag,
    Tooltip
  },
  data() {
    return {
      visible: false,
      form: {
        identifier: undefined,
        key: undefined,
        readonly: undefined,
        value: undefined,
      },
      loading: false,
      list: [],
      listView: [],
      columns: [
        {
          title: this.$t("pileManage.settingTable.index"),
          align: "center",
          width: 60,
          ellipsis: true,
          customRender: ({text, record, index, column}) => `${index + 1}`
        },
        {title: this.$t("pileManage.settingTable.key"), align: "center", width: 180, dataIndex: "key", key: "key"},
        {
          title: this.$t("pileManage.settingTable.readonly"),
          align: "center",
          width: 60,
          ellipsis: true,
          dataIndex: "readonly",
          key: "readonly",
          slots: {title: "statusTitle", customRender: "status"}
        },
        {
          title: this.$t("pileManage.settingTable.value"),
          align: "center",
          width: 80,
          ellipsis: true,
          dataIndex: "value",
          key: "value",
        },
        {key: "action", align: "center", width: 80, ellipsis: true, slots: {title: "actionTitle", customRender: "action"}},
      ],
      isShowList: true,
      identifier: undefined,
      validateRules: {
        key: [
          {
            required: false,
            message: this.$t("pileManage.settingField.required.key"),
            trigger: "change",
          },
        ],
        readonly: [
          {
            required: false,
            message: this.$t("pileManage.settingField.required.readonly"),
            trigger: "change",
          },
        ],
        value: [
          {
            required: true,
            message: this.$t("pileManage.settingField.required.value"),
            trigger: "change",
          },
        ]
      },
      searchValue: undefined,
      options: [],
    };
  },
  computed: {},
  watch: {
    "visible": {
      deep: true
    }
  },
  methods: {
    submit() {
      const api = new PileSetConfig();
      this.form.identifier = this.identifier
      api.post(this.form).then((response) => {
        if (0 === parseInt(response.data.code)) {
          message.success(response.data.msg)
          this.get_list()
        } else {
          message.error(response.data.msg)
        }
      })
    },
    cancel() {
      this.isShowList = true
      this.visible = false;
      // 调用父组件方法，刷新主列表数据
      this.$emit("updateList");
    },
    showList(identifier) {
      this.visible = true
      this.identifier = identifier
      this.get_list()
    },
    get_list() {
      this.loading = true
      this.list = []
      this.listView = []
      this.options = []

      const api = new PileGetConfig();
      api.get({identifier: this.identifier}).then((response) => {
        this.loading = false;
        this.isShowList = true
        this.searchValue = undefined
        if (0 === parseInt(response.data.code)) {
          this.list = response.data.data;
          this.listView = response.data.data;
          let l = []
          this.list.forEach(item=> {
            l.push({
              value: item.key,
              label: item.key,
            })
          })
          this.options = l
        } else {
          message.error(response.data.msg)
        }
      });
    },
    isEdit(obj) {
      this.isShowList = false
      this.form = {...obj}
    },
    handleChange(value) {
      if (value === undefined) {
        this.listView = this.list
      } else {
        let l = []
        this.list.forEach(item=> {
          if (item.key === value) {
            l.push(item)
          }
        })
        this.listView = l
      }

    },
    filterOption(input, option) {
      return option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    }
  },
};
</script>

<style lang="less">
@import url("style");
</style>
