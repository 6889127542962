import { createRouter, createWebHashHistory } from "vue-router";
import store from "@/store";
import Login from "@/views/Login";
import Home from "@/views/Home";
import PaySuccess from "@/views/PaySuccess";
import AuthorityManage from "@/views/Home/tenantSetting/authorityManage";
import PileManage from "@/views/Home/tenantSetting/pileManage/index";
import ChargerManage from "@/views/Home/tenantSetting/chargerManage/index";
import TradeManage from "@/views/Home/tenantSetting/tradeManage/index";
import EmployeeManage from "@/views/Home/tenantSetting/employeeManage";
import RoleManage from "@/views/Home/tenantSetting/roleManage";
import Dashboard from "@/views/Home/dashboard";
import RentalManage from "@/views/Home/tenantSetting/rentalManage";
import DeviceManage from "@/views/Home/tuya/deviceManage";
import ControlPanel from "@/views/Home/tuya/deviceManage/ControlPanel";
import DeviceLogs from "@/views/Home/tuya/deviceLogs";
import Device from "@/views/Home/device";
import Generalinfo from "@/views/Home/generalinfo";
import Management from "@/views/Home/management";
import Reports from "@/views/Home/reports";
import Users from "@/views/Home/users";
import Supplier from "@/views/Home/supplier";
import Structure from "@/views/Home/structure";

const routes = [
  {
    path: "/",
    name: "Login",
    component: Login,
  },
  {
    path: "/PaySuccess",
    name: "PaySuccess",
    component: PaySuccess,
  },
  {
    path: "/Home",
    name: "Home",
    component: Home,
    beforeEnter: (to, from, next) => {
      // token存在 进入该路由，不存在跳转到登陆页面
      if (store.getters.getToken) {
        //console.log(store.getters.getToken)
        next();
      } else {
        next("/Login");
      }
    },
    children: [
      {
        path: "/DeviceManage",
        name: "DeviceManage",
        component: DeviceManage,
      },
      {
        path: "/DeviceLogs",
        name: "DeviceLogs",
        component: DeviceLogs,
      },
      {
        path: "/ControlPanel",
        name: "ControlPanel",
        component: ControlPanel,
      },
      {
        path: "/Dashboard",
        name: "Dashboard",
        component: Dashboard,
      },
      {
        path: "/Dashboard2",
        name: "Dashboard2",
        component: () => import("@/views/Home/dashboard/dashboard2.vue"),
      },
      {
        path: "/Zone",
        name: "Zone",
        component: () => import("@/views/Home/zone/index.vue"),
      },
      {
        path: "/AuthorityManage",
        name: "AuthorityManage",
        component: AuthorityManage,
      },
      {
        path: "/PileManage",
        name: "PileManage",
        component: PileManage,
      },
      {
        path: "/ChargerManage",
        name: "ChargerManage",
        component: ChargerManage,
      },
      {
        path: "/RentalManage",
        name: "RentalManage",
        component: RentalManage,
      },
      {
        path: "/TradeManage",
        name: "TradeManage",
        component: TradeManage,
      },
      {
        path: "/EmployeeManage",
        name: "EmployeeManage",
        component: EmployeeManage,
      },
      {
        path: "/RoleManage",
        name: "RoleManage",
        component: RoleManage,
      },
      { path: "/Device", name: "Device", component: Device },
      { path: "/Generalinfo", name: "Generalinfo", component: Generalinfo },
      { path: "/Management", name: "Management", component: Management },
      { path: "/Reports", name: "Reports", component: Reports },
      { path: "/User", name: "User", component: Users },
      { path: "/Supplier", name: "Supplier", component: Supplier },
      { path: "/Structure", name: "Structure", component: Structure },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes: routes,
});

export default router;
