import { defineComponent, ref, computed,onMounted } from "vue"
import { Upload, message } from "ant-design-vue";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons-vue";
import { Uploads as uploadApi } from "@/api/Uploads";

import i18n from "@/i18n"

export default defineComponent({
  name: "UploadImage",
  props: {
    url: {
      type: String,
      default: ""
    }
  },
  emits: ['new_image_url'],
  setup(props, ctx) {
    const imageUrl = ref("")
    const _url = ref(props.url)
    const uploading = ref(false)
    const show = computed(() => imageUrl.value === "")

    // upload handle
    const _upload_handle = (obj) => {
      uploading.value = true;
      let form = new FormData();
      let api = new uploadApi();
      form.append("file", obj.file);
      api.upload(form).then((response) => {
        if (parseInt(response.data.code) === 0) {
          ctx.emit('new_image_url', response.data.data.objName);
          _get_img(response.data.data.url);
          message.success(response.data.msg);
        } else {
          message.warning(response.data.msg);
          uploading.value = false;
        }
      });
    }
    // get upload img by url
    const _get_img = async (url) => {
      if (url === "") return false;
      _url.value = url;
      uploading.value = true;
      let api = new uploadApi();
      await api.show(url).then((response) => {
        uploading.value = false;
        imageUrl.value = "data:image/png;base64," + btoa(new Uint8Array(response.data).reduce((data, byte) => data + String.fromCharCode(byte), ""));
      });
    }
    const change_url = (url) => {
      imageUrl.value = url
    }
    onMounted(() => {
      _get_img(_url.value)
    })
    ctx.expose({ change_url })
    return () => (
      <Upload class="photo-single-uploader" show-upload-list={false} customRequest={_upload_handle}>
        {
          show.value === false ?
            (<img src={imageUrl.value} alt={i18n.global.t('public.name.empty')}/>) :
            (
              uploading.value === true ?
                <LoadingOutlined style="{fontSize:'25px'}"/> :
                <div>
                  <PlusOutlined style="{fontSize:'25px'}"/>
                  <div class="ant-upload-text">Upload</div>
                </div>
            )
        }
      </Upload>
    )
  }
})
