<template>
  <Row id="UsersIndex" :gutter="[8, 8]">
    <Col :span="3">
      <div style="width: 100%; height: calc(100% - 23px); background-color: white; border-radius: 20px; padding: 10px; margin-top: 23px">
        <Row>
          <Col :span="24" style="padding-top: 50px">
            <Form :layout="'vertical'">
              <FormItem>
                <template #label>
                  <Space>
                    <img :src="SVGICONCostomer" style="width: 20px" />
                    <span style="color: #44659a">{{ t('dashboard2.name.customer') }}</span>
                  </Space>
                </template>
                <Select value="">
                  <SelectOption value="">Company A</SelectOption>
                </Select>
              </FormItem>
              <FormItem>
                <template #label>
                  <Space>
                    <img :src="SVGICONSite" style="width: 20px" />
                    <span style="color: #44659a">{{ t('dashboard2.name.site') }}</span>
                  </Space>
                </template>
                <Select value="">
                  <SelectOption value="">All</SelectOption>
                </Select>
              </FormItem>
              <FormItem>
                <template #label>
                  <Space>
                    <img :src="SVGICONFloor" style="width: 20px" />
                    <span style="color: #44659a">{{ t('dashboard2.name.floor') }}</span>
                  </Space>
                </template>
                <Select value="">
                  <SelectOption value="">All</SelectOption>
                </Select>
              </FormItem>
              <FormItem>
                <template #label>
                  <Space>
                    <img :src="SVGICONZoneGroup" style="width: 20px" />
                    <span style="color: #44659a">{{ t('dashboard2.name.zone') }}</span>
                  </Space>
                </template>
                <Select value="">
                  <SelectOption value="">All</SelectOption>
                </Select>
              </FormItem>
              <FormItem>
                <template #label>
                  <Space>
                    <img :src="SVGICONDevices" style="width: 20px" />
                    <span style="color: #44659a">{{ t('dashboard2.name.devices') }}</span>
                  </Space>
                </template>
                <Select value="">
                  <SelectOption value="">All</SelectOption>
                </Select>
              </FormItem>
              <FormItem>
                <Button :type="'text'" style="color: #44659a">{{ t('dashboard2.name.compare') }}</Button>
              </FormItem>
            </Form>
          </Col>
        </Row>
      </div>
    </Col>
    <Col :span="21">
      <Row>
        <!-- 标签-->
        <Col :span="24" class="title">
          <TypographyTitle>{{ t('userManagement.name.title') }}</TypographyTitle>
        </Col>
        <Col :span="24" style="display: flex">
          <InfoBox v-for="(item, index) in infoList" :title="item.title" :right-color="item.rightColor" :num="item.num" :key="index"></InfoBox>
        </Col>
        <Col :span="24" class="content">
          <Col span="24" style="margin-bottom: 10px">
            <Row>
              <Col span="12">{{ t('userManagement.name.title') }}</Col>
              <Col span="12" :align="'right'">
                <Space>
                  <span>{{ t('dashboard2.name.readtime') }}</span>
                  <Select style="width: 100px; margin: 0 10px" value="">
                    <SelectOption value="">12 hours</SelectOption>
                  </Select>
                  <PlusCircleOutlined />
                </Space>
              </Col>
            </Row>
          </Col>
          <!-- 列表-->
          <Row>
            <Col :span="24">
              <Table :columns="columns" :dataSource="list" rowKey="id" :loading="loading" :scroll="{ x: 1500, y: 'calc(100vh - 300px)' }" :pagination="tablePagination" @change="chang_page">
                <template #headerCell="{ column }">
                  <template v-if="column.dataIndex === 'action'">
                    <span>
                      {{ $t("public.table.action") }}
                    </span>
                  </template>
                </template>
                <template #bodyCell="{ column, record }">
                  <template v-if="column.dataIndex === 'id'">
                    <a @click="clickId(record.id, record.category)">
                      {{ record.id }}
                    </a>
                  </template>
                  <template v-if="column.dataIndex === 'isOnline'">
                    <div>
                      <Tag v-if="record.isOnline" color="#87d068">{{ record.isOnline }}</Tag>
                      <Tag v-else color="#f50">{{ record.isOnline }}</Tag>
                    </div>
                  </template>
                  <template v-if="column.dataIndex === 'action'">
                    <div class="pc-display">
                      <a href="javascript: void(0)" style="margin-right: 10px"><BarChartOutlined /></a>
                      <a href="javascript: void(0)" style="margin-right: 10px"><EditOutlined /></a>
                      <a href="javascript: void(0)"><EnvironmentOutlined /></a>
                      <!-- <Button type="link" size="small" @click="deleteUser(record)">Delete</Button> -->
                    </div>
                  </template>
                </template>
              </Table>
              <div v-if="showLoadingBut" style="width: 100%; text-align: center; margin-top: 5px">
                <Button @click="onClickLoading">loading({{ tablePagination.pageSize }})...</Button>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  </Row>
</template>
<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "users",
};
</script>
<script setup>
import { createVNode, ref } from "vue";
import InfoBox from "./InfoBox.vue";
import { Button, Col, TypographyTitle, Row, Select, Table, Tag, SelectOption, Space, Form, FormItem } from "ant-design-vue";
import { useI18n } from "vue-i18n";

import { EditOutlined, BarChartOutlined, EnvironmentOutlined, PlusCircleOutlined } from "@ant-design/icons-vue";

import { useRouter } from "vue-router";

const i18n = useI18n();

const {t} = useI18n();

const router = useRouter();

let showLoadingBut = ref(false);

let categorieList = ref([]);

let tablePagination = ref({
  pageSizeOptions: ["10", "20", "50", "100"],
  showSizeChanger: true,
  current: 1,
  pageSize: 20,
  total: 0,
  lastRowKey: undefined,
});
let loading = ref(false);
let list = ref([]);

let infoList = ref([]);

const columns = [
  { title: i18n.t("userManagement.table.name"), width: 180, ellipsis: true, dataIndex: "Name", key: "Name" },

  { title: i18n.t("userManagement.table.email"), width: 180, ellipsis: true, dataIndex: "EmailID", key: "EmailID" },
  {
    title: i18n.t("userManagement.table.role"),
    width: 180,
    ellipsis: true,
    dataIndex: "Type",
    key: "Type",
  },
  {
    title: i18n.t("userManagement.table.structureLevel"),
    width: 180,
    ellipsis: true,
    dataIndex: "Customer",
    key: "Customer",
  },
  { dataIndex: "action", key: "action", fixed: "right", width: 145, ellipsis: true },
];

function chang_page(pagination) {
  tablePagination.value = pagination;
  get_list();
}

function showDetail(info) {
  console.log(info);
}

function deleteUser(info) {
  // console.log(info);
  list.value.forEach((item, index) => {
    if (item.Name == info.Name) {
      // console.log(item);
      list.value.splice(index, 1);
    }
  });
  get_count();
}

function get_count() {
  let superadmin = 0;
  let siteadmin = 0;
  let manager = 0;
  infoList.value = [];
  list.value.forEach((item) => {
    switch (item.Userid) {
      case "1":
        superadmin++;
        break;
      case "2":
        siteadmin++;
        break;
      case "3":
        manager++;
        break;
    }
  });
  infoList.value.push({ title: t('userManagement.name.superAdmin'), num: superadmin, rightColor: "#23378d" });
  infoList.value.push({ title: t('userManagement.name.siteAdmin'), num: siteadmin, rightColor: "#1375bc" });
  infoList.value.push({ title: t('userManagement.name.manager'), num: manager, rightColor: "#20a5dd" });
  infoList.value.push({ title: t('userManagement.name.user'), num: list.value.length, rightColor: "#70cbe0" });
  console.log(infoList.value);
  tablePagination.value.total = list.value.length;
}

function get_list(lastRowKey) {
  loading.value = true;

  list.value = [
    {
      Name: "User001",
      Userid: "1",
      EmailID: "user001@email.com",
      Type: "Super Admin",
      Customer: "CLPe --> SiteA --> 10/F --> Zone A",
    },
    {
      Name: "User002",
      Userid: "1",
      EmailID: "user002@email.com",
      Type: "Super Admin",
      Customer: "CLPe --> SiteA --> 11/F --> Zone A",
    },
    {
      Name: "User003",
      Userid: "2",
      EmailID: "user003@email.com",
      Type: "Site Admin",
      Customer: "CLPe --> SiteA --> 12/F --> Zone B",
    },
    {
      Name: "User004",
      Userid: "1",
      EmailID: "user004@email.com",
      Type: "Super Admin",
      Customer: "CLPe --> SiteA --> 11/F --> Zone B",
    },
    {
      Name: "User005",
      Userid: "2",
      EmailID: "user005@email.com",
      Type: "Site Admin",
      Customer: "CLPe --> SiteA --> 10/F --> Zone C",
    },
    {
      Name: "User006",
      Userid: "3",
      EmailID: "user006@email.com",
      Type: "Manager",
      Customer: "CLPe --> SiteA --> 13/F --> Zone A",
    },
    {
      Name: "User007",
      Userid: "3",
      EmailID: "user007@email.com",
      Type: "Manager",
      Customer: "CLPe --> SiteA --> 14/F --> Zone A",
    },
    {
      Name: "User008",
      Userid: "2",
      EmailID: "user008@email.com",
      Type: "Site Admin",
      Customer: "CLPe --> SiteA --> 15/F --> Zone A",
    },
    {
      Name: "User009",
      Userid: "3",
      EmailID: "user009@email.com",
      Type: "Manager",
      Customer: "CLPe --> SiteA --> 13/F --> Zone A",
    },
    {
      Name: "User010",
      Userid: "2",
      EmailID: "user010@email.com",
      Type: "Site Admin",
      Customer: "CLPe --> SiteA --> 11/F --> Zone B",
    },
    {
      Name: "User011",
      Userid: "3",
      EmailID: "user011@email.com",
      Type: "Manager",
      Customer: "CLPe --> SiteA --> 8/F --> Zone A",
    },
  ];
  console.log(list.value);
  get_count();
  loading.value = false;
}

function clickId(id, category) {
  router.push({
    path: "/DeviceLogs",
    query: {
      id: id,
      category: category,
    },
  });
}

// 加载更多数据
function onClickLoading() {
  get_list(tablePagination.value.lastRowKey);
}

function filterOption(input, option) {
  return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
}

get_list(undefined);
</script>

<style lang="less">
#UsersIndex {
  .content {
    border-radius: 20px;
    padding: 20px;
  }
  .colored-section {
    position: relative;
    width: 250px;
    height: 100px;
    background-color: white;
    border-radius: 10px;
    margin: 0 10px;
    margin-bottom: 20px;

    padding: 0 20px;
    .num {
      font-size: 50px;
      text-align: right;
    }
    .title {
      position: absolute;
      bottom: 10px;
      left: 40px;
    }
  }

  .colored-section::before {
    content: "";
    position: absolute;
    left: 0;
    width: 20px;
    height: 100%;
    background-color: var(--right-color);
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
}
</style>
