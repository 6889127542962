import {Base} from "@/api/Base"

const api = "authority"

export class AuthorityAdd extends Base {
    path = api + "/add"
}

export class AuthorityDelete extends Base {
    path = api + "/delete"
}

export class AuthorityList extends Base {
    path = api + "/list"
}

export class AuthorityEdit extends Base {
    path = api + "/edit"
}

export class AuthorityListAll extends Base {
    path = api + "/listAll"
}
