<template>
  <div class="Statistic">
    <Flex>
      <div :class="props.isShine ? 'left-color left-color-shine' : 'left-color'" :style="{ backgroundColor: props.backgroundColor }"></div>
      <div class="right-content">
        <Flex :vertical="true" :justify="'space-between'" style="height: 100%; color: #626365">
          <Row>
            <Col :span="4"><renader_icon :style="{ color: props.backgroundColor, fontSize: '24px' }" /></Col>
            <Col :span="20" style="text-align: right; font-size: 36px">{{ props.num }}</Col>
          </Row>
          <TypographyParagraph class="content-title" :ellipsis="{ expandable: false, rows: 2, tooltip: props.title }" :content="props.title"></TypographyParagraph>
        </Flex>
      </div>
    </Flex>
  </div>
</template>

<script setup>
import { h } from "vue";
import { Row, Col, Flex, TypographyParagraph } from "ant-design-vue";
import { BarChartOutlined, PieChartOutlined, LineChartOutlined, DashboardOutlined, StarOutlined, IdcardOutlined, BellOutlined,ShakeOutlined } from "@ant-design/icons-vue";

const props = defineProps({
  backgroundColor: {
    type: String,
    default: "red",
  },
  icon: {
    type: String,
    default: "",
  },
  num: {
    type: Number,
    default: 0,
  },
  title: {
    type: String,
    default: "",
  },
  isShine: {
    default: false,
  },
});

const renader_icon = () => {
  let iconComponent = null;
  switch (props.icon) {
    case "bell":
      iconComponent = BellOutlined;
      break;
    case "idcard":
      iconComponent = IdcardOutlined;
      break;
    case "star":
      iconComponent = StarOutlined;
      break;
    case "dashboard":
      iconComponent = DashboardOutlined;
      break;
    case "pie_chart":
      iconComponent = PieChartOutlined;
      break;
    case "line_chart":
      iconComponent = LineChartOutlined;
      break;
    case "bar_chart":
      iconComponent = BarChartOutlined;
      break;
      case "shake":
      iconComponent = ShakeOutlined;
      break;
    default:
      iconComponent = BarChartOutlined;
      break;
  }

  return h(iconComponent);
};
</script>

<style lang="less">
.Statistic {
  --thisWidth: 230px;
  --thisHeight: 120px;
  --thisLeftWidth: 14px;
  --thisRightWidth: calc(var(--thisWidth) - var(--thisLeftWidth));
  width: var(--thisWidth);
  height: var(--thisHeight);
  background-color: white;
  border-radius: 20px;
  .left-color {
    height: 120px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    width: var(--thisLeftWidth);
  }
  @keyframes blink {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .left-color-shine {
    animation: blink 1s infinite;
  }
  .right-content {
    width: var(--thisRightWidth);
    height: var(--thisHeight);
    padding: 15px;
    .content-title {
      margin-bottom: 0;
      width: 50%;
      font-size: 18px;
      line-height: 18px;
      color: #767676;
    }
  }
}
</style>
