import {Base} from "@/api/Base"

const api = "tuya/weather"

// 获取全部设备列表
export class GetCurrentWeather extends Base {
    path = api + "/getCurrentWeatherByOpenWeatherMap"
}


