const zhTW = {
  public: {
    name: {
      index: "序號",
      ID: "ID",
      status: "狀態",
      login: "登錄",
      logout: "登出",
      hello: "你好",
      username: "用戶名",
      email: "E-mail",
      password: "密碼",
      confirm_password: "確認密碼",
      reset_password: "重置密碼",
      captcha: "驗證碼",
      more: "更多",
      add: "添加",
      edit: "編輯",
      del: "刪除",
      setting: "設置",
      dels: "批量刪除",
      detail: "詳情",
      yes: "是",
      no: "否",
      empty: "空",
      are_you_sure_delete: "你確定需要刪除該信息嗎",
      action_cannot_resumed: "該操作將無法恢復",
      are_you_sure_operate: "是否確定當前操作",
      search: "搜索",
      reset: "重置",
      spread: "展開",
      retract: "收回",
      show: "展示",
      no_page: "無頁麵",
      service: "服務商",
      phone_country_code: "電話國家代碼",
      name: "名稱",
      phone: "電話",
      sex: "性別",
      country: "國家",
      address: "地址",
      birthday: "出生年月日",
      remark: "備註",
      personal: "個人信息",
      enclosure: "附件",
      logo: "Logo",
      submit: "提交",
      areYouSure: "是否確認",
      operateRemind: "當前操作敏感,請慎重",
      export: "導出",
      save: "保存",
      cancel: "取消",
      batchDelete: "批量刪除",
    },
    language: {
      zhTW: "中文（繁體）",
      enUS: "英文",
      zhCN: "中文（簡體）",
    },
    status: {
      0: "未知",
      1: "正常",
      2: "禁用",
      3: "已刪除",
    },
    sex: {
      1: "男",
      2: "女",
      3: "未知",
    },
    placeholder: {
      please_select: "請選擇",
    },
    table: {
      name: "名稱",
      status: "狀態",
      action: "操作",
      content: "內容",
      created_at: "創建時間",
      image: "圖像",
      email: "E-mail",
      order_id: "排序",
      sex: "性別",
      last_ip: "最後登錄IP",
      phone: "電話",
    },
    field: {
      name: "名稱",
      username: "用戶名",
      content: "內容",
      phone_country_code: "電話國家代碼",
      phone: "電話",
      email: "E-mail",
      order_id: "排序",
      portrait: "頭像",
      status: "狀態",
      image: "圖像",
      sex: "性別",
    },
    validate: {
      required: {
        id: "缺少ID",
        username: "請輸入用戶名",
        password: "請輸入密碼",
        repassword: "請重復密碼",
        name: "請輸入名稱",
        phone: "請輸入電話",
        email: "請輸入E-mail",
        portrait: "請上傳頭像",
        phone_country_code: "請選擇電話國家代碼",
      },
      tips: {
        inconsistent_password: "密碼不一緻",
        length_should: "長度應爲{min}至{max}",
      },
    },
  },
  language: {
    zhTW: "中文（繁體）",
    enUS: "英語",
    zhCN: "中文（簡體）",
  },
  nav: {
    name: {
      Dashboard: "EV儀錶盤",
      IotDashboard: "物聯網儀錶盤",
      UserManagement: "用戶管理",
      AuthorityManage: "權限管理",
      TenantManage: "租戶管理",
      BalanceLogManage: "賬變記錄管理",
      CarManage: "車輛管理",
      CarImagesManage: "車圖管理",
      ChargeStationImagesManage: "充電樁圖片管理",
      ChargingStationManage: "充電樁管理",
      ConnectorManage: "連接端管理",
      EmployeeManage: "用戶管理",
      EventLogManage: "事件記錄管理",
      MeterManage: "電錶管理",
      OrderManage: "訂單管理",
      PlanManage: "充電計劃管理",
      RegionManage: "區域管理",
      RegionImagesManage: "停車場圖片管理",
      RepairLogManage: "維修記錄管理",
      RoleManage: "角色管理",
      TransactionLogManage: "事務記錄管理",
      UserManage: "用戶管理",
      UserPlanManage: "用戶計劃管理",
      GetConfiguration: "獲取電樁配置信息",
      DataTransfer: "設定電樁參數",
      TenantManagement: "租戶管理",
      ChargerSetting: "充電樁管理",
      AppManagement: "App數據管理",
      LogManagement: "日誌管理",
      AppVersionManage: "App版本管理",
      RentalManage: "客戶管理",
      batchDelete: "批量刪除",
      PileManage: "充電樁管理",
      ChargerManage: "電槍管理",
      TradeManage: "充電記錄管理",
      Zone: "區域",
      TuyaManagement: "物聯網",
      DeviceManage: "設備管理",
      DeviceLogs: "設備日誌",
      Supplier: "供應商API",
      Report: "報告",
      ProfileManagement: "資料管理",
      Structure: "結構",
    },
    table: {
      order_id: "排序",
      url: "Url",
    },
    field: {
      pid: "PID",
      order_id: "排序",
      name: "名稱",
      url: "Url",
      status: "狀態",
      icon: "圖標",
    },
  },
  login: {
    validate: {
      required: {
        username: "請輸入用戶名",
        email: "請輸入郵箱",
        password: "輸輸入密碼",
        captcha: "請輸入驗證碼",
      },
    },
  },
  authority: {
    name: {
      title: "權限",
      detailTitle: "權限詳情",
      editTitle: "編輯權限",
    },
    table: {
      id_aut: "ID",
      a_id_aut: "父權限",
      name: "權限",
      resource_name: "權限標識",
      type: "權限類型",
      sort: "排序",
    },
    field: {
      id_aut: "ID",
      a_id_aut: "父權限",
      name: "權限",
      resource_name: "權限標識",
      type: "權限類型",
      sort: "排序",
    },
    validate: {
      required: {
        id_aut: "請填寫ID",
        a_id_aut: "請填寫父權限",
        name: "請填寫權限",
        resource_name: "請填寫權限標識",
        type: "請填寫權限類型",
        sort: "請填寫排序",
      },
    },
  },
  balanceLog: {
    name: {
      title: "賬變記錄",
      detailTitle: "賬變記錄詳情",
      editTitle: "編輯賬變記錄",
    },
    table: {
      id_bal_log: "ID",
      id_use: "用戶",
      type: "類型",
      amount: "金額",
      log_time: "時間",
      tenant_key: "租戶識別碼",
      status: "狀態",
      payment_method: "支付方式",
    },
    field: {
      id_bal_log: "ID",
      id_use: "用戶",
      type: "類型",
      amount: "金額",
      log_time: "時間",
      tenant_key: "租戶識別碼",
      status: "狀態",
      payment_method: "支付方式",
    },
    validate: {
      required: {
        id_bal_log: "請填寫ID",
        id_use: "請填寫用戶",
        type: "請填寫類型",
        amount: "請填寫金額",
        log_time: "請填寫時間",
        tenant_key: "請填寫租戶識別碼",
        status: "請填寫狀態",
        payment_method: "請填寫支付方式",
      },
    },
  },
  car: {
    name: {
      title: "車輛",
      detailTitle: "車輛詳情",
      editTitle: "編輯車輛",
    },
    table: {
      id_car: "ID",
      id_use: "用戶",
      model: "型號",
      car_license: "牌照",
      max_years_old: "最大年份",
      manufacture_year: "製造年份",
      maximum_power: "最大容量",
      is_second_hand: "是否二手",
      vehicle_inspection_date: "檢修日期",
      insurance_expiry_date: "保險到期日",
      tenant_key: "租戶識別碼",
    },
    field: {
      id_car: "ID",
      id_use: "用戶",
      model: "型號",
      car_license: "牌照",
      max_years_old: "最大年份",
      manufacture_year: "製造年份",
      maximum_power: "最大容量",
      is_second_hand: "是否二手",
      vehicle_inspection_date: "檢修日期",
      insurance_expiry_date: "保險到期日",
      tenant_key: "租戶識別碼",
    },
    validate: {
      required: {
        id_car: "請填寫ID",
        id_use: "請填寫用戶",
        model: "請填寫型號",
        car_license: "請填寫牌照",
        max_years_old: "請填寫最大年份",
        manufacture_year: "請填寫製造年份",
        maximum_power: "請填寫最大容量",
        is_second_hand: "請填寫是否二手",
        vehicle_inspection_date: "請填寫檢修日期",
        insurance_expiry_date: "請填寫保險到期日",
        tenant_key: "請填寫租戶識別碼",
      },
    },
  },
  carImages: {
    name: {
      title: "車圖",
      detailTitle: "車圖詳情",
      editTitle: "編輯車圖",
    },
    table: {
      id_car_ima: "ID",
      id_car: "車輛",
      url: "鏈接",
      upload_time: "上傳時間",
      tenant_key: "租戶識別碼",
    },
    field: {
      id_car_ima: "ID",
      id_car: "車輛",
      url: "鏈接",
      upload_time: "上傳時間",
      tenant_key: "租戶識別碼",
    },
    validate: {
      required: {
        id_car_ima: "請填寫ID",
        id_car: "請填寫車輛",
        url: "請填寫鏈接",
        upload_time: "請填寫上傳時間",
        tenant_key: "請填寫租戶識別碼",
      },
    },
  },
  chargeStationImages: {
    name: {
      title: "充電樁圖片",
      detailTitle: "充電樁圖片詳情",
      editTitle: "編輯充電樁圖片",
    },
    table: {
      id_ima: "ID",
      id_cha_poi: "所屬電樁",
      url: "鏈接",
      upload_time: "上傳時間",
      tenant_key: "租戶識別碼",
    },
    field: {
      id_ima: "ID",
      id_cha_poi: "所屬電樁",
      url: "鏈接",
      upload_time: "上傳時間",
      tenant_key: "租戶識別碼",
    },
    validate: {
      required: {
        id_ima: "請填寫ID",
        id_cha_poi: "請填寫所屬電樁",
        url: "請填寫鏈接",
        upload_time: "請填寫上傳時間",
        tenant_key: "請填寫租戶識別碼",
      },
    },
  },
  chargingStation: {
    name: {
      title: "充電樁",
      detailTitle: "充電樁詳情",
      editTitle: "編輯充電樁",
      quickAdd: "添加充電樁",
    },
    table: {
      id_cha_poi: "ID",
      id_met: "所屬電錶",
      charge_box_id: "電樁ID",
      last_heartbeat_time: "上次心跳時間",
      monthly_fee: "係統接入月費",
      charger_type: "充電樁類型",
      tenant_key: "租戶識別碼",
      name: "名稱",
      heartbeat_interval: "心跳間隔",
      enable: "是否可用",
    },
    field: {
      id_cha_poi: "ID",
      id_met: "所屬電錶",
      charge_box_id: "電樁ID",
      last_heartbeat_time: "上次心跳時間",
      monthly_fee: "係統接入月費",
      charger_type: "充電樁類型",
      tenant_key: "租戶識別碼",
      name: "名稱",
      heartbeat_interval: "心跳間隔",
      enable: "是否可用",
    },
    validate: {
      required: {
        id_cha_poi: "請填寫ID",
        id_met: "請填寫所屬電錶",
        charge_box_id: "請填寫電樁ID",
        last_heartbeat_time: "請填寫上次心跳時間",
        monthly_fee: "請填寫係統接入月費",
        charger_type: "請填寫充電樁類型",
        tenant_key: "請填寫租戶識別碼",
        name: "請填寫名稱",
        heartbeat_interval: "請填寫心跳間隔",
        enable: "請填寫是否可用",
      },
    },
  },
  connector: {
    name: {
      title: "連接端",
      detailTitle: "連接端詳情",
      editTitle: "編輯連接端",
      startTransaction: "開始充電",
      stopTransaction: "停止充電",
    },
    table: {
      id_con: "ID",
      id_cha_poi: "所屬電樁",
      name: "名稱",
      connector_id: "端口ID",
      status: "連接端狀態",
      tenant_key: "租戶識別碼",
      power: "電量",
      type: "類型",
    },
    field: {
      id_con: "ID",
      id_cha_poi: "所屬電樁",
      name: "名稱",
      connector_id: "端口ID",
      status: "連接端狀態",
      tenant_key: "租戶識別碼",
      power: "電量",
      type: "類型",
    },
    validate: {
      required: {
        id_con: "請填寫ID",
        id_cha_poi: "請填寫所屬電樁",
        name: "請填寫名稱",
        connector_id: "請填寫端口ID",
        status: "請填寫連接端狀態",
        tenant_key: "請填寫租戶識別碼",
        power: "請填寫電量",
        type: "請填寫類型",
      },
    },
  },
  employee: {
    name: {
      title: "用戶",
      detailTitle: "用戶詳情",
      editTitle: "編輯用戶",
    },
    table: {
      id_emp: "ID",
      id_rol: "角色",
      account: "賬號",
      password: "密碼",
      salt: "鹽",
      last_login_time: "上次登錄時間",
      register_time: "創建時間",
      login_failure_counter: "登錄錯誤計數",
      email: "郵箱",
      remark: "備註",
      status: "狀態",
      name: "姓名",
      commission_rate: "電價分成比例",
      yedpay_api_key: "Yedpay API密鑰",
      yedpay_sign_key: "Yedpay 簽名密鑰",
      tenant_key: "租戶識別碼",
    },
    field: {
      id_emp: "ID",
      id_rol: "角色",
      account: "賬號",
      password: "密碼",
      salt: "鹽",
      last_login_time: "上次登錄時間",
      register_time: "創建時間",
      login_failure_counter: "登錄錯誤計數",
      email: "郵箱",
      remark: "備註",
      status: "狀態",
      name: "姓名",
      commission_rate: "電價分成比例",
      yedpay_api_key: "Yedpay API密鑰",
      yedpay_sign_key: "Yedpay 簽名密鑰",
      tenant_key: "租戶識別碼",
    },
    validate: {
      required: {
        id_emp: "請填寫ID",
        id_rol: "請填寫角色",
        account: "請填寫賬號",
        password: "請填寫密碼",
        salt: "請填寫鹽",
        last_login_time: "請填寫上次登錄時間",
        register_time: "請填寫創建時間",
        login_failure_counter: "請填寫登錄錯誤計數",
        email: "請填寫郵箱",
        remark: "請填寫備註",
        status: "請填寫狀態",
        name: "請填寫姓名",
        commission_rate: "請填寫電價分成比例",
        yedpay_api_key: "請填寫Yedpay API密鑰",
        yedpay_sign_key: "請填寫Yedpay 簽名密鑰",
        tenant_key: "請填寫租戶識別碼",
      },
    },
  },
  eventLog: {
    name: {
      title: "事件記錄",
      detailTitle: "事件記錄詳情",
      editTitle: "編輯事件記錄",
    },
    table: {
      id_env_log: "ID",
      event_time: "事件時間",
      log: "日誌",
      tenant_key: "租戶識別碼",
    },
    field: {
      id_env_log: "ID",
      event_time: "事件時間",
      log: "日誌",
      tenant_key: "租戶識別碼",
    },
    validate: {
      required: {
        id_env_log: "請填寫ID",
        event_time: "請填寫事件時間",
        log: "請填寫日誌",
        tenant_key: "請填寫租戶識別碼",
      },
    },
  },
  meter: {
    name: {
      title: "電錶",
      detailTitle: "電錶詳情",
      editTitle: "編輯電錶",
    },
    table: {
      id_met: "ID",
      id_reg: "所屬區域",
      name: "電錶名稱",
      current_threshold: "電流閾值",
      tenant_key: "租戶識別碼",
    },
    field: {
      id_met: "ID",
      id_reg: "所屬區域",
      name: "電錶名稱",
      current_threshold: "電流閾值",
      tenant_key: "租戶識別碼",
    },
    validate: {
      required: {
        id_met: "請填寫ID",
        id_reg: "請填寫所屬區域",
        name: "請填寫電錶名稱",
        current_threshold: "請填寫電流閾值",
        tenant_key: "請填寫租戶識別碼",
      },
    },
  },
  order: {
    name: {
      title: "訂單",
      detailTitle: "訂單詳情",
      editTitle: "編輯訂單",
    },
    table: {
      id_ord: "ID",
      id_car: "車輛",
      id_con: "所屬連接端",
      order_time: "訂單日期",
      type: "訂單類型",
      amount: "金額",
      charge_start_time: "開始充電時間",
      charge_end_time: "結束充電時間",
      status: "狀態",
      payment_time: "支付時間",
      electricity: "充電量",
      ref_id_pla: "關聯計劃",
      price: "下單時電價",
      commission_rate: "下單時的分成比例",
      tenant_key: "租戶識別碼",
      id_tra_log: "充電關聯記錄",
      charge_minutes: "充電時長",
      pay_type: "支付類型",
      payment_method: "支付方式",
    },
    field: {
      id_ord: "ID",
      id_car: "車輛",
      id_con: "所屬連接端",
      order_time: "訂單日期",
      type: "訂單類型",
      amount: "金額",
      charge_start_time: "開始充電時間",
      charge_end_time: "結束充電時間",
      status: "狀態",
      payment_time: "支付時間",
      electricity: "充電量",
      ref_id_pla: "關聯計劃",
      price: "下單時電價",
      commission_rate: "下單時的分成比例",
      tenant_key: "租戶識別碼",
      id_tra_log: "充電關聯記錄",
      charge_minutes: "充電時長",
      pay_type: "支付類型",
      payment_method: "支付方式",
    },
    validate: {
      required: {
        id_ord: "請填寫ID",
        id_car: "請填寫車輛",
        id_con: "請填寫所屬連接端",
        order_time: "請填寫訂單日期",
        type: "請填寫訂單類型",
        amount: "請填寫金額",
        charge_start_time: "請填寫開始充電時間",
        charge_end_time: "請填寫結束充電時間",
        status: "請填寫狀態",
        payment_time: "請填寫支付時間",
        electricity: "請填寫充電量",
        ref_id_pla: "請填寫關聯計劃",
        price: "請填寫下單時電價",
        commission_rate: "請填寫下單時的分成比例",
        tenant_key: "請填寫租戶識別碼",
        id_tra_log: "請填寫充電關聯記錄",
        charge_minutes: "請填寫充電時長",
        pay_type: "請填寫支付類型",
        payment_method: "請填寫支付方式",
      },
    },
  },
  plan: {
    name: {
      title: "充電計劃",
      detailTitle: "充電計劃詳情",
      editTitle: "編輯充電計劃",
    },
    table: {
      id_pla: "ID",
      name: "計劃名稱",
      price_per_month: "價格",
      contract_period: "合同期限",
      free_charging_time_limit: "免費充電時間",
      overtime_charge_price: "超時價格",
      tenant_key: "租戶識別碼",
    },
    field: {
      id_pla: "ID",
      name: "計劃名稱",
      price_per_month: "價格",
      contract_period: "合同期限",
      free_charging_time_limit: "免費充電時間",
      overtime_charge_price: "超時價格",
      tenant_key: "租戶識別碼",
    },
    validate: {
      required: {
        id_pla: "請填寫ID",
        name: "請填寫計劃名稱",
        price_per_month: "請填寫價格",
        contract_period: "請填寫合同期限",
        free_charging_time_limit: "請填寫免費充電時間",
        overtime_charge_price: "請填寫超時價格",
        tenant_key: "請填寫租戶識別碼",
      },
    },
  },
  region: {
    name: {
      title: "區域",
      detailTitle: "區域詳情",
      editTitle: "編輯區域",
    },
    table: {
      id_reg: "ID",
      name: "區域名稱",
      ac_price_per_electricity: "直流按電量價格",
      ac_price_per_minute: "直流按分鐘價格",
      lon: "經度",
      lat: "緯度",
      address: "地址",
      dc_price_per_electricity: "交流按電量價格",
      dc_price_per_minute: "交流按分鐘價格",
      tenant_key: "租戶識別碼",
    },
    field: {
      id_reg: "ID",
      name: "區域名稱",
      ac_price_per_electricity: "直流按電量價格",
      ac_price_per_minute: "直流按分鐘價格",
      lon: "經度",
      lat: "緯度",
      address: "地址",
      dc_price_per_electricity: "交流按電量價格",
      dc_price_per_minute: "交流按分鐘價格",
      tenant_key: "租戶識別碼",
      images: "圖片",
    },
    validate: {
      required: {
        id_reg: "請填寫ID",
        name: "請填寫區域名稱",
        ac_price_per_electricity: "請填寫直流按電量價格",
        ac_price_per_minute: "請填寫直流按分鐘價格",
        lon: "請填寫經度",
        lat: "請填寫緯度",
        address: "請填寫地址",
        dc_price_per_electricity: "請填寫交流按電量價格",
        dc_price_per_minute: "請填寫交流按分鐘價格",
        tenant_key: "請填寫租戶識別碼",
      },
    },
  },
  regionImages: {
    name: {
      title: "停車場圖片",
      detailTitle: "停車場圖片詳情",
      editTitle: "編輯停車場圖片",
    },
    table: {
      id_reg_img: "ID",
      id_reg: "所屬區域",
      url: "鏈接",
      upload_time: "上傳時間",
      tenant_key: "租戶識別碼",
    },
    field: {
      id_reg_img: "ID",
      id_reg: "所屬區域",
      url: "鏈接",
      upload_time: "上傳時間",
      tenant_key: "租戶識別碼",
    },
    validate: {
      required: {
        id_reg_img: "請填寫ID",
        id_reg: "請填寫所屬區域",
        url: "請填寫鏈接",
        upload_time: "請填寫上傳時間",
        tenant_key: "請填寫租戶識別碼",
      },
    },
  },
  repairLog: {
    name: {
      title: "維修記錄",
      detailTitle: "維修記錄詳情",
      editTitle: "編輯維修記錄",
    },
    table: {
      id_rep_log: "ID",
      id_car: "車輛",
      amount: "金額",
      repair_date: "維修日期",
      remark: "備註",
      tenant_key: "租戶識別碼",
    },
    field: {
      id_rep_log: "ID",
      id_car: "車輛",
      amount: "金額",
      repair_date: "維修日期",
      remark: "備註",
      tenant_key: "租戶識別碼",
    },
    validate: {
      required: {
        id_rep_log: "請填寫ID",
        id_car: "請填寫車輛",
        amount: "請填寫金額",
        repair_date: "請填寫維修日期",
        remark: "請填寫備註",
        tenant_key: "請填寫租戶識別碼",
      },
    },
  },
  role: {
    name: {
      title: "角色",
      detailTitle: "角色詳情",
      editTitle: "編輯角色",
    },
    table: {
      id_rol: "ID",
      name: "角色",
      remark: "備註",
    },
    field: {
      id_rol: "ID",
      name: "角色",
      remark: "備註",
      authorityList: "權限列錶",
    },
    validate: {
      required: {
        id_rol: "請填寫ID",
        name: "請填寫角色",
        remark: "請填寫備註",
      },
    },
  },
  roleAuthority: {
    name: {
      title: "RoleAuthority",
      detailTitle: "RoleAuthority Detail",
      editTitle: "Edit RoleAuthority",
    },
    table: {
      id_rol_aut: "id_rol_aut",
      id_rol: "id_rol",
      id_aut: "id_aut",
    },
    field: {
      id_rol_aut: "id_rol_aut",
      id_rol: "id_rol",
      id_aut: "id_aut",
    },
    validate: {
      required: {
        id_rol_aut: "請填寫id_rol_aut",
        id_rol: "請填寫id_rol",
        id_aut: "請填寫id_aut",
      },
    },
  },
  transactionLog: {
    name: {
      title: "事務記錄",
      detailTitle: "事務記錄詳情",
      editTitle: "編輯事務記錄",
    },
    table: {
      id_tra_log: "ID",
      id_con: "所屬連接端",
      event_time: "發生時間",
      id_tag: "用戶標籤",
      start_time: "事務開始時間",
      start_value: "開始值",
      stop_time: "事務結束時間",
      stop_value: "結束值",
      stop_reason: "結束原因",
      fail_reason: "失敗原因",
      tenant_key: "租戶識別碼",
      current_electricity: "當前充電電量",
    },
    field: {
      id_tra_log: "ID",
      id_con: "所屬連接端",
      event_time: "發生時間",
      id_tag: "用戶標籤",
      start_time: "事務開始時間",
      start_value: "開始值",
      stop_time: "事務結束時間",
      stop_value: "結束值",
      stop_reason: "結束原因",
      fail_reason: "失敗原因",
      tenant_key: "租戶識別碼",
      current_electricity: "當前充電電量",
    },
    validate: {
      required: {
        id_tra_log: "請填寫ID",
        id_con: "請填寫所屬連接端",
        event_time: "請填寫發生時間",
        id_tag: "請填寫用戶標籤",
        start_time: "請填寫事務開始時間",
        start_value: "請填寫開始值",
        stop_time: "請填寫事務結束時間",
        stop_value: "請填寫結束值",
        stop_reason: "請填寫結束原因",
        fail_reason: "請填寫失敗原因",
        tenant_key: "請填寫租戶識別碼",
        current_electricity: "請填寫當前充電電量",
      },
    },
  },
  user: {
    name: {
      title: "用戶",
      detailTitle: "用戶詳情",
      editTitle: "編輯用戶",
    },
    table: {
      id_use: "ID",
      balance: "餘額",
      account: "賬號",
      password: "密碼",
      register_time: "註冊時間",
      email: "郵箱",
      token: "令牌",
      salt: "鹽",
      tenant_key: "租戶識別碼",
      phone: "電話",
      register_type: "註冊類型",
    },
    field: {
      id_use: "ID",
      balance: "餘額",
      account: "賬號",
      password: "密碼",
      register_time: "註冊時間",
      email: "郵箱",
      token: "令牌",
      salt: "鹽",
      tenant_key: "租戶識別碼",
      phone: "電話",
      register_type: "註冊類型",
    },
    validate: {
      required: {
        id_use: "請填寫ID",
        balance: "請填寫餘額",
        account: "請填寫賬號",
        password: "請填寫密碼",
        register_time: "請填寫註冊時間",
        email: "請填寫郵箱",
        token: "請填寫令牌",
        salt: "請填寫鹽",
        tenant_key: "請填寫租戶識別碼",
        phone: "請填寫電話",
        register_type: "請填寫註冊類型",
      },
    },
  },
  userManagement: {
    name: {
      title: "用戶管理",
      superAdmin: "超級管理員",
      siteAdmin: "站點管理員",
      manager: "經理",
      user: "用戶",
    },
    table: {
      name: "姓名",
      email: "郵箱",
      role: "角色",
      structureLevel: "層級",
    },
    field: {},
    validate: {
      required: {},
    },
  },
  supplierManagement: {
    name: {
      title: "供應商API設定",
      search: "搜索供應商",
      add: "添加供應商",
    },
    table: {
      brand: "品牌",
      url: "鏈接",
      publicKey: "公鑰",
      privateKey: "私鑰",
      status: "狀態",
    },
    field: {},
    validate: {
      required: {},
    },
  },
  reportManagement: {
    name: {
      title: "報告",
      search: "搜索報告",
      add: "新報告",
      favorites: "收藏",
      tecurrentReport: "技術報告",
      applicationReports: "應用報告",
      myReports: "我的報告",
      newReports: "新報告",
      alarmsCountReport: "警告報告",
      readingsHistoryReport: "曆史報告",
      usablityReport: "能力報告",
      communicationReport: "通訊報告",
      tenantUsageReport: "租戶使用報告",
      dataSourceUsageReport: "數據源使用報告",
      setReport: "下載報告",
    },
    table: {
      subscribed: "訂閱",
      name: "名稱",
      entity: "實體",
      creationDate: "創建日期",
      createdBy: "創建者",
      editable: "編輯",
      subscribers: "訂閱者",
      lastRunResult: "最後運行結果",
      lastRunDate: "最後運行時間",
    },
    field: {},
    validate: {
      required: {},
    },
  },
  profileManagement: {
    name: {
      title: "資料管理",
      superAdmin: "管理員",
      user: "用戶",
      sites: "站點",
      devices: "設備",
      information: "基礎信息",
    },
    table: {},
    field: {
      companyName: "公司名",
      address: "地址",
      email: "邮箱",
      telephoneNo: "电话",
      themeColor: "主题颜色",
      domain: "域名",
      changeDomainName: "修改域名",
      locale: "本地化",
      defaultLanguage: "默认语言",
      dateFormat: "日期格式",
      defaultTimeZone: "时区",
      timeFormat: "时间格式",
      logo: "图标",
      drag: "拖动图片到此处",
      max: "最大4K的图片",
    },
    validate: {
      required: {},
    },
  },
  userPlan: {
    name: {
      title: "用戶計劃",
      detailTitle: "用戶計劃詳情",
      editTitle: "編輯用戶計劃",
    },
    table: {
      id_use: "用戶",
      id_pla: "計劃",
      start_date: "合同開始日期",
      tenant_key: "租戶識別碼",
    },
    field: {
      id_use: "用戶",
      id_pla: "計劃",
      start_date: "合同開始日期",
      tenant_key: "租戶識別碼",
    },
    validate: {
      required: {
        id_use: "請填寫用戶",
        id_pla: "請填寫計劃",
        start_date: "請填寫合同開始日期",
        tenant_key: "請填寫租戶識別碼",
      },
    },
  },
  dashboard: {
    name: {
      overview: "概覽",
      totalPorts: "總端口數",
      unavailablePorts: "離線端口數",
      inUsePorts: "使用中的端口數",
      availablePorts: "可用端口數",
      errorPorts: "異常端口數",
      totalChargers: "充電樁總數",
      availableChargers: "可用充電樁數",
      inUseChargers: "使用中的充電樁數",
      unavailableChargers: "不可用充電樁數",
      energyUsage: "總使用電量(kWh)",
      totalRevenue: "總收入",
      totalEnergy: "總電量",
      newUser: "新用戶數",
    },
    table: {},
    field: {},
    validate: {
      required: {},
    },
  },
  dashboard2: {
    name: {
      customer: "客戶",
      site: "站點",
      floor: "樓層",
      zone: "區域 / 分組",
      devices: "設備",
      compare: "對比",
      location: "地址",
      alarms: "警報",
      offlineDevices: "離線設備數",
      energyConsumption: "能源消耗",
      energyConsumedPerMinute: "每分鐘耗能",
      readtime: "時間",
      floorplan: "平面圖",
      temperature: "溫度",
      humidity: "濕度",
    },
    total: {
      Devices: "設備數",
      ConnectedDevices: "已連接設備數",
      OpenAlarms: "打開的警告",
      CriticalAlarms: "嚴重警告",
      MajorAlarms: "主要警告",
      MinerAlarms: "輕微警告",
      OtherAlarms: "其他警告",
    },
    table: {},
    field: {},
    validate: {
      required: {},
    },
  },
  appVersionManage: {
    name: {},
    table: {},
    field: {
      androidVersion: "Android版本",
      androidDownloadLink: "Android下載鏈接",
      androidComplieNumber: "Android編譯次數",
      iosVersion: "IOS版本",
      iosBundleId: "IOS資源號",
      iosComplieNumber: "IOS編譯次數",
    },
    validate: {
      required: {
        androidVersion: "請填入Android版本",
        androidDownloadLink: "請填入Android下載鏈接",
        androidComplieNumber: "請填入Android編譯次數",
        iosVersion: "請填入IOS版本",
        iosBundleId: "請填入IOS資源號",
        iosComplieNumber: "請填入IOS編譯次數",
      },
    },
  },
  tradeManage: {
    detailTitle: "訂單詳情",
    tradeStatusList: {
      0: "空閑",
      1: "進行中",
      2: "已完成",
      3: "故障",
    },
    table: {
      index: "序號",
      id: "事務編號",
      tradeNo: "充電卡號",
      identifier: "電樁標識",
      port: "電樁端口",
      startTime: "開始充電時間",
      duration: "充電時長",
      endTime: "預計結束時間",
      actualEndTime: "實際結束時間",
      actualDuration: "實際充電時長",
      chargeType: "充電類型",
      otherArgs: "其他參數",
      tradeStatus: "交易狀態",
      pileType: "電樁類型",
      currentElectricity: "耗電量(wh)",

      createBy: "創建者",
      createTime: "創建時間",
      updateBy: "更新者",
      updateTime: "更新時間",
      remark: "備註",

      action: {
        edit: "編輯",
        del: "刪除",
        endTransaction: "終止",
      },
    },
    validate: {
      tradeNo: "請填寫流水編號",
      identifier: "請填寫電樁標識",
      port: "請填寫電樁埠",
      startTime: "請選擇開始充電時間",
      duration: "請選擇充電時長",
      endTime: "請選擇預計結束時間",
      actualEndTime: "請選擇實際結束時間",
      actualDuration: "請選擇實際充電時長",
      chargeType: "請選擇充電類型",
      otherArgs: "請填寫其他參數",
      tradeStatus: "請選擇交易狀態",
      pileType: "請選擇電樁類型",

      createBy: "創建者",
      createTime: "創建時間",
      updateBy: "更新者",
      updateTime: "更新時間",
      remark: "備註",
    },
  },
  rentalManage: {
    table: {
      id: "ID",
      index: "序號",
      reId: "客戶編號",
      reName: "客戶名稱",
      reIsAuthorized: "是否授權",
      reSecKey: "金鑰",
      reAisle: "回調位址",
      tenantKey: "租戶標識",
      reLeaseTime: "到期時間",
      createBy: "建立者",
      createTime: "創建時間",
      updateBy: "更新者",
      updateTime: "更新時間",
      remark: "備註",
    },
    validate: {
      id: "ID",
      reId: "請填寫客戶編號",
      reName: "請填寫客戶名稱",
      reIsAuthorized: "請選擇是否授權",
      reSecKey: "請填寫金鑰",
      reAisle: "請填寫回調位址",
      tenantKey: "請填寫租戶標識",
      reLeaseTime: "請選擇到期時間",
      createBy: "請填寫建立者",
      createTime: "請選擇建立時間",
      updateBy: "請填寫更新者",
      updateTime: "請選擇更新時間",
      remark: "請填寫備註",
    },
  },
  pileManage: {
    updateFirmware: {
      identifier: "序列號",
      location: "遠端位址",
      retrieveDate: "檢索日期",
    },
    uValidate: {
      identifier: "請輸入序列號",
      location: "請輸入遠端位址",
      retrieveDate: "請選擇檢索日期",
    },
    field: {
      id: "ID",
      identifier: "充電樁序列號",
      reId: "客戶編號",
      evName: "充電樁名稱",
      evStatus: "狀態",
      evType: "充電樁類型",
      firmwareVersion: "固件版本",
      lastHeartBeatTime: "最近心跳時間",
      remark: "備註",
      readMeter: "讀錶方式",
      readMeterList: {
        0: "差值",
        1: "儀錶值",
      },
      evStatusList: {
        UnEnroll: "未注冊",
        Available: "可用的",
        Detected: "待檢測",
        UnAvailable: "不可用",
      },
      action: {
        updateFirmware: "更新韌體",
        hardReset: "強制重啓",
        softReset: "優雅重啓",
      },
    },
    validate: {
      id: "請填寫ID",
      identifier: "請填寫電樁標識符",
      reId: "請填寫租戶標識",
      evName: "請填寫電樁名稱",
      evStatus: "請選擇電樁狀態",
      evType: "請選擇電樁類型",
      evArgs: "請填寫電樁參數",
      remark: "請填寫電樁備注",
    },
    // 设置列表
    settingTable: {
      index: "序號",
      key: "配置名",
      readonly: "是否只讀",
      value: "配置值",
    },
    // 设置表单
    settingField: {
      key: "配置名",
      readonly: "是否只讀",
      value: "配置值",
      required: {
        key: "請填寫配置名",
        readonly: "請選擇是否只讀",
        value: "請填寫配置值",
      },
    },
  },
  chargerManage: {
    name: {
      start: "開始充電",
      stop: "結束充電",
    },
    table: {
      id: "ID",
      identifier: "充電樁序列號",
      cName: "端口名字",
      cPort: "電槍端口",
      cPower: "端口功率(kwh)",
      cType: "端口類型",
      cStatus: "端口狀態",
      remark: "備註",
    },
    validate: {
      id: "ID",
      identifier: "請填寫充電樁序列號",
      cName: "請填寫電槍名字",
      cPort: "請填寫電槍端口",
      cPower: "請填寫功率",
      cType: "請選擇類型",
      cStatus: "請選擇狀態",
      remark: "請填寫備註",
    },
  },
  DeviceManage: {
    detail: {
      title: "我的設備",
      subTitle: "設備詳情",
      detail: "詳情",
      attributes: "參數",
      lattestTelemetry: "最新數據",
      alarms: "警告",
      events: "事件",
      relations: "關係",
      openDetailsPage: "打開詳情頁",
      makeDevicePublic: "設備公開",
      assignToCustomer: "分配給客戶",
      manageCredentials: "憑據管理",
      checkConnectivity: "鏈接檢測",
      deleteDevice: "刪除設備",
      copyDeviceId: "複製設備ID",
      copyDeviceToken: "複製設備Token",
      name: "名稱",
      nameP: "我的新設備",
      deviceProfile: "設備資料",
      deviceProfileP: "默認",
      label: "標簽",
      assignedFirmware: "固件",
      assignedSoftware: "軟件",
      isGateway: "是否網關",
      description: "描述",
      attrivutesTitle: "服務器參數",
      lastUpdateTime: "最後更新時間",
      key: "密鑰",
      value: "值",
      filterActive: "過濾:已激活",
      forAllTime: "全部時間",
      createdTime: "創建時間",
      originator: "組織者",
      type: "類型",
      severity: "程度",
      assignee: "受讓人",
      eventType: "事件類型",
      last15Minute: "最近15分鍾",
      event: "事件",
      method: "方法",
      error: "異常",
    },
    table: {
      id: "設備ID",
      gatewayId: "閘道ID",
      nodeId: "節點ID",
      uuid: "設備UUID",
      category: "產品品類",
      categoryName: "產品品類名稱",
      name: "設備名稱",
      productId: "產品ID",
      productName: "產品名稱",
      localKey: "金鑰",
      sub: "是否為子設備",
      assetId: "資產ID",
      ownerId: "家庭ID",
      ip: "設備IP",
      lon: "經度",
      lat: "纬度",
      model: "產品型號",
      timeZone: "時區",
      activeTime: "啟動時間",
      updateTime: "更新時間",
      createTime: "初次配網時間",
      icon: "設備圖標",
      isOnline: "是否線上",
      customName: "自定義名稱",
      bindSpaceId: "綁定空間ID",
      controlPanel: "控製面板",
    },
    validate: {
      id: "設備ID",
      gatewayId: "閘道ID",
      nodeId: "節點ID",
      uuid: "設備UUID",
      category: "產品品類",
      categoryName: "產品品類名稱",
      name: "設備名稱",
      productId: "產品ID",
      productName: "產品名稱",
      localKey: "金鑰",
      sub: "是否為子設備",
      assetId: "資產ID",
      ownerId: "家庭ID",
      ip: "設備IP",
      lon: "經度",
      lat: "纬度",
      model: "產品型號",
      timeZone: "時區",
      activeTime: "啟動時間",
      updateTime: "更新時間",
      createTime: "初次配網時間",
      icon: "設備圖標",
      isOnline: "是否線上",
    },
  },
  DeviceLogs: {
    eventType: {
      1: "上線",
      2: "下線",
      3: "設備啟動",
      4: "設備重置",
      5: "指令下發",
      6: "固件升級",
      7: "數據點上報",
      8: "設備信號量",
      9: "設備重啓",
      10: "定時資訊",
    },
    table: {
      deviceId: "设备ID",
      code: "功能點ID",
      value: "事件詳情",
      eventTime: "事件時間",
      eventFrom: "事件來源",
      eventId: "事件ID",
      status: "事件狀態",
      eventValue: "事件值",
    },
    validate: {
      deviceId: "设备ID",
      types: "事件ID",
      codes: "功能點ID",
      value: "状态值",
      startTime: "开始时间",
      endTime: "结束时间",
    },
  },

  Structure: {
    table: {
      NickName: "暱稱",
      CreatedTime: "創建時間",
      Status: "狀態",
      Action: "操作",
    },
    AddStructureLevel: "添加結構級別",
    StructureAccessRight: "結構訪問權限",
    StructureLevel: "結構級別",
    Normal: "正常",
    Edit: "編輯",
    ViewRoot: "查看根目錄",
  },

  Alarms: {
    table: {
      CreatedTime: "創建時間",
      Status: "狀態",
      Type: "類型",
    },
  },
  Device: {
    table: {
      Label: "標籤",
      Status: "狀態",
      Name: "名稱",
      Component: "組件",
    },
  },
};

export default zhTW;
