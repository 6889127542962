<template>
  <Row>
    <div class="NewReportsBox">
      <div class="title">{{ title }}</div>
      <div class="text">{{ text }}</div>
      <hr />
      <div class="footer"><a target="_blank" href="/api/tuya/devices/downloadExcelTemplate">{{ t('reportManagement.name.setReport') }} ></a></div>
    </div>
  </Row>
</template>

<script setup>
import {DeviceOperaLogExportToExcel} from "@/api/tuya";
import { useI18n } from "vue-i18n";

const {t} = useI18n();

const props = defineProps({
  title: {
    type: String,
    default: "默认标题",
  },
  text: {
    type: String,
    default: "默认文本",
  },
});

function exportToExcel() {
  const api = new DeviceOperaLogExportToExcel()
  api.getDownLoad({
    "deviceId": "eb78035e9e6ee54c176jyd",
  }).then(() => {}).finally(f => {

  })
}

</script>

<style lang="less">
.NewReportsBox {
  position: relative;
  width: 100%;
  background-color: white;
  border-radius: 10px;
  margin: 0 10px;
  margin-bottom: 20px;

  .title {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    background-color: #75a4d6;
    color: white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  .text {
    padding: 15px 10px;
    height: 120px;
    font-size: 16px;
  }
  .footer {
    padding: 0 20px;
    height: 40px;
    display: flex;
    color: #75a4d6;
    align-items: center;
    font-size: 18px;
    p {
      cursor: pointer;
      font-weight: bolder;
    }
  }
}
</style>
