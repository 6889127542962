<template>
  <!-- <Modal id="BalanceLogDetail" width="60%" :open="visible" @cancel="visible = !visible" @ok="visible = !visible" :title="$t('public.name.detail')" :zIndex="9999999900">
    <Descriptions >
      <DescriptionsItem :label="$t('DeviceManage.table.activeTime')">{{data.activeTime}}</DescriptionsItem>
      <DescriptionsItem :label="$t('DeviceManage.table.bindSpaceId')">{{data.bindSpaceId}}</DescriptionsItem>
      <DescriptionsItem :label="$t('DeviceManage.table.category')">{{data.category}}</DescriptionsItem>

      <DescriptionsItem :label="$t('DeviceManage.table.createTime')">{{data.createTime}}</DescriptionsItem>
      <DescriptionsItem :label="$t('DeviceManage.table.customName')">{{data.customName}}</DescriptionsItem>
      <DescriptionsItem :label="$t('DeviceManage.table.icon')">{{data.icon}}</DescriptionsItem>

      <DescriptionsItem :label="$t('DeviceManage.table.id')">{{data.id}}</DescriptionsItem>
      <DescriptionsItem :label="$t('DeviceManage.table.ip')">{{data.ip}}</DescriptionsItem>
      <DescriptionsItem :label="$t('DeviceManage.table.isOnline')">{{data.isOnline}}</DescriptionsItem>

      <DescriptionsItem :label="$t('DeviceManage.table.lat')">{{data.lat}}</DescriptionsItem>
      <DescriptionsItem :label="$t('DeviceManage.table.lon')">{{data.lon}}</DescriptionsItem>
      <DescriptionsItem :label="$t('DeviceManage.table.localKey')">{{data.localKey}}</DescriptionsItem>

      <DescriptionsItem :label="$t('DeviceManage.table.model')">{{data.model}}</DescriptionsItem>
      <DescriptionsItem :label="$t('DeviceManage.table.name')">{{data.name}}</DescriptionsItem>
      <DescriptionsItem :label="$t('DeviceManage.table.productId')">{{data.productId}}</DescriptionsItem>

      <DescriptionsItem :label="$t('DeviceManage.table.productName')">{{data.productName}}</DescriptionsItem>
      <DescriptionsItem :label="$t('DeviceManage.table.sub')">{{data.sub}}</DescriptionsItem>
      <DescriptionsItem :label="$t('DeviceManage.table.timeZone')">{{data.timeZone}}</DescriptionsItem>

      <DescriptionsItem :label="$t('DeviceManage.table.updateTime')">{{data.updateTime}}</DescriptionsItem>
      <DescriptionsItem :label="$t('DeviceManage.table.uuid')">{{data.uuid}}</DescriptionsItem>
  </Descriptions>
  </Modal> -->
  <div id="body">
    <div ref="ModalRef" class="TabsModal">
      <Modal
        :open="visible"
        @cancel="visible = !visible"
        :mask="false"
        width="100%"
        wrap-class-name="full-modal"
        @ok="handleOk"
        :getContainer="() => $refs.ModalRef"
        :footer="false"
        class="TabsModal"
        :destroyOnClose="true"
      >
        <Row
          style="
            background-color: #0d5480;
            padding: 20px 24px;
            color: white;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
          "
        >
          <Col span="24" style="font-size: 22px">{{ t('DeviceManage.detail.title') }}</Col>
          <Col span="24">{{ t('DeviceManage.detail.subTitle') }}</Col>
        </Row>
        <Row style="padding: 0 24px">
          <Col span="24">
            <Tabs>
              <Tabs.TabPane key="1" :tab="t('DeviceManage.detail.detail')">
                <Col span="24">
                  <Button class="colorBtn">{{ t('DeviceManage.detail.openDetailsPage') }}</Button>
                  <Button class="colorBtn">{{ t('DeviceManage.detail.makeDevicePublic') }}</Button>
                  <Button class="colorBtn">{{ t('DeviceManage.detail.assignToCustomer') }}</Button>
                  <Button class="colorBtn" @click="CredentialsOpen = true">{{ t('DeviceManage.detail.manageCredentials') }}</Button>
                  <Button class="colorBtn">{{ t('DeviceManage.detail.checkConnectivity') }}</Button>
                  <Button class="colorBtn">{{ t('DeviceManage.detail.deleteDevice') }}</Button>
                </Col>
                <Col span="24">
                  <Button class="colorBtn" style="background-color: #fdfefe; color: black">{{ t('DeviceManage.detail.copyDeviceId') }}</Button>
                  <Button class="colorBtn" style="background-color: #fdfefe; color: black">{{ t('DeviceManage.detail.copyDeviceToken') }}</Button>
                </Col>
                <Col span="24" style="background-color: #f7fbfc">
                  <div class="colorInput">
                    <div style="padding: 1px 11px; color: #d2d6d6; font-size: 11px">{{ t('DeviceManage.detail.name') }}</div>
                    <div><Input style="background-color: #f7fbfc" :placeholder="t('DeviceManage.detail.nameP')"></Input></div>
                  </div>
                </Col>
                <Col span="24" style="background-color: #f7fbfc">
                  <div class="colorInput">
                    <div style="padding: 1px 11px; color: #d2d6d6; font-size: 11px">{{ t('DeviceManage.detail.deviceProfile') }}</div>
                    <div><Input style="background-color: #f7fbfc" :placeholder="t('DeviceManage.detail.deviceProfileP')"></Input></div>
                  </div>
                </Col>
                <Col span="24" style="background-color: #f7fbfc">
                  <div class="colorInput">
                    <div><Input style="background-color: #f7fbfc; height: 50px" :placeholder="t('DeviceManage.detail.label')"></Input></div>
                  </div>
                </Col>
                <Col span="24" style="background-color: #f7fbfc">
                  <div class="colorInput">
                    <div>
                      <Input style="background-color: #f7fbfc; height: 50px" :placeholder="t('DeviceManage.detail.assignedFirmware')"></Input>
                    </div>
                  </div>
                </Col>
                <Col span="24" style="background-color: #f7fbfc">
                  <div class="colorInput">
                    <div>
                      <Input style="background-color: #f7fbfc; height: 50px" :placeholder="t('DeviceManage.detail.assignedSoftware')"></Input>
                    </div>
                  </div>
                </Col>
                <Col span="24" style="margin-top: 20px">
                  <Switch v-model:checked="checked" />
                  {{t('DeviceManage.detail.isGateway')}}
                </Col>
                <Col span="24" style="background-color: #f7fbfc">
                  <div class="colorInput">
                    <div>
                      <Input style="background-color: #f7fbfc; height: 50px" :placeholder="t('DeviceManage.detail.description')"></Input>
                    </div>
                  </div>
                </Col>
              </Tabs.TabPane>
              <Tabs.TabPane key="2" :tab="t('DeviceManage.detail.attributes')">
                <Row align="middle" style="margin-bottom: 10px">
                  <Col span="12">{{t('DeviceManage.detail.attrivutesTitle')}}</Col>
                  <Col span="12" align="right">
                    <PlusOutlined style="margin-right: 10px; font-size: 20px" />
                    <RedoOutlined style="margin-right: 10px; font-size: 20px" />
                    <SearchOutlined style="font-size: 20px" />
                  </Col>
                </Row>
                <Col span="24">
                  <Table :row-selection="rowSelection" :dataSource="dataSource" :columns="servercolumns">
                    <template #headerCell="{ column }">
                      <template v-if="column.dataIndex === 'action'">
                        <span>
                          {{ $t("public.table.action") }}
                        </span>
                      </template>
                    </template>
                    <template #bodyCell="{ column, record }">
                      <template v-if="column.dataIndex === 'action'">
                        <div class="pc-display">
                          <Button type="link" size="small" @click="showDetail(record)"><EditOutlined /></Button>
                        </div>
                      </template>
                    </template>
                  </Table>
                </Col>
              </Tabs.TabPane>
              <Tabs.TabPane key="3" :tab="t('DeviceManage.detail.lattestTelemetry')">
                <Col span="24">
                  <Table :dataSource="dataSource" :columns="servercolumns"></Table>
                </Col>
              </Tabs.TabPane>
              <Tabs.TabPane key="4" :tab="t('DeviceManage.detail.alarms')">
                <Col span="24">
                  <Button class="borderBtn">
                    <AlignCenterOutlined />
                    {{ t('DeviceManage.detail.filterActive') }}
                  </Button>
                  <Button class="borderBtn">
                    <ClockCircleOutlined style="margin-right: 5px" />
                    {{ t('DeviceManage.detail.forAllTime') }}
                  </Button>
                </Col>
                <Col span="24">
                  <Table :row-selection="rowSelection" :dataSource="alarmdataSource" :columns="alarmcolumns">
                    <template #headerCell="{ column }">
                      <template v-if="column.dataIndex === 'action'">
                        <span>
                          {{ $t("public.table.action") }}
                        </span>
                      </template>
                    </template>
                    <template #bodyCell="{ column, record }">
                      <template v-if="column.dataIndex === 'action'">
                        <div class="pc-display">
                          <Button type="link" size="small"><SmallDashOutlined style="font-size: 22px" /></Button>
                        </div>
                      </template>
                      <template v-if="column.dataIndex === 'severity'">
                        <span style="color: red">
                          {{ record.severity }}
                        </span>
                      </template>
                      <template v-if="column.dataIndex === 'assignee'">
                        <Avatar></Avatar>
                        <span style="margin: 0 10px">
                          {{ record.assignee }}
                        </span>
                        <DownOutlined />
                      </template>
                    </template>
                  </Table>
                </Col>
              </Tabs.TabPane>
              <Tabs.TabPane key="5" :tab="t('DeviceManage.detail.events')">
                <Col span="24" style="font-size: 11px; color: #0c5580">{{ t('DeviceManage.detail.eventType') }}</Col>
                <Col span="24">
                  <Select
                    style="width: 200px; border-bottom: 1px solid #09537d"
                    :bordered="false"
                    v-model:value="selectValue"
                  >
                    <Select.Option value="jack">Error</Select.Option>
                    <Select.Option value="lucy">Lifecycle event</Select.Option>
                    <Select.Option value="Yiminghe">Statistic</Select.Option>
                  </Select>
                  <Button style="border-color: #0c5580; color: #0c5580; margin-left: 10px">
                    <ClockCircleOutlined />
                    {{ t('DeviceManage.detail.last15Minute') }}
                  </Button>
                </Col>
                <Col span="24">
                  <Table :dataSource="eventdatasource" :columns="eventcolumns"></Table>
                </Col>
              </Tabs.TabPane>
              <!-- <Tabs.TabPane key="6" :tab="t('DeviceManage.detail.relations')">
                <Col span="24">
                  <Table :dataSource="dataSource" :columns="servercolumns"></Table>
                </Col>
              </Tabs.TabPane>
              <Tabs.TabPane key="7" :tab="t('DeviceManage.detail.relations')">
                <Col span="24">
                  <Table :dataSource="dataSource" :columns="servercolumns"></Table>
                </Col>
              </Tabs.TabPane> -->
            </Tabs>
          </Col>
        </Row>
      </Modal>
    </div>
    <div ref="Credentials" class="CredentialsModal">
      <Modal
        v-model:open="CredentialsOpen"
        :mask="false"
        wrap-class-name="full-modal"
        @ok="handleOk"
        :getContainer="() => $refs.Credentials"
        :footer="false"
        class="TabsModal"
        :destroyOnClose="true"
      >
        <Row
          style="
            background-color: #0d5480;
            padding: 20px 24px;
            color: white;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
          "
        >
          <Col span="24" style="font-size: 22px">Device Credentials</Col>
        </Row>
        <Row style="padding: 20px 24px">
          <Col span="24">Credentials type</Col>

          <Col span="24">
            <Row class="typeBox">
              <Col span="8" align="center" @click="changeSelect('1')" :class="selectType == 1 ? 'selectBox' : ''">
                Access token
              </Col>
              <Col span="8" align="center" @click="changeSelect('2')" :class="selectType == 2 ? 'selectBox' : ''">
                X509
              </Col>
              <Col span="8" align="center" @click="changeSelect('3')" :class="selectType == 3 ? 'selectBox' : ''">
                MQTT Basic
              </Col>
            </Row>
          </Col>
          <Col span="24" style="background-color: #f7fbfc; margin-top: 15px">
            <div>
              <div style="padding: 1px 11px; color: #d2d6d6; font-size: 11px">Device Profile</div>
              <div style="position: relative">
                <Input style="background-color: #f7fbfc" placeholder="default"></Input>
                <div class="reloadBox">
                  <RedoOutlined />
                </div>
              </div>
            </div>
          </Col>
          <Col span="24" style="background-color: #f7fbfc; margin-top: 15px">
            <div>
              <div style="padding: 1px 11px; color: #d2d6d6; font-size: 11px">Device Profile</div>
              <div style="position: relative">
                <Input style="background-color: #f7fbfc" placeholder="default"></Input>
                <div class="reloadBox">
                  <RedoOutlined />
                </div>
              </div>
            </div>
          </Col>
          <Col span="24" style="background-color: #f7fbfc; margin-top: 15px">
            <div>
              <div style="padding: 1px 11px; color: #d2d6d6; font-size: 11px">Device Profile</div>
              <div style="position: relative">
                <Input.Password
                  style="background-color: #f7fbfc"
                  placeholder="default"
                  :bordered="false"
                  :controls="false"
                ></Input.Password>
                <div class="reloadBox">
                  <RedoOutlined />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Modal>
    </div>
  </div>
</template>
<script>
export default {
  name: "DetailModel",
};
</script>
<script setup>
import {
  Button,
  Col,
  TypographyTitle,
  Modal,
  Row,
  Table,
  Tag,
  Tabs,
  Avatar,
  Input,
  Switch,
  InputNumber,
  Select,
} from "ant-design-vue";
import {
  EditOutlined,
  PlusOutlined,
  SearchOutlined,
  RedoOutlined,
  ClockCircleOutlined,
  AlignCenterOutlined,
  SmallDashOutlined,
  DownOutlined,
} from "@ant-design/icons-vue";
import { reactive, ref } from "vue";
import { useI18n } from "vue-i18n";

const i18n = useI18n();
const {t} = useI18n();
let visible = ref(false);
let data = ref({});

let CredentialsOpen = ref(false);

let selectType = ref("3");

let selectValue = ref("Error");

let eventdatasource = [];

let checked = ref(false);

const eventcolumns = [
  { title: t('DeviceManage.detail.event'), ellipsis: true, dataIndex: "event", key: "event" },

  { title: t('DeviceManage.detail.method'), ellipsis: true, dataIndex: "method", key: "method" },
  {
    title: t('DeviceManage.detail.error'),
    ellipsis: true,
    dataIndex: "error",
    key: "error",
  },
];

let alarmdataSource = [
  {
    createdtime: "2023-10-31 13:11:55",
    originator: "My Davice",
    severity: "Critical",
    type: "High Temperatune",
    assignee: "Unassignee",
  },
];

const alarmcolumns = [
  { title: t('DeviceManage.detail.createdTime'), ellipsis: true, dataIndex: "createdtime", key: "createdtime" },

  { title: t('DeviceManage.detail.originator'), ellipsis: true, dataIndex: "originator", key: "originator" },
  {
    title: t('DeviceManage.detail.type'),
    ellipsis: true,
    dataIndex: "type",
    key: "type",
  },
  {
    title: t('DeviceManage.detail.severity'),
    ellipsis: true,
    dataIndex: "severity",
    key: "severity",
  },
  {
    title: t('DeviceManage.detail.assignee'),
    ellipsis: true,
    dataIndex: "assignee",
    key: "assignee",
    width: 200,
  },

  { dataIndex: "action", key: "action", fixed: "right", width: 145, ellipsis: true },
];

let dataSource = [
  {
    lasttime: "2024-08-31 10:31",
    key: "inactiityAlarmTime",
    value: "false",
  },
  {
    lasttime: "2024-08-31 10:31",
    key: "active",
    value: "4597454894651894",
  },
  {
    lasttime: "2024-08-31 10:31",
    key: "lastActivityTime",
    value: "45954145541894",
  },
  {
    lasttime: "2024-09-20 10:31",
    key: "lastConnectTime",
    value: "4597414954651894",
  },
  {
    lasttime: "2024-08-31 10:31",
    key: "lastDisconnectTime",
    value: "564849519894",
  },
];

const servercolumns = [
  { title: t('DeviceManage.detail.lastUpdateTime'), ellipsis: true, dataIndex: "lasttime", key: "lasttime" },

  { title: t('DeviceManage.detail.key'), ellipsis: true, dataIndex: "key", key: "key" },
  {
    title: t('DeviceManage.detail.value'),
    ellipsis: true,
    dataIndex: "value",
    key: "value",
  },

  { dataIndex: "action", key: "action", fixed: "right", width: 145, ellipsis: true },
];

function changeSelect(data) {
  selectType.value = data;
}

async function detailShow(obj) {
  data.value = obj;
  visible.value = true;
}
function handelNull(v) {
  return v === undefined || v === null || v === "0" || v === 0 ? "-" : v;
}

defineExpose({
  detailShow,
});
</script>

<style lang="less" scoped>
#body {
  .colored-section {
    position: relative;
    width: 250px;
    height: 100px;
    background-color: white;
    border-radius: 10px;
    margin: 0 10px;
    margin-bottom: 20px;

    padding: 0 20px;
    .num {
      font-size: 50px;
      text-align: right;
    }
    .title {
      position: absolute;
      bottom: 10px;
      left: 40px;
    }
  }

  .colored-section::before {
    content: "";
    position: absolute;
    left: 0;
    width: 20px;
    height: 100%;
    background-color: var(--right-color);
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .colorBtn {
    background-color: #085382;
    color: white;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .borderBtn {
    border: 1px solid #96adb6;
    color: #96adb6;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .colorInput {
    margin-top: 15px;
  }
  .TabsModal {
    :deep(.ant-modal) {
      width: 50vw !important;
      left: 23vw;
      height: 85vh;
    }
    :deep(.ant-modal-content) {
      height: 100%;
      padding: 0px;
      //     padding: 20px 24px;
    }
    :deep(.ant-modal-close-x) {
      color: white;
    }
  }
  .CredentialsModal {
    :deep(.ant-modal-content) {
      padding: 0px;
    }
    :deep(.ant-modal-close-x) {
      color: white;
    }
  }

  :deep(.ant-input) {
    border: 0px;
    // border-bottom: 1px solid black;
    border-radius: 0px;
  }

  :deep(.ant-input:focus) {
    box-shadow: 0 0 0 0;
    border-inline-end-width: 0px;
    outline: 0;
  }
  .typeBox {
    background-color: #f3f3f5;
    border-radius: 50px;
    height: 30px;
    line-height: 30px;
    cursor: pointer;
    margin-top: 10px;
  }
  .selectBox {
    background-color: #09537d;
    color: white;
    border-radius: 50px;
  }
  :deep(.ant-input-suffix) {
    margin-inline-start: -70px;
  }
  .reloadBox {
    position: absolute;
    right: 0px;
    bottom: 0px;
    font-size: 26px;
    height: 50px;
    width: 50px;
    background: #eff8f6;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
