<template>
  <Row id="body">
    <!-- 标签-->
    <Col :span="24" class="title">
      <TypographyTitle>{{ t("supplierManagement.name.title") }}</TypographyTitle>
    </Col>

    <Col :span="24" class="content">
      <!-- 查询表单-->
      <Row :gutter="[16, 32]">
        <Col :span="24">
          <Row style="margin-bottom: 15px">
            <Col :span="6" style="font-size: 26px">{{ t("supplierManagement.name.title") }}</Col>
            <Col :span="12"></Col>
            <Col :span="6" align="right" style="display: flex">
              <Input :placeholder="t('supplierManagement.name.search')" allowClear style="margin-right: 15px"></Input>
              <Button type="primary" @click="showNewReport">{{ t("supplierManagement.name.add") }}</Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <!-- 列表-->
      <Row>
        <Col :span="24">
          <!--          :row-selection="{selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"-->
          <Table
            :columns="columns"
            :dataSource="list"
            rowKey="id"
            :loading="loading"
            :scroll="{ x: 1500, y: 'calc(100vh - 300px)' }"
            :pagination="tablePagination"
            @change="chang_page"
          >
            <template #headerCell="{ column }">
              <template v-if="column.dataIndex === 'action'">
                <span>
                  {{ $t("public.table.action") }}
                  <!--                <Button v-if="checkPermission('ChargerManage.Add')" type="primary" size="small" shape="circle" @click="add()">-->
                  <!--                  <PlusOutlined/>-->
                  <!--                </Button>-->
                </span>
              </template>
            </template>

            <template #bodyCell="{ column, record }">
              <template v-if="column.dataIndex === 'privatekey'">
                <div v-if="record.ishide">********</div>
                <div v-else>
                  {{ record.privatekey }}
                </div>
              </template>
              <template v-if="column.dataIndex === 'status'">
                <div v-if="record.status == true" class="greendot"></div>
                <div v-else class="reddot"></div>
              </template>
              <template v-if="column.dataIndex === 'action'">
                <div class="pc-display">
                  <Button type="link" size="small">Edit</Button>
                  <Button type="link" size="small">Delete</Button>
                </div>
              </template>
            </template>
          </Table>
          <div v-if="showLoadingBut" style="width: 100%; text-align: center; margin-top: 5px">
            <Button @click="onClickLoading">loading({{ tablePagination.pageSize }})...</Button>
          </div>
        </Col>
      </Row>
    </Col>
  </Row>
</template>
<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "supplier",
};
</script>
<script setup>
import { createVNode, ref } from "vue";

import {
  Button,
  Col,
  Dropdown,
  Form,
  FormItem,
  TypographyTitle,
  Input,
  InputNumber,
  Menu,
  MenuItem,
  message,
  Modal,
  Row,
  Select,
  Table,
  Tag,
  Divider,
} from "ant-design-vue";
import { useI18n } from "vue-i18n";

import { useRouter } from "vue-router";

const i18n = useI18n();

const { t } = useI18n();

const router = useRouter();

let showLoadingBut = ref(false);

let categorieList = ref([]);

let tablePagination = ref({
  pageSizeOptions: ["10", "20", "50", "100"],
  showSizeChanger: true,
  current: 1,
  pageSize: 20,
  total: 0,
  lastRowKey: undefined,
});
let loading = ref(false);
let list = ref([]);

let infoList = ref([]);

const columns = [
  { title: t("supplierManagement.table.brand"), width: 180, ellipsis: true, dataIndex: "brand", key: "brand" },

  { title: t("supplierManagement.table.url"), width: 180, ellipsis: true, dataIndex: "api", key: "api" },
  {
    title: t("supplierManagement.table.publicKey"),
    width: 180,
    ellipsis: true,
    dataIndex: "key",
    key: "key",
  },
  {
    title: t("supplierManagement.table.privateKey"),
    width: 180,
    ellipsis: true,
    dataIndex: "privatekey",
    key: "privatekey",
  },
  {
    title: t("supplierManagement.table.status"),
    width: 180,
    ellipsis: true,
    dataIndex: "status",
    key: "status",
  },
  { dataIndex: "action", key: "action", fixed: "right", width: 245, ellipsis: true },
];

function chang_page(pagination) {
  tablePagination.value = pagination;
  get_list();
}

function showDetail(info) {
  console.log(info);
}

// function deleteUser(info) {
//   // console.log(info);
//   list.value.forEach((item, index) => {
//     if (item.Name == info.Name) {
//       // console.log(item);
//       list.value.splice(index, 1);
//     }
//   });
// }

function get_list(lastRowKey) {
  loading.value = true;

  list.value = [
    {
      brand: "Milesight",
      api: "https://api.******.com/v1/",
      key: "agafaf465548",
      sercertkey: "(***********)",
      ishide: true,
      status: true,
    },
    {
      brand: "Lutron",
      api: "https://api.******.com/v1/",
      key: "agafaf465548",
      sercertkey: "(***********)",
      ishide: true,
      status: true,
    },
    {
      brand: "Tuya",
      api: "https://api.******.com/v1/",
      key: "agafaf465548",
      sercertkey: "(***********)",
      ishide: true,
      status: true,
    },
    {
      brand: "Panoramic",
      api: "https://api.******.com/v1/",
      key: "agafaf465548",
      sercertkey: "(***********)",
      ishide: true,
      status: true,
    },
    {
      brand: "Senso8",
      api: "https://api.******.com/v1/",
      key: "agafaf465548",
      sercertkey: "(***********)",
      ishide: true,
      status: true,
    },
    {
      brand: "Computime",
      api: "https://api.******.com/v1/",
      key: "agafaf465548",
      sercertkey: "(***********)",
      ishide: true,
      status: true,
    },
    {
      brand: "Teltonika",
      api: "https://api.******.com/v1/",
      key: "agafaf465548",
      sercertkey: "(***********)",
      ishide: true,
      status: true,
    },
    {
      brand: "Scheider",
      api: "https://api.******.com/v1/",
      key: "agafaf465548",
      sercertkey: "(***********)",
      ishide: true,
      status: true,
    },
    {
      brand: "Blueair",
      api: "https://api.******.com/v1/",
      key: "agafaf465548",
      sercertkey: "(***********)",
      ishide: true,
      status: false,
    },
  ];
  console.log(list.value);

  loading.value = false;
}

function clickId(id, category) {
  router.push({
    path: "/DeviceLogs",
    query: {
      id: id,
      category: category,
    },
  });
}

// 加载更多数据
function onClickLoading() {
  get_list(tablePagination.value.lastRowKey);
}

function filterOption(input, option) {
  return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
}

get_list(undefined);
</script>

<style lang="less">
@import url("style");
</style>
