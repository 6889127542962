import {Base} from "@/api/Base"

const api = "employee"

export class EmployeeAdd extends Base {
    path = api + "/add"
}

export class EmployeeDelete extends Base {
    path = api + "/delete"
}

export class EmployeeList extends Base {
    path = api + "/list"
}

export class EmployeeEdit extends Base {
    path = api + "/edit"
}

export class EmployeeListAll extends Base {
    path = api + "/listAll"
}

//此处修改后台登录用户的登录路径
export class Login extends Base {
    path = api + "/login"
}

//此处修改后台用户登出的路径
export class Logout extends Base {
    path = api + "/logout"
}

//此处修改后台登录用户修改密码的请求路径
export class ChangePassword extends Base {
    path = api + "/changePassword"
}
