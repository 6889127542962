import { defineComponent, ref } from "vue"
import { useStore } from "vuex";
import { GlobalOutlined } from "@ant-design/icons-vue"
import { Dropdown, Menu, Button } from "ant-design-vue"

import i18n from "@/i18n"

export default defineComponent({
  name: "LanguageButton",
  setup() {
    const store = useStore();
    const locale = ref(store.getters.language)
    const change_language = (...args) => {
      switch (args[0].key) {
        case "zhTW":
          locale.value = "zhTW"
          break;
        case "enUS":
          locale.value = "enUS"
          break;
        case "zhCN":
          locale.value = "zhCN"
          break;
      }
      store.commit("setLanguage", locale.value)
      i18n.global.locale = locale.value
      location.reload();
    }
    const menu = (
      <Menu onClick={change_language}>
        <Menu.Item key={"zhTW"}>{i18n.global.t("language.zhTW")}</Menu.Item>
        <Menu.Item key={"enUS"}>{i18n.global.t("language.enUS")}</Menu.Item>
        <Menu.Item key={"zhCN"}>{i18n.global.t("language.zhCN")}</Menu.Item>
      </Menu>
    )
    return () => (
      <Dropdown trigger={['click']} overlay={menu} placement={"bottom"}>
        <Button shape="circle" icon={<GlobalOutlined />} style={{ border: "0px" }}>
        </Button>
      </Dropdown>
    )
  }
})