import {Base} from "@/api/Base"

const api = "pile"

// 分页查询列表
export class PileGetListByPage extends Base {
    path = api + "/getListByPage"
}

// 获取记录
export class PileGetInfo extends Base {
    path = api + "/getInfo"
}

// 新增记录
export class PileAddInfo extends Base {
    path = api + "/addInfo"
}

// 修改记录
export class PileEditInfo extends Base {
    path = api + "/editInfo"
}

// 删除记录
export class PileDelInfo extends Base {
    path = api + "/delInfo"
}

// 获取配置列表
export class PileGetConfig extends Base {
    path = api + "/getConfig"
}

// 编辑配置
export class PileSetConfig extends Base {
    path = api + "/setConfig"
}

// 编辑配置
export class PileUpdateFirmware extends Base {
    path = api + "/updateFirmware"
}

// 编辑配置
export class PileFirmwareStatusNotification extends Base {
    path = api + "/firmwareStatusNotification"
}

// 重启电桩
export class PileReset extends Base {
    path = api + "/reset"
}

// 自定义数据传输
export class PileDataTransfer extends Base {
    path = api + "/dataTransfer"
}

// 触发电桩上报
export class PileTriggerMessage extends Base {
    path = api + "/triggerMessage"
}

// 获取诊断日志
export class PileGetDiagnostics extends Base {
    path = api + "/getDiagnostics"
}


