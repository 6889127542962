<template>
  <Row id="body">
    <!-- 标签-->
    <Col span="24">
      <Row align="middle">
        <Col :span="12" class="title">
          <TypographyTitle>{{ t('profileManagement.name.title') }}</TypographyTitle>
        </Col>
        <Col :span="12" align="right">
          <PlusOutlined style="font-size: 22px; margin-right: 20px" @click="$router.push('/Generalinfo')" />
        </Col>
      </Row>
      <Row>
        <Col :span="6" v-for="(item, index) in companyList" :key="index">
          <ManagementBox
            :user="item.user"
            :admin="item.admin"
            :sites="item.sites"
            :devives="item.devives"
            :company="item.name"
            @click="goGeneralInfo"
          ></ManagementBox>
        </Col>
      </Row>
    </Col>
  </Row>
</template>
<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "generalinfo",
};
</script>
<script setup>
import { createVNode, ref, onMounted } from "vue";
import ManagementBox from "./ManagementBox.vue";
import { useRouter } from "vue-router";

import { Button, Col, TypographyTitle, Row, Card, Form, Input, Select, Upload } from "ant-design-vue";
import { useI18n } from "vue-i18n";
import { PlusOutlined } from "@ant-design/icons-vue";

const {t } = useI18n();

const router = useRouter();

const companyList = ref([
  { name: "Company A", user: "41", admin: "21", sites: "21", devives: "13" },
  { name: "Company B", user: "31", admin: "11", sites: "8", devives: "6" },
]);

function goGeneralInfo() {
  router.push("/GeneralInfo");
}
</script>

<style lang="less" scoped>
@import url("style");
</style>
