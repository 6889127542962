/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import store from "@/store"
import qs from "querystring"
import { default as axios, AxiosInstance } from "axios";
import { message } from "ant-design-vue";
import router from '@/router';

//此处填写后台请求Base路径，如果开了代理，请留空
let httpUrl = ""
if (process.env.NODE_ENV === 'production') {
    httpUrl = ""
} else {
    httpUrl = ""
}

//此处填写后台请求Base路径固定前缀
const apiUrl = httpUrl + "/api/"
const config = {
    httpUrl: httpUrl,
    baseURL: apiUrl,
    withCredentials: true,
    headers: {
        'Access-Control-Allow-Origin': "*",
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    },
    validateStatus(status) {
        if (null === status) return false
        return (status >= 200 && status < 300) || (status >= 400 && status < 500)
    }
}
const runAxios = axios.create(config)
runAxios.interceptors.request.use(
    function (config) {
        // TODO: Do something before request is sent
        if (config.headers['Content-Type'] !== 'multipart/form-data') {
            config.data = qs.stringify(config.data);
        }
        return config;
    },
    function (error) {
        // TODO: Do something with request error
        return Promise.reject(error);
    }
)
// Add a response interceptor
runAxios.interceptors.response.use(
    function (response) {
        // TODO: Do something with response data
        if (response.status == 401) {
            message.error('登录超时，请重新登录！');
            setTimeout(() => {
                router.push({ name: "Login" });
            }, 1000)
        }
        // 根据响应头content-disposition 判断响应是否为文件类型
        if(response.headers["content-disposition"]){
          let fileName = "default"
          const patt = new RegExp("filename=([^;]+\\.[^]+);*");
          const result = patt.exec(response.headers["content-disposition"]);
          if(result.length>1){
              //中文解码
              fileName = decodeURI(result[1]);
          }
          //制作a标签并点击下载
          const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/octet-stream' }));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
      }
        return response;
    },
    function (error) {
        // TODO: Do something with response error
        return Promise.reject(error);
    }
)

export const Axios = runAxios

export class Base {
    constructor() {
        //此处配置后台请求头TOKEN名称
        Axios.defaults.headers["token"] = store.getters.getToken
        Axios.defaults.headers["tenantKey"] = store.getters.getDsKey
        Axios.defaults.headers["tenantDb"] = store.getters.getUserMsg.tenantDb
    }
    path = ""
    get(param = {}) {
        return Axios.get(this.path, { params: param })
    }
    post(param) {
        return Axios.post(this.path, param)
    }
    put(param) {
        return Axios.put(this.path, param)
    }
    delete(param) {
        return Axios.delete(this.path, { params: param })
    }
    getDownLoad(param) {
      return Axios.get(this.path, { params: param,  responseType: "blob"})
    }
    postDownLoad(param) {
        return Axios.post(this.path, param, {responseType: "blob"})
    }
}