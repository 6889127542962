<template>
  <Modal id="Detail" width="60%" :visible="visible" @cancel="visible = !visible" @ok="visible = !visible" :title="$t('tradeManage.detailTitle')" :zIndex="9999999900">
    <Descriptions >
      <DescriptionsItem :label="$t('tradeManage.table.tradeNo')">{{data.tradeNo}}</DescriptionsItem>
      <DescriptionsItem :label="$t('tradeManage.table.startTime')">{{data.startTime}}</DescriptionsItem>
      <DescriptionsItem :label="$t('tradeManage.table.actualEndTime')">{{data.actualEndTime}}</DescriptionsItem>
      <DescriptionsItem :label="$t('tradeManage.table.actualDuration')">{{data.actualDuration}}</DescriptionsItem>
      <DescriptionsItem :label="$t('tradeManage.table.chargeType')">{{data.chargeType}}</DescriptionsItem>
      <DescriptionsItem :label="$t('tradeManage.table.tradeStatus')">{{data.tradeStatus}}</DescriptionsItem>
      <DescriptionsItem :label="$t('tradeManage.table.pileType')">{{data.pileType}}</DescriptionsItem>
  </Descriptions>
  </Modal>
</template>

<script>
import { Modal, Descriptions,DescriptionsItem } from "ant-design-vue";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons-vue";

export default {
  name: "TradeDetail",
  components: {
    Modal,
    LoadingOutlined,
    PlusOutlined,
    Descriptions,
    DescriptionsItem,
  },
  data() {
    return {
      selectList:{
        typeList: [{value: 1,label: '菜單'},{value: 2,label: '按鈕'}],
      },
      data: {
      },
      visible: false,
    };
  },
  computed: {
  },
  methods: {
    async detailShow(obj) {
      delete obj.startTime
      delete obj.endTime
      delete obj.actualEndTime
      delete obj.createTime
      delete obj.updateTime
      this.data = obj;
      this.visible = true;
    },
  },
}
</script>

<style lang="less">
</style>