<template>
  <div :class="fullScreen ? 'PirChart PirChartFullScreen' : 'PirChart'">
    <Card :title="props.title">
      <template #extra><Button :type="'text'" :icon="fullScreen ? h(ShrinkOutlined) : h(ArrowsAltOutlined)" @click="visibleModal"></Button></template>
      <!-- <div id="pir"></div> -->
      <Timeline :mode="'alternate'">
        <TimelineItem v-for="item in data">{{ item.timeStr }}</TimelineItem>
      </Timeline>
    </Card>
  </div>
</template>

<script setup>
import { Card, message, Button, Timeline, TimelineItem } from "ant-design-vue";
import { ref, onMounted, nextTick, h } from "vue";
import { Chart } from "@antv/g2";
import { DeviceOperaLog, DeviceSpecificationAttributes } from "@/api/tuya";
import { EllipsisOutlined, ArrowsAltOutlined, ShrinkOutlined } from "@ant-design/icons-vue";
import dayjs from "dayjs";

// let data = [
//   { year: '1991', value: 3 },
//   { year: '1992', value: 4 },
//   { year: '1993', value: 3.5 },
//   { year: '1994', value: 5 },
//   { year: '1995', value: 4.9 },
//   { year: '1996', value: 6 },
//   { year: '1997', value: 7 },
//   { year: '1998', value: 9 },
//   { year: '1999', value: 13 },
// ];

const props = defineProps({
  deviceId: {
    type: String,
    default: "",
  },
  title: {
    type: String,
    default: "",
  },
});

let chart = null;

const fullScreen = ref(false);

const data = ref();

const visibleModal = () => {
  fullScreen.value = !fullScreen.value;
  chart.destroy();
  setTimeout(() => {
    initChart();
  }, 100);
};

const initChart = async () => {
  // chart = new Chart({
  //   container: "pir",
  //   autoFit: true,
  // });

  // 获取设备状态列表

  // let response = await new DeviceSpecificationAttributes().get({deviceId: 'eb78035e9e6ee54c176jyd'});

  // let codeList = []
  // // 拼接所有状态，并查询
  // if (response.data) {
  //   response.data.status?.forEach(item => {
  //     let values = JSON.parse(item.values)
  //     codeList.push({
  //       value: item.code,
  //       label: item.code,
  //       unit: values.unit,
  //       scale: values.scale
  //     })
  //   })
  // }

  // 构建查询参数
  const getData = {
    deviceId: props.deviceId,
    codes: "pir",
  };
  let response1 = await new DeviceOperaLog().get(getData);
  if (response1.data.msg !== undefined) {
    message.error(response1.data.msg);
    return;
  }
  console.log(response1.data.logs);
  let tmp = [];
  // const obj = codeList.find(item => item.value === 'pir')
  for (let i = 0; i < response1.data.logs.length; i++) {
    if (response1.data.logs[i].code === "pir") {
      // tmp.push({
      //   time: dayjs(response1.data.logs[i].timeStr).format("HH:mm:ss"),
      //   value: response1.data.logs[i].value === "none" ? 0 : 1,
      // });
      tmp.push(response1.data.logs[i]);
      // tmp.push(response1.data.logs[i]);
    }
  }

  data.value = tmp;

  // chart
  //   .interval()
  //   .data(data)
  //   .encode("x", "time")
  //   .encode("y", "value")
  //   .axis({
  //     y: {
  //       tickFilter: (datum) => {
  //         return datum == 0 || datum == 1;
  //       },
  //     },
  //   });

  // chart.render();
};

onMounted(() => {
  initChart();
});
</script>

<style lang="less">
.PirChart {
  background-color: white;
  border-radius: 10px;
  width: 100%;
  padding: 20px;
  .ant-card-body {
    height: 450px;
    overflow: auto;
  }
}
.PirChartFullScreen {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  height: 100vh;
  .ant-row {
    height: 100vh;
  }
  .ant-card-body {
    height: calc(100vh - 100px);
    overflow: auto;
  }
}
</style>
