<template>
  <Row>
    <div class="colored-section" :style="sectionStyle">
      <div class="num">{{ num }}</div>
      <div class="title">{{ title }}</div>
    </div>
  </Row>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
  rightColor: {
    type: String,
    default: "#283794",
  },
  title: {
    type: String,
    default: "默认标题",
  },
  num: {
    type: Number,
    default: 0,
  },
});

// 样式对象
const sectionStyle = computed(() => ({
  "--right-color": props.rightColor,
}));
</script>

<style lang="less">
</style>
