<template>
  <Row id="ReportsIndex" :gutter="[8, 8]">
    <!-- <Col :span="3">
      <div
        style="
          width: 100%;
          height: calc(100% - 23px);
          background-color: white;
          border-radius: 20px;
          padding: 10px;
          margin-top: 23px;
        "
      >
        <Row>
          <Col :span="24" style="padding-top: 50px">
            <Form :layout="'vertical'">
              <FormItem>
                <template #label>
                  <Space>
                    <img :src="SVGICONCostomer" style="width: 20px" />
                    <span style="color: #44659a">Costomer</span>
                  </Space>
                </template>
                <Select value="">
                  <SelectOption value="">Company A</SelectOption>
                </Select>
              </FormItem>
              <FormItem>
                <template #label>
                  <Space>
                    <img :src="SVGICONSite" style="width: 20px" />
                    <span style="color: #44659a">Site</span>
                  </Space>
                </template>
                <Select value="">
                  <SelectOption value="">All</SelectOption>
                </Select>
              </FormItem>
              <FormItem>
                <template #label>
                  <Space>
                    <img :src="SVGICONFloor" style="width: 20px" />
                    <span style="color: #44659a">Floor</span>
                  </Space>
                </template>
                <Select value="">
                  <SelectOption value="">All</SelectOption>
                </Select>
              </FormItem>
              <FormItem>
                <template #label>
                  <Space>
                    <img :src="SVGICONZoneGroup" style="width: 20px" />
                    <span style="color: #44659a">Zone / Group</span>
                  </Space>
                </template>
                <Select value="">
                  <SelectOption value="">All</SelectOption>
                </Select>
              </FormItem>
              <FormItem>
                <template #label>
                  <Space>
                    <img :src="SVGICONDevices" style="width: 20px" />
                    <span style="color: #44659a">Devices</span>
                  </Space>
                </template>
                <Select value="">
                  <SelectOption value="">All</SelectOption>
                </Select>
              </FormItem>
              <FormItem>
                <Button :type="'text'" style="color: #44659a">Compare</Button>
              </FormItem>
            </Form>
          </Col>
        </Row>
      </div>
    </Col> -->
    <Col :span="3" class="sidebar">
      <Row>
        <Col span="24">
          <Input :placeholder="t('reportManagement.name.search')">
            <template #suffix>
              <SearchOutlined />
            </template>
          </Input>
        </Col>

        <Col span="24" style="margin-top: 20px">
          <Row style="margin-bottom: 10px" align="middle">
            <Col span="16">
              <SettingOutlined style="margin: 0 5px" />

              <span>{{ t("reportManagement.name.title") }}</span>
            </Col>

            <Col span="8" align="right">
              <div class="grayBox" style="margin-right: 10px">20</div>
            </Col>
          </Row>

          <Tree
            v-model:expandedKeys="expandedKeys"
            v-model:selectedKeys="selectedKeys"
            v-model:checkedKeys="checkedKeys"
            checkable
            :tree-data="treeData"
          >
            <template #switcherIcon="{ switcherCls }"><PlusOutlined :class="switcherCls" /></template>

            <template #title="{ title, key }">
              <div v-if="key === '3-0'">
                <div style="display: flex; justify-content: space-between; align-items: center">
                  <div style="margin-right: 55px">{{ title }}</div>
                  <div class="grayBox" style="margin-right: 10px">20</div>
                </div>
              </div>

              <template v-else>{{ title }}</template>
            </template>
          </Tree>
        </Col>
      </Row>
    </Col>

    <Col :span="21">
      <Row>
        <!-- 标签-->
        <Col :span="24" class="title">
          <TypographyTitle>{{ t("reportManagement.name.title") }}</TypographyTitle>
        </Col>
        <Col :span="isShowNewReport ? 14 : 24" class="index-content">
          <Row>
            <Col :span="24">
              <!-- 查询表单-->
              <Row :gutter="[16, 32]">
                <Col :span="24">
                  <Row style="margin-bottom: 15px">
                    <Col :span="6" style="font-size: 26px">{{ t("reportManagement.name.title") }}</Col>
                    <Col :span="12"></Col>
                    <Col :span="6" :align="'right'" style="display: flex">
                      <Input
                        :placeholder="t('reportManagement.name.search')"
                        allowClear
                        style="margin-right: 15px"
                      ></Input>
                      <Button type="primary" @click="showNewReport">{{ t("reportManagement.name.add") }}</Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <!-- 列表-->
              <Row>
                <Col :span="24">
                  <!--:row-selection="{selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"-->
                  <Table
                    :columns="columns"
                    :row-selection="rowSelection"
                    :dataSource="list"
                    rowKey="Name"
                    :loading="loading"
                    :scroll="{ x: 1500, y: 'calc(100vh - 300px)' }"
                    :pagination="tablePagination"
                    @change="chang_page"
                  >
                    <template #headerCell="{ column }">
                      <template v-if="column.dataIndex === 'action'">
                        <span>
                          {{ $t("public.table.action") }}
                        </span>
                      </template>
                    </template>

                    <template #bodyCell="{ column, record }">
                      <template v-if="column.dataIndex === 'Subscribed'">
                        <div v-if="record.Subscribed">
                          <CheckOutlined />
                        </div>
                      </template>
                      <template v-if="column.dataIndex === 'Entity'">
                        <div
                          style="
                            background-color: black;
                            height: 15px;
                            width: 15px;
                            border-radius: 50%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                          "
                        >
                          <div style="background-color: white; height: 5px; width: 5px; border-radius: 50%"></div>
                        </div>
                      </template>
                      <template v-if="column.dataIndex === 'Editable'">
                        <EditOutlined />
                      </template>

                      <template v-if="column.dataIndex === 'action'">
                        <div class="pc-display">
                          <EyeOutlined style="margin-right: 20px" />
                          <DownloadOutlined />
                        </div>
                      </template>
                    </template>
                  </Table>
                  <div v-if="showLoadingBut" style="width: 100%; text-align: center; margin-top: 5px">
                    <Button @click="onClickLoading">loading({{ tablePagination.pageSize }})...</Button>
                  </div>
                </Col>
              </Row>
            </Col>
            <!-- <Col :span="isShowNewReport ? 9 : 0" class="index-content" style="background-color: #f4f7fc; height: calc(100% - 160px); margin-left: 20px">
              <Row>
                <Col :span="24">
                  <Row style="margin-bottom: 15px">
                    <Col :span="6" style="font-size: 26px">New Reports</Col>
                    <Col :span="18" :align="'right'">
                      <CloseOutlined @click="isShowNewReport = false" />
                    </Col>
                  </Row>
                </Col>
                <Col :span="24">
                  <Row :gutter="[8, 6]">
                    <Col :span="8" v-for="(item, index) in newReportList" :key="index">
                      <NewReportBox :title="item.title" :text="item.text"></NewReportBox>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col> -->
          </Row>
        </Col>
        <Col
          :span="isShowNewReport ? 9 : 0"
          class="index-content"
          :style="{
            backgroundColor: '#f4f7fc',
            marginLeft: isShowNewReport ? '10px' : '0',
          }"
        >
          <Row>
            <Col :span="24">
              <Row style="margin-bottom: 15px">
                <Col :span="6" style="font-size: 26px">{{ t("reportManagement.name.newReports") }}</Col>
                <Col :span="18" :align="'right'">
                  <CloseOutlined @click="isShowNewReport = false" />
                </Col>
              </Row>
            </Col>
            <Col :span="24">
              <Row :gutter="[8, 6]">
                <Col :span="8" v-for="(item, index) in newReportList" :key="index">
                  <NewReportBox :title="item.title" :text="item.text"></NewReportBox>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  </Row>
</template>
<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "reports",
};
</script>
<script setup>
import { createVNode, ref } from "vue";
import { EyeOutlined, EyeInvisibleOutlined, EditOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons-vue";
import {
  Button,
  Col,
  Form,
  FormItem,
  TypographyTitle,
  Input,
  Row,
  Select,
  SelectOption,
  Table,
  Tree,
} from "ant-design-vue";
import { useI18n } from "vue-i18n";
import NewReportBox from "./NewReportBox.vue";
import { useRouter } from "vue-router";
import { SearchOutlined, SettingOutlined, PlusOutlined, DownloadOutlined } from "@ant-design/icons-vue";

const isShowNewReport = ref(false);

const i18n = useI18n();

const { t } = useI18n();

const router = useRouter();

const treeData = [
  { title: t("reportManagement.name.favorites"), key: "0-0" },
  { title: t("reportManagement.name.tecurrentReport"), key: "1-0" },
  { title: t("reportManagement.name.applicationReports"), key: "2-0" },
  {
    title: t("reportManagement.name.myReports"),
    key: "3-0",
    children: [
      {
        title: "reports 1-0",
        key: "3-0-0",

        children: [
          { title: "reports", key: "3-0-3-0", disableCheckbox: true },
          { title: "reports", key: "3-0-0-1" },
        ],
      },
      {
        title: "reports 1-1",
        key: "3-0-1",
        children: [{ key: "3-0-1-0", title: "reports" }],
      },
    ],
  },
];

let showLoadingBut = ref(false);

let categorieList = ref([]);

let tablePagination = ref({
  pageSizeOptions: ["10", "20", "50", "100"],
  showSizeChanger: true,
  current: 1,
  pageSize: 20,
  total: 0,
  lastRowKey: undefined,
});
let loading = ref(false);
let list = ref([]);

let infoList = ref([]);
let newReportList = ref([
  {
    title: t("reportManagement.name.alarmsCountReport"),
    text: "The total numoer of alarmsgrouped by devices or rules",
  },
  {
    title: t("reportManagement.name.readingsHistoryReport"),
    text: "The reading history of a singledenice or muktiple devioes.",
  },
  { title: t("reportManagement.name.usablityReport"), text: "The total and new entities inyour applicatson." },
  {
    title: t("reportManagement.name.communicationReport"),
    text: "Total number of $Ms, calls andemails send in your applicatson",
  },
  {
    title: t("reportManagement.name.tenantUsageReport"),
    text: "The report provides an oMerviewof al resources created by thetenan.",
  },
  {
    title: t("reportManagement.name.dataSourceUsageReport"),
    text: "The ropoet prowides and gverviewod the dats source usage",
  },
]);

const columns = [
  { title: t("reportManagement.table.subscribed"), ellipsis: true, dataIndex: "Subscribed", key: "Subscribed" },
  { title: t("reportManagement.table.name"), ellipsis: true, dataIndex: "Name", key: "Name" },
  {
    title: t("reportManagement.table.entity"),

    ellipsis: true,
    dataIndex: "Entity",
    key: "Entity",
  },
  {
    title: t("reportManagement.table.creationDate"),
    ellipsis: true,
    dataIndex: "CreationDate",
    key: "CreationDate",
  },
  {
    title: t("reportManagement.table.createdBy"),
    ellipsis: true,
    dataIndex: "CreatedBy",
    key: "CreatedBy",
  },

  {
    title: t("reportManagement.table.editable"),
    ellipsis: true,
    dataIndex: "Editable",
    key: "Editable",
  },
  {
    title: t("reportManagement.table.subscribers"),
    ellipsis: true,
    dataIndex: "Subscribers",
    key: "Subscribers",
  },
  {
    title: t("reportManagement.table.lastRunResult"),
    ellipsis: true,
    dataIndex: "LastRun",
    key: "LastRun",
  },
  {
    title: t("reportManagement.table.lastRunDate"),
    ellipsis: true,
    dataIndex: "LastDate",
    key: "LastDate",
  },
  { dataIndex: "action", key: "action", fixed: "right", width: 145, ellipsis: true },
];

function showNewReport() {
  isShowNewReport.value = true;
}
function chang_page(pagination) {
  tablePagination.value = pagination;
  get_list();
}

function deleteUser(info) {
  // console.log(info);
  list.value.forEach((item, index) => {
    if (item.Name == info.Name) {
      // console.log(item);
      list.value.splice(index, 1);
    }
  });
  get_count();
}

function get_count() {
  let superadmin = 0;
  let siteadmin = 0;
  let manager = 0;
  infoList.value = [];
  list.value.forEach((item) => {
    switch (item.Userid) {
      case "1":
        superadmin++;
        break;
      case "2":
        siteadmin++;
        break;
      case "3":
        manager++;
        break;
    }
  });
  infoList.value.push({ title: "Super Admin", num: superadmin, rightColor: "#23378d" });
  infoList.value.push({ title: "Site Admin", num: siteadmin, rightColor: "#1375bc" });
  infoList.value.push({ title: "Manager", num: manager, rightColor: "#20a5dd" });
  infoList.value.push({ title: "User", num: list.value.length, rightColor: "#70cbe0" });
  console.log(infoList.value);
  tablePagination.value.total = list.value.length;
}

const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(`selectedRowKeys: ${selectedRowKeys}`, "selectedRows: ", selectedRows);
  },
  getCheckboxProps: (record) => ({
    disabled: record.name === "Disabled User", // Column configuration not to be checked
    name: record.name,
  }),
};

function get_list(lastRowKey) {
  loading.value = true;

  list.value = [
    {
      Subsribed: false,
      Name: "IoT01 31-Aug-24",
      Entity: true,
      CreationDate: "Aug 31 2024，2-27-47PM",
      CreatedBy: "Joe",
      Editable: true,
      Subscribers: "2",
      LastRun: "0",
      LastDate: "Aug 31 2024，2-27-47PM",
    },
    {
      Subscribed: true,
      Name: "IoT02 31-Aug-24",
      Entity: false,
      CreationDate: "Sep 01 2024，10-15-00AM",
      CreatedBy: "Alice",
      Editable: false,
      Subscribers: "5",
      LastRun: "5",
      LastDate: "Sep 01 2024，10-20-00AM",
    },
    {
      Subscribed: false,
      Name: "IoT03 31-Aug-24",
      Entity: true,
      CreationDate: "Sep 02 2024，4-45-30PM",
      CreatedBy: "Bob",
      Editable: true,
      Subscribers: "5",
      LastRun: "10",
      LastDate: "Sep 02 2024，5-00-00PM",
    },
    {
      Subscribed: true,
      Name: "IoT04 31-Aug-24",
      Entity: false,
      CreationDate: "Aug 30 2024，8-00-00AM",
      CreatedBy: "Charlie",
      Editable: true,
      Subscribers: "2",
      LastRun: "3",
      LastDate: "Aug 30 2024，9-00-00AM",
    },
    {
      Subscribed: false,
      Name: "IoT05 31-Aug-24",
      Entity: true,
      CreationDate: "Aug 29 2024，12-30-45PM",
      CreatedBy: "Lucy",
      Editable: false,
      Subscribers: "1",
      LastRun: "1",
      LastDate: "Aug 29 2024，12-45-00PM",
    },
    {
      Subscribed: true,
      Name: "IoT01 31-July-24",
      Entity: false,
      CreationDate: "Sep 03 2024，9-15-30AM",
      CreatedBy: "Snoopy",
      Editable: true,
      Subscribers: "5",
      LastRun: "7",
      LastDate: "Sep 03 2024，9-45-00AM",
    },
    {
      Subscribed: false,
      Name: "IoT02 31-July-24",
      Entity: true,
      CreationDate: "Sep 04 2024，3-00-00PM",
      CreatedBy: "Linus",
      Editable: false,
      Subscribers: "0",
      LastRun: "0",
      LastDate: "Sep 04 2024，3-10-00PM",
    },
    {
      Subscribed: true,
      Name: "IoT03 31-July-24",
      Entity: true,
      CreationDate: "Sep 05 2024，6-20-30PM",
      CreatedBy: "Patty",
      Editable: true,
      Subscribers: "4",
      LastRun: "8",
      LastDate: "Sep 05 2024，6-45-00PM",
    },
    {
      Subscribed: false,
      Name: "IoT04 31-July-24",
      Entity: false,
      CreationDate: "Sep 06 2024，2-45-15PM",
      CreatedBy: "Woodstock",
      Editable: false,
      Subscribers: "3",
      LastRun: "4",
      LastDate: "Sep 06 2024，3-00-00PM",
    },
    {
      Subscribed: true,
      Name: "IoT05 31-July-24",
      Entity: true,
      CreationDate: "Sep 07 2024，11-30-00AM",
      CreatedBy: "Schroeder",
      Editable: true,
      Subscribers: "7",
      LastRun: "9",
      LastDate: "Sep 07 2024，12-00-00PM",
    },
    {
      Subscribed: false,
      Name: "IoT06 31-July-24",
      Entity: false,
      CreationDate: "Sep 08 2024，7-15-45PM",
      CreatedBy: "Franklin",
      Editable: false,
      Subscribers: "3",
      LastRun: "6",
      LastDate: "Sep 08 2024，7-30-00PM",
    },
  ];
  console.log(list.value);
  get_count();
  loading.value = false;
}

function clickId(id, category) {
  router.push({
    path: "/DeviceLogs",
    query: {
      id: id,
      category: category,
    },
  });
}

// 加载更多数据
function onClickLoading() {
  get_list(tablePagination.value.lastRowKey);
}

function filterOption(input, option) {
  return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
}

get_list(undefined);
</script>

<style lang="less">
// @import url("style");
#ReportsIndex {
  .index-content {
    background: #fff;
    padding: 20px;
    border-radius: 20px;
  }
  padding-bottom: 10px;
  .grayBox {
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: #998a8a;
  }
  .sidebar {
    // height: calc(100vh - 180px);
    border: 1px solid #e7ebf7;
    border-left: 0px;
    background-color: white;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    padding: 20px 10px;
  }
}
</style>
