<template>
  <Row id="GeneralinfoIndex" :gutter="[8, 8]">
    <Col :span="3">
      <div
        style="
          width: 100%;
          height: calc(100% - 23px);
          background-color: white;
          border-radius: 20px;
          padding: 10px;
          margin-top: 23px;
        "
      >
        <Row>
          <Col :span="24" style="padding-top: 50px">
            <Form :layout="'vertical'">
              <FormItem>
                <template #label>
                  <Space>
                    <img :src="SVGICONCostomer" style="width: 20px" />
                    <span style="color: #44659a">{{ t("dashboard2.name.customer") }}</span>
                  </Space>
                </template>
                <Select value="">
                  <SelectOption value="">Company A</SelectOption>
                </Select>
              </FormItem>
              <FormItem>
                <template #label>
                  <Space>
                    <img :src="SVGICONSite" style="width: 20px" />
                    <span style="color: #44659a">{{ t("dashboard2.name.site") }}</span>
                  </Space>
                </template>
                <Select value="">
                  <SelectOption value="">All</SelectOption>
                </Select>
              </FormItem>
              <FormItem>
                <template #label>
                  <Space>
                    <img :src="SVGICONFloor" style="width: 20px" />
                    <span style="color: #44659a">{{ t("dashboard2.name.floor") }}</span>
                  </Space>
                </template>
                <Select value="">
                  <SelectOption value="">All</SelectOption>
                </Select>
              </FormItem>
              <FormItem>
                <template #label>
                  <Space>
                    <img :src="SVGICONZoneGroup" style="width: 20px" />
                    <span style="color: #44659a">{{ t("dashboard2.name.zone") }}</span>
                  </Space>
                </template>
                <Select value="">
                  <SelectOption value="">All</SelectOption>
                </Select>
              </FormItem>
              <FormItem>
                <template #label>
                  <Space>
                    <img :src="SVGICONDevices" style="width: 20px" />
                    <span style="color: #44659a">{{ t("dashboard2.name.devices") }}</span>
                  </Space>
                </template>
                <Select value="">
                  <SelectOption value="">All</SelectOption>
                </Select>
              </FormItem>
              <FormItem>
                <Button :type="'text'" style="color: #44659a">{{ t("dashboard2.name.compare") }}</Button>
              </FormItem>
            </Form>
          </Col>
        </Row>
      </div>
    </Col>
    <Col :span="21">
      <Row>
        <!-- 标签-->
        <Col :span="24">
          <Row :align="'middle'">
            <Col :span="12" class="title">
              <TypographyTitle>{{ t("profileManagement.name.information") }}</TypographyTitle>
            </Col>
            <Col :span="12" :align="'right'" style="padding-right: 20px">
              <Button type="primary">{{ t("public.name.save") }}</Button>
            </Col>
          </Row>
        </Col>
        <Col :span="24">
          <Row :gutter="[10, 10]">
            <Col :span="12">
              <Card>
                <Form layout="vertical" :model="formState" v-bind="formItemLayout">
                  <FormItem :label="t('profileManagement.field.companyName')">
                    <Input v-model:value="formState.Company" placeholder="" />
                  </FormItem>
                  <FormItem :label="t('profileManagement.field.address')">
                    <Input v-model:value="formState.Address" placeholder="" />
                  </FormItem>
                  <FormItem :label="t('profileManagement.field.email')">
                    <Input v-model:value="formState.Email" placeholder="" />
                  </FormItem>
                  <FormItem :label="t('profileManagement.field.telephoneNo')">
                    <Input v-model:value="formState.Phone" placeholder="" />
                  </FormItem>
                </Form>
              </Card>
              <Card style="margin-top: 5px">
                <Form layout="vertical" :model="formState" v-bind="formItemLayout">
                  <FormItem :label="t('profileManagement.field.domain')">
                    <Input v-model:value="formState.Domain" placeholder="" />
                  </FormItem>
                </Form>
                <Col span="24">
                  <Button type="primary" style="margin-right: 10px">
                    {{ t("profileManagement.field.changeDomainName") }}
                  </Button>
                  <Button>{{ t("public.name.cancel") }}</Button>
                </Col>
              </Card>
              <Card style="margin-top: 5px">
                <Form layout="vertical" :model="formState" v-bind="formItemLayout">
                  <FormItem label="Locale">
                    <Col span="24">
                      <Row :gutter="[10, 30]">
                        <Col span="12">
                          <Row :align="'middle'">
                            <Col span="10">{{ t("profileManagement.field.defaultLanguage") }}</Col>
                            <Col span="14"><Select v-model:value="langValue" :options="langOption"></Select></Col>
                          </Row>
                        </Col>
                        <Col span="12">
                          <Row :align="'middle'">
                            <Col span="10">{{ t("profileManagement.field.dateFormat") }}</Col>
                            <Col span="14"><Select v-model:value="dateValue" :options="dateOption"></Select></Col>
                          </Row>
                        </Col>
                        <Col span="12">
                          <Row :align="'middle'">
                            <Col span="10">{{ t("profileManagement.field.defaultTimeZone") }}</Col>
                            <Col span="14"><Select v-model:value="zoneValue" :options="zoneOption"></Select></Col>
                          </Row>
                        </Col>
                        <Col span="12">
                          <Row :align="'middle'">
                            <Col span="10">{{ t("profileManagement.field.timeFormat") }}</Col>
                            <Col span="14"><Select v-model:value="timeValue" :options="timeOption"></Select></Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </FormItem>
                </Form>
              </Card>
            </Col>
            <Col :span="12">
              <Card style="height: 35.3%">
                <span>{{ t("profileManagement.field.themeColor") }}</span>
                <Row>
                  <Col :span="12"><input type="color" v-model="selectedColor" ref="colorInput" /></Col>
                  <Col :span="12"><input type="color" v-model="selectedColor2" ref="colorInput2" /></Col>
                </Row>
              </Card>
              <Card style="margin-top: 5px">
                <Row>
                  <Col span="24">
                    <Row>
                      <Col span="24">
                        <div>{{ t("profileManagement.field.logo") }}</div>
                        <div style="display: flex">
                          <div style="height: 100px; width: 100px; background-color: gray; margin-right: 10px"></div>
                          <div style="width: calc(100% - 130px)">
                            <Upload.Dragger
                              v-model:fileList="fileList"
                              name="file"
                              :multiple="true"
                              action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                              @change="handleChange"
                              @drop="handleDrop"
                            >
                              <p class="ant-upload-text" style="text-align: center; line-height: 65px">
                                {{ t("profileManagement.field.drag") }}
                              </p>
                            </Upload.Dragger>
                          </div>
                        </div>
                        <div>{{ t("profileManagement.field.max") }}</div>
                      </Col>
                      <Col span="24">
                        <div>{{ t("profileManagement.field.logo") }}</div>
                        <div style="display: flex">
                          <div style="height: 100px; width: 100px; background-color: gray; margin-right: 10px"></div>
                          <div style="width: calc(100% - 130px)">
                            <Upload.Dragger
                              v-model:fileList="fileList"
                              name="file"
                              :multiple="true"
                              action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                              @change="handleChange"
                              @drop="handleDrop"
                            >
                              <p class="ant-upload-text" style="text-align: center; line-height: 65px">
                                {{ t("profileManagement.field.drag") }}
                              </p>
                            </Upload.Dragger>
                          </div>
                        </div>
                        <div>{{ t("profileManagement.field.max") }}</div>
                      </Col>
                      <Col span="24">
                        <div>{{ t("profileManagement.field.logo") }}</div>
                        <div style="display: flex">
                          <div style="height: 100px; width: 100px; background-color: gray; margin-right: 10px"></div>
                          <div style="width: calc(100% - 130px)">
                            <Upload.Dragger
                              v-model:fileList="fileList"
                              name="file"
                              :multiple="true"
                              action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                              @change="handleChange"
                              @drop="handleDrop"
                            >
                              <p class="ant-upload-text" style="text-align: center; line-height: 65px">
                                {{ t("profileManagement.field.drag") }}
                              </p>
                            </Upload.Dragger>
                          </div>
                        </div>
                        <div>{{ t("profileManagement.field.max") }}</div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  </Row>
</template>
<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "generalinfo",
};
</script>
<script setup>
import { createVNode, ref, onMounted } from "vue";

import {
  Button,
  Col,
  TypographyTitle,
  Row,
  Card,
  Form,
  Input,
  Select,
  SelectOption,
  FormItem,
  Upload,
} from "ant-design-vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
const { t } = useI18n();

const formState = ref({});
const colorInput = ref(null);
const colorInput2 = ref(null);

const langOption = [
  { label: "English", value: "enUS" },
  { label: "繁體中文", value: "zhCN" },
  { label: "簡體中文", value: "zhTW" },
];

const langValue = ref("zhTW");

const dateOption = [
  { label: "DD/MM/YYYY", value: "DD/MM/YYYY" },
  { label: "MM/DD/YYYY", value: "MM/DD/YYYY" },
];

const dateValue = ref("MM/DD/YYYY");

const timeOption = [
  { label: "24H", value: "24H" },
  { label: "12H", value: "12H" },
];

const timeValue = ref("24H");

const zoneOption = [{ label: "GMT-00:00", value: "GMT-00:00" }];

const zoneValue = ref("GMT-00:00");

onMounted(() => {
  colorInput.value.click;
  // colorInput2.value.click();
});
</script>

<style lang="less">
#GeneralinfoIndex {
  :deep(.ant-input) {
    border: 0px;
    border-bottom: 1px solid #b9b9b9;
    border-radius: 0px;
    font-size: 18px;
  }
  :deep(.ant-input:focus) {
    border-color: none;
    box-shadow: none;
    border-inline-end-width: 0px;
    outline: 0;
  }
  padding-bottom: 10px;
}
</style>
