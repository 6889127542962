<template>
  <Modal
      id="UpdateFirmwareModel"
      width="50%"
      :visible="visible"
      :confirmLoading="confirmLoading"
      @cancel="visible = !visible"
      @ok="submit"
      :title="title"
  >
    <Row align="middle" justify="center">
      <Col span="18">
        <Row>
          <Col span="24">
            <Form
                ref="refForm"
                :model="form"
                layout="vertical"
                :rules="validateRules"
            >
              <Row justify="space-around">

                <Col span="10">
                  <FormItem :label="$t('pileManage.updateFirmware.identifier')" name="identifier">
                    <Input
                        :placeholder="$t('pileManage.uValidate.identifier')"
                        v-model:value="form.identifier" disabled>
                    </Input>
                  </FormItem>
                </Col>

                <Col span="10">

                </Col>

                <Col span="22">
                  <FormItem :label="$t('pileManage.updateFirmware.location')" name="location">
                    <Textarea style="height: 150px" :placeholder="$t('pileManage.uValidate.location')"
                           v-model:value="form.location">
                    </Textarea>
                  </FormItem>
                </Col>

<!--                <Col span="10">-->
<!--                  <FormItem :label="$t('pileManage.updateFirmware.retrieveDate')" name="retrieveDate">-->
<!--                    <DatePicker-->
<!--                        style="width:100%"-->
<!--                        :placeholder="$t('pileManage.uValidate.retrieveDate')"-->
<!--                        :getCalendarContainer="(triggerNode) => triggerNode.parentNode"-->
<!--                        format="YYYY-MM-DD HH:mm:ss"-->
<!--                        :showTime="{ defaultValue: defTime() }"-->
<!--                        v-model:value="form.retrieveDate"-->

<!--                        :disabledDate="disabledDate"-->
<!--                        :disabledDateTime="disabledDateTime"-->
<!--                        @ok="onOk"-->
<!--                    />-->
<!--                  </FormItem>-->
<!--                </Col>-->

                <Col span="10"></Col>

              </Row>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  </Modal>
</template>

<script>
import {
  Modal,
  Row,
  Col,
  Form,
  Input,
  InputNumber,
  Image,
  Radio,
  Steps,
  Step,
  Button,
  message,
  Select,
  DatePicker,
  InputGroup,
  Upload,
  Descriptions,
  DescriptionsItem,
  Space,
  Card,
  Table,
  Popconfirm,
} from "ant-design-vue";
import {
  UploadOutlined,
  UserOutlined,
  SolutionOutlined,
  FileImageOutlined,
  CheckOutlined,
  PlusOutlined,
  MinusCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons-vue";

import {PileGetDiagnostics} from "@/api/pile";

import dayjs from 'dayjs';

export default {
  name: "FormModel",
  components: {
    Modal,
    Row,
    Col,
    UserOutlined,
    SolutionOutlined,
    FileImageOutlined,
    CheckOutlined,
    UploadOutlined,
    PlusOutlined,
    MinusCircleOutlined,
    ExclamationCircleOutlined,
    Form: Form,
    FormItem: Form.Item,
    Input: Input,
    InputPassword: Input.Password,
    Textarea: Input.TextArea,
    InputGroup,
    InputNumber,
    Image,
    RadioGroup: Radio.Group,
    RadioButton: Radio.Button,
    Steps,
    Step,
    Button,
    message,
    Select,
    SelectOption: Select.Option,
    DatePicker,
    Upload,
    Descriptions,
    DescriptionsItem,
    Space,
    Card,
    Table,
    Popconfirm,
  },
  data() {
    return {
      statusList:[
        {value: 0, label: 'Downloaded'},
        {value: 1, label: 'DownloadFailed'},
        {value: 2, label: 'Downloading'},
        {value: 3, label: 'Idle'},
        {value: 4, label: 'InstallationFailed'},
        {value: 5, label: 'Installing'},
        {value: 6, label: 'Installed'},
      ],
      confirmLoading: false,
      visible: false,
      actionType: "",
      form: {},
      fileList: {},
      validateRules: {
        identifier: [
          {
            required: true,
            message: this.$t("pileManage.uValidate.identifier"),
            trigger: "change",
          },
        ],
        location: [
          {
            required: true,
            message: this.$t("pileManage.uValidate.location"),
            trigger: "change",
          },
        ],
        // retrieveDate: [
        //   {
        //     required: true,
        //     message: this.$t("pileManage.uValidate.retrieveDate"),
        //     trigger: "change",
        //   },
        // ],
      },
    };
  },
  computed: {
    title: function () {
      return "获取诊断日志"
    },
  },
  watch: {
    "visible": {
      deep: true
    }
  },
  methods: {
    onShow(obj) {
      this._resetData();
      this.form = JSON.parse(JSON.stringify(obj));
      this.visible = true;
    },
    submit() {
      this.$refs["refForm"].validate().then(() => {
        this.confirmLoading = true
        // 构建参数
        const param = {
          identifier: this.form.identifier,
          location: this.form.location,
          // retrieveDate: dayjs(this.form.retrieveDatet).format('YYYY-MM-DD HH:mm:ss')
        }
        // console.log("获取参数", param)

        const api = new PileGetDiagnostics()
        // api.post({identifier: identifier, location: "https://mungo.mynatapp.cc/api/common/upload"}).then((response) => {
        api.post(param).then((response) => {
          if (parseInt(response.data.code) === 0) {
            response.data.data.status === 'Accepted' ? message.success(response.data.data.status) : message.error(response.data.data.status);
          } else {
            message.error(response.data.msg)
          }
        });
        this.confirmLoading = false
      }).catch(() => {});
    },

    // 点击确定按钮后的回调
    onOk(value) {
      this.form.retrieveDatet = value.format('YYYY-MM-DD HH:mm:ss')
    },

    range(start, end) {
      const result = []
      for (let i = start; i < end; i++) {
        result.push(i)
      }
      return result
    },

    defTime() {
      return dayjs('00:00:00', 'HH:mm:ss')
    },

    // 今天之前的日期不可选（不包括今天）
    disabledDate(date) {
      // return date < moment(moment(new Date()).format("YYYY-MM-DD HH:mm:ss")); //返回今天以后的日期
      return date < dayjs(dayjs(new Date()).format("YYYY-MM-DD "));
    },

    //不可选择的时间
    disabledDateTime() {
      return {
        disabledHours: () => this.range(0, 24).splice(4, 20),
        disabledMinutes: () => this.range(30, 60),
        disabledSeconds: () => [55, 56]
      }
    },

    // reset data
    _resetData() {
      this.form = {};
    },
  },
};
</script>
