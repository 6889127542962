<template>
  <Layout id="Home" has-sider>
    <LayoutSider v-model:collapsed="collapsed" class="home-sider">
      <Nav ref="refNav"></Nav>
    </LayoutSider>
    <Layout>
      <LayoutHeader class="home-header">
        <Row style="padding-left: 20px; padding-right: 20px">
          <Col :span="2">
            <Space>
              <MenuUnfoldOutlined v-if="collapsed" @click="() => (collapsed = !collapsed)" />
              <MenuFoldOutlined v-else @click="() => (collapsed = !collapsed)" />
              <img :src="JPGLogo" alt="logo" style="width: 120px" />
            </Space>
          </Col>
          <Col :span="12"> </Col>
          <Col :span="10" style="text-align: right">
            <Space>
              <Badge count="5">
                <BellOutlined style="font-size: 16px" />
              </Badge>
              <LanguageButton />
              <Dropdown :trigger="['click']">
                <Button type="text">
                  <Avatar :src="$store.getters.getUserPortrait"></Avatar>
                  <span>{{ $store.getters.getName }}</span>
                </Button>
                <template #overlay>
                  <Menu>
                    <!-- <MenuItem key="personal" @click="personal">
                      <UserOutlined />
                      <span>{{ $t("public.name.personal") }}</span>
                    </MenuItem> -->
                    <MenuItem key="reset_password" @click="reset_password">
                      <UserOutlined />
                      <span>{{ $t("public.name.reset_password") }}</span>
                    </MenuItem>
                    <MenuItem key="logout" @click="logout">
                      <LogoutOutlined />
                      <span>{{ $t("public.name.logout") }}</span>
                    </MenuItem>
                  </Menu>
                </template>
              </Dropdown>
            </Space>
          </Col>
        </Row>
        <Row>
          <Col :span="24" style="background: #f4f7fb;  line-height: initial; padding: 5px 10px">
            <Flex style="width: 100%; height: 100%" :align="'center'" :justify="'end'" gap="large">
              <img :src="SVGSunshine" style="width: 26px" />
              <div>
                <img :src="SVGTemperature" style="width: 26px" />
                <span style="color: #8894a2; padding-left: 2px">{{ weather.temperature }}℃</span>
              </div>
              <div>
                <img :src="SVGHumidity" style="width: 26px" />
                <span style="color: #8894a2; padding-left: 2px">{{ weather.humidity }}%</span>
              </div>
              <div>
                <img :src="SVGWind" style="width: 26px" />
                <span style="color: #8894a2; padding-left: 2px">{{ weather.windDeg }}</span>
              </div>
              <div>
                <img :src="SVGUV" style="width: 26px" />
                <span style="color: #8894a2; padding-left: 2px">{{ weather.uvi }}</span>
              </div>
              <div>
                <img :src="SVGSunrise" style="width: 26px" />
                <span style="color: #8894a2; padding-left: 2px">{{ weather.sunrise }}</span>
              </div>
              <div>
                <img :src="SVGSunset" style="width: 26px" />
                <span style="color: #8894a2; padding-left: 2px">{{ weather.sunset }}</span>
              </div>
            </Flex>
          </Col>
        </Row>
      </LayoutHeader>
      <LayoutContent class="home-content">
        <router-view />
      </LayoutContent>
      <LayoutFooter class="home-footer">Powered by TTech Global Service Limited , all rights reserved 2024</LayoutFooter>
    </Layout>
  </Layout>
  <PersonalEdit ref="refPersonalEdit" />
  <PersonalPassword ref="refPasswordEdit" />
</template>

<script setup>
import SVGLogo from "@/assets/logo.svg";
import JPGLogo from "@/assets/logo.jpg";

import SVGCloudy from "@/assets/img/cloudy.svg";
import SVGCloudyNight from "@/assets/img/cloudy_night.svg";
import SVGNight from "@/assets/img/night.svg";
import SVGRainNight from "@/assets/img/rain_night.svg";
import SVGRainSun from "@/assets/img/rain_sun.svg";
import SVGRainy from "@/assets/img/rainy.svg";
import SVGSunshine from "@/assets/img/sunshine.svg";
import SVGThunderstorm from "@/assets/img/thunderstorm.svg";

import SVGHumidity from "@/assets/img/humidity.svg";
import SVGSunrise from "@/assets/img/sunrise.svg";
import SVGSunset from "@/assets/img/sunset.svg";
import SVGUV from "@/assets/img/uv.svg";
import SVGWind from "@/assets/img/wind.svg";
import SVGTemperature from "@/assets/img/temperature.svg";
</script>

<script>
import { Layout, Row, Col, Dropdown, Button, Menu, Avatar, Space, Flex, message,Badge } from "ant-design-vue";
import { LogoutOutlined, UserOutlined, MenuUnfoldOutlined, MenuFoldOutlined, DashboardOutlined,BellOutlined } from "@ant-design/icons-vue";
import Nav from "@/components/Nav.vue";
import PersonalEdit from "@/components/Home/PersonalEdit.vue";
import PersonalPassword from "@/components/Home/PersonalPassword.vue";
import LanguageButton from "@/components/Common/LanguageButton";
import { Logout } from "@/api/employee";
import { GetCurrentWeather } from "@/api/weather";
import Temperature from "@/components/Dashboard/Temperature.vue";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Home",
  components: {
    Nav,
    Row,
    Col,
    Flex,
    Layout,
    Dropdown,
    Menu,
    MenuItem: Menu.Item,
    Avatar,
    Button,
    Space,
    LayoutSider: Layout.Sider,
    LayoutHeader: Layout.Header,
    LayoutContent: Layout.Content,
    LayoutFooter: Layout.Footer,
    LogoutOutlined,
    UserOutlined,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    PersonalEdit,
    PersonalPassword,
    LanguageButton,
    message,
    Badge,
  },
  data() {
    return {
      collapsed: true,
      weather: {
        temperature: 24,
        humidity: 74,
        windDeg: 230,
        uvi: 0,
        sunrise: '18:00',
        sunset: '06:00'
      }
    };
  },
  methods: {
    // check login statuc
    checkLogin() {
      // if (this.$store.getters.getToken == "") {
      //   this.$router.push({ name: "Login" });
      // } else {
      //   const api = new apiLogin();
      //   api.get().then((response:any) => {
      //     if (1 != parseInt(response.data.code)) {
      //       this.$store.commit("setToken", "");
      //       this.$store.commit("setUsername", "");
      //       this.$store.commit("setname", "");
      //       this.$store.commit("setPortrait", "");
      //       this.$store.commit("setLastIp", "");
      //       this.$store.commit("setLastTime", 0);
      //       this.$router.push({ name: "Login" });
      //     }
      //   });
      // }
    },
    logout() {
      const api = new Logout();
      api.get().then((response, any) => {
        if (0 == parseInt(response.data.code)) {
          message.success(response.data.msg);
          this.$store.dispatch("logout");
          this.$router.push({ name: "Login" });
        } else {
          message.error(response.data.msg);
          // this.getCaptcha();
        }
      });
    },
    personal() {
      this.$refs.refPersonalEdit.show();
    },
    reset_password() {
      this.$refs.refPasswordEdit.show();
    },
    // clickItem(item: any) {
    //   if (!item) return false;
    //   switch (item.key) {
    //     case "logout":
    //       this.logout();
    //       break;
    //   }
    // },
    toggleCollapsed() {
      this.$refs.refNav.toggleCollapsed();
    },
    getCollapsed() {
      return this.$refs.refNav.getCollapsed();
    },
    windDirectionToStr(degrees) {
        const directions = [
            'N', 'NNE', 'EN', 'EEN', 'E', 'EES', 'ES', 'SES', 'S',
            'SSE', 'WS', 'WWS', 'W', 'WWE', 'WS', 'ES', 'EES'
        ];
    
        // 将角度范围分为 11 个区间，每个区间 360/11 度
        const anglePerDirection = 360 / directions.length;
        let index = Math.round(degrees / anglePerDirection);
        index = (index + directions.length) % directions.length; // 处理可能的负索引
    
        return directions[index];
    }
  },
  mounted() {
    this.checkLogin();
    // 获取天气
    let api = new GetCurrentWeather();
    api.get({
      lat: "22.3",
      lon: "114.2"
    }).then((response) => {
      console.log(response.data)
      this.weather.temperature = response.data.weather.current.temp;
      this.weather.humidity = response.data.weather.current.humidity;
      this.weather.windDeg = this.windDirectionToStr(response.data.weather.current.wind_deg);
      this.weather.uvi = response.data.weather.current.uvi;
      var sunrise = new Date(response.data.weather.current.sunrise);
      this.weather.sunrise = sunrise.getHours() + ":" + sunrise.getMinutes();
      var sunset = new Date(response.data.weather.current.sunset);
      this.weather.sunset = sunset.getHours() + ":" + sunset.getMinutes();
    });
  },
};
</script>

<style lang="less">
@import url("style.less");
</style>
