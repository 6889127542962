/**
 * @Auther    william
 * @Name      state mutations file
 * @Note      
 * @Time      2021/04/14 17:21
 */

let mutations = {
    // test
    test(state) {
        console.log(state)
    },
    // logout
    logout(state) {
        localStorage.removeItem(state.systemTag + "_info")
        this.commit("reset")
    },
    // reset state
    reset(state) {
        let tmp = localStorage.getItem(state.systemTag + "_info")
        if (tmp) {
            let info = JSON.parse(tmp)
            state.dsKey = info.dsKey
            state.language = info.language
            state.token = info.token
            state.logo = info.logo
            state.copyRight = info.copyRight
            state.navList = info.navList
            state.username = info.username
            state.idRol = info.idRol
            state.userType = info.userType
            state.tenantDb = info.tenantDb
            state.portrait = info.portrait
            state.lastIp = info.lastIp
            state.lastTime = info.lastTime
            state.phone = info.phone,
            state.login = info.login,
            state.email = info.email,
            state.type = info.type
            state.permissionList = info.permissionList
        } else {
            state.dsKey = "";
            state.language = "enUS"
            state.token = ""
            state.logo = ""
            // state.copyRight = "Account Master ©2022 Created by Elite Ltd"
            state.navList = []
            state.username = ""
            state.idRol = ""
            state.userType = ""
            state.tenantDb = ""
            state.portrait = ""
            state.lastIp = ""
            state.lastTime = 0
            state.phone = "",
            state.login = "",
            state.email = "",
            state.type = 0,
            state.permissionList = []
        }
    },
    // set state
    setInfo(state) {
        let info = {
            dsKey: state.dsKey,
            language: state.language,
            token: state.token,
            logo: state.logo,
            copyRight: state.copyRight,
            navList: state.navList,
            username: state.username,
            idRol: state.idRol,
            userType: state.userType,
            tenantDb: state.tenantDb,
            portrait: state.portrait,
            lastIp: state.lastIp,
            lastTime: state.lastTime,
            phone: state.phone,
            login: state.login,
            email: state.email,
            type: state.type,
            permissionList:state.permissionList
        }
        localStorage.setItem(state.systemTag + "_info", JSON.stringify(info))
    },
    // set dsKey
    setDsKey(state, value) {
        state.dsKey = value
        this.commit("setInfo")
    },
    // set language
    setLanguage(state, value) {
        state.language = value
        this.commit("setInfo")
    },
    // set username
    setUsername(state, value) {
        state.username = value
        this.commit("setInfo")
    },
    // set idRol
    setIdRol(state, value) {
        state.idRol = value
        this.commit("setInfo")
    },
    // set userType
    setUserType(state, value) {
        state.userType = value
        this.commit("setInfo")
    },
    // set userType
    setTenantDb(state, value) {
        state.tenantDb = value
        this.commit("setInfo")
    },
    // set portrait
    setPortrait(state, value) {
        state.portrait = value
        this.commit("setInfo")
    },
    // set last login ip
    setLastIp(state, value) {
        state.lastIp = value
        this.commit("setInfo")
    },
    // set last login time
    setLastTime(state, value) {
        state.lastTime = value
        this.commit("setInfo")
    },
    // set toekn
    setToken(state, value) {
        state.token = value
        this.commit("setInfo")
    },
    setPhone(state, value) {
        state.phone = value
        this.commit("setInfo")
    },
    setLogin(state, value) {
        state.login = value
        this.commit("setInfo")
    },
    setEmail(state, value) {
        state.email = value
        this.commit("setInfo")
    },
    setType(state, value) {
        state.type = value
        this.commit("setInfo")
    },
    setPermissionList(state, value) {
        state.permissionList = value
        this.commit("setInfo")
    },
    
}


export default mutations